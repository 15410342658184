table.dataTable {
    &.dtr-inline.collapsed {
        > tbody > tr {
            > {
                td.child,
                th.child {
                    cursor: default !important;
                }

                td {
                    &.dataTables_empty {
                        cursor: default !important;
                    }

                    &.child:before {
                        display: none !important;
                    }
                }

                th.child:before {
                    display: none !important;
                }

                td {
                    &.dataTables_empty:before {
                        display: none !important;
                    }

                    &.dtr-control {
                        position: relative;
                        padding-left: 30px;
                        cursor: pointer;
                    }
                }

                th.dtr-control {
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                }

                td.dtr-control:before,
                th.dtr-control:before {
                    top: 50%;
                    left: 5px;
                    height: 1em;
                    width: 1em;
                    margin-top: -9px;
                    display: block;
                    position: absolute;
                    color: white;
                    border: 0.15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 0.2em #777086;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: #6c5ffc;
                }
            }

            &.parent > {
                td.dtr-control:before,
                th.dtr-control:before {
                    content: "-";
                    background-color: #f82649;
                }
            }
        }

        &.compact > tbody > tr > {
            td.dtr-control,
            th.dtr-control {
                padding-left: 27px;
            }

            td.dtr-control:before,
            th.dtr-control:before {
                left: 4px;
                height: 14px;
                width: 14px;
                border-radius: 14px;
                line-height: 14px;
                text-indent: 3px;
            }
        }
    }

    &.dtr-column > tbody > tr {
        > {
            td.dtr-control,
            th.dtr-control,
            td.control,
            th.control {
                position: relative;
                cursor: pointer;
            }

            td.dtr-control:before,
            th.dtr-control:before,
            td.control:before,
            th.control:before {
                top: 50%;
                left: 50%;
                height: 0.8em;
                width: 0.8em;
                margin-top: -0.5em;
                margin-left: -0.5em;
                display: block;
                position: absolute;
                color: white;
                border: 0.15em solid white;
                border-radius: 1em;
                box-shadow: 0 0 0.2em #444;
                box-sizing: content-box;
                text-align: center;
                text-indent: 0 !important;
                font-family: "Courier New", Courier, monospace;
                line-height: 1em;
                content: "+";
                background-color: #0d6efd;
            }
        }

        &.parent {
            td.dtr-control:before,
            th.dtr-control:before,
            td.control:before,
            th.control:before {
                content: "-";
                background-color: #d33333;
            }
        }
    }

    > tbody > tr.child {
        padding: 0.5em 1em;

        &:hover {
            background: transparent !important;
        }

        ul.dtr-details {
            display: inline-block;
            list-style-type: none;
            margin: 0;
            padding: 0;

            > li {
                border-bottom: 1px solid #efefef;
                padding: 0.5em 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        span.dtr-title {
            display: inline-block;
            min-width: 75px;
            font-weight: bold;
        }
    }
}

div {
    &.dtr-modal {
        position: fixed;
        box-sizing: border-box;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
        padding: 10em 1em;

        div {
            &.dtr-modal-display {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 50%;
                height: 50%;
                overflow: auto;
                margin: auto;
                z-index: 102;
                overflow: auto;
                background-color: #f5f5f7;
                border: 1px solid black;
                border-radius: 0.5em;
                box-shadow: 0 12px 30px $black-6;
            }

            &.dtr-modal-content {
                position: relative;
                padding: 1em;
            }

            &.dtr-modal-close {
                position: absolute;
                top: 6px;
                right: 6px;
                width: 22px;
                height: 22px;
                border: 1px solid #eaeaea;
                background-color: #f9f9f9;
                text-align: center;
                border-radius: 3px;
                cursor: pointer;
                z-index: 12;

                &:hover {
                    background-color: #eaeaea;
                }
            }

            &.dtr-modal-background {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 101;
                background: $black-6;
            }
        }
    }

    &.dtr-bs-modal table.table tr:first-child td {
        border-top: none;
    }
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%;
    }
}
