/* ######  Alert ###### */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid $border;
  border-radius: 3px;

  .close {
    text-shadow: none;
    opacity: 0.2;
  }

  strong {
    font-weight: 600;
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
}

.alert-primary {
  color: $primary;
  background-color: $primary-1;
  border-color: $primary-1 !important;

  hr {
    border-top-color: $primary-1;
    background: $primary-1 !important;
  }

  .alert-link {
    color: #12245b;
  }
}

.alert-secondary {
  color: #3f4654;
  background-color: #e4e7ec;
  border-color: #d9dde5 !important;

  hr {
    border-top-color: #cacfdb;
    background:#cacfdb !important;
  }

  .alert-link {
    color: #292e37;
  }
}

.alert-success {
  color: #1f5c01;
  background-color: #d8efcc;
  border-color: #c8e9b8 !important;

  hr {
    border-top-color: #b9e3a5;
    background:#b9e3a5 !important;
  }

  .alert-link {
    color: #0e2a00;
  }
  p {
    color: #1f5c01 !important;
  }
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb !important;

  hr {
    border-top-color: #abdde5;
    background:#abdde5 !important;
  }

  .alert-link {
    color: #062c33;
  }
}

.alert-warning {
  color: #856404;
  background-color: $warning;
  border-color: #ffeeba !important;

  hr {
    border-top-color: #ffe8a1;
    background:#ffe8a1 !important;
  }

  .alert-link {
    color: #533f03;
  }
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb !important;

  hr {
    border-top-color: #f1b0b7;
    background:#f1b0b7 !important;
  }

  .alert-link {
    color: #491217;
  }
}

.alert-light {
  color: #7f7f81;
  background-color: $border;
  border-color: $border !important;

  hr {
    border-top-color: #ededf3;
    background:#ededf3 !important;
  }

  .alert-link {
    color: #666667;
  }
}

.alert-dark {
  color: #1f2533;
  background-color: #d8dae0;
  border-color: #c8ccd3 !important;

  hr {
    border-top-color: #babfc8;
    background:#babfc8 !important;
  }

  .alert-link {
    color: #0c0e13;
  }
}

[class*="alert-outline-"] {
  border: 1px solid transparent;
}

.alert-outline-success {
  border-color: #65fe19 !important;
  color: $success;
}

.alert-outline-info {
  border-color: #1ab6cf !important;
  color: $info;
}

.alert-outline-warning {
  border-color: #ffdb6d !important;
  color: #d39e00;
}

.alert-outline-danger {
  border-color: #eb8c95 !important;
  color: #ff0019;
}

[class*="alert-solid-"] {
  border-width: 0;
  color: $white;
}

.alert-solid-success {
  background-color: $success;
}

.alert-solid-info {
  background-color: $info;
}

.alert-solid-warning {
  background-color: $warning;
}

.alert-solid-danger {
  background-color: $danger;
}
/* ######  Alert ###### */
