.nice-select {
    position: relative;
    text-align: left;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    color: #3c4858;
    height: 41px;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #eaf0f7;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:after {
        border-bottom: 2px solid #cbd2e9;
        border-right: 2px solid #cbd2e9;
        content: "";
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 50%;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        width: 5px;
    }

    &.open {
        &:after {
            -webkit-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }

        .list {
            opacity: 1;
            pointer-events: auto;
            -webkit-transform: scale(1) translateY(0);
            -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
        }
    }

    &.disabled {
        border-color: #ededed;
        color: #807b90;
        pointer-events: none;

        &:after {
            border-color: #cccccc;
        }
    }

    &.wide {
        width: 100%;

        .list {
            left: 0 !important;
            right: 0 !important;
        }
    }

    &.right {
        float: right;

        .list {
            left: auto;
            right: 0;
        }
    }

    &.small {
        font-size: 12px;
        height: 36px;
        line-height: 34px;

        &:after {
            height: 4px;
            width: 4px;
        }

        .option {
            line-height: 34px;
            min-height: 34px;
        }
    }

    .list {
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
        box-sizing: border-box;
        margin-top: 4px;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
        transform: scale(0.75) translateY(-21px);
        -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
        z-index: 1;
        width: 100%;
    }

    .option {
        cursor: pointer;
        font-weight: 400;
        line-height: 40px;
        list-style: none;
        min-height: 40px;
        outline: none;
        padding-left: 18px;
        padding-right: 29px;
        text-align: left;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        &:hover,
        &.focus {
            background-color: #edeff5;
        }

        &.selected {
            &.focus {
                background-color: #edeff5;
            }

            font-weight: bold;
        }

        &.disabled {
            background-color: transparent;
            color: #807b90;
            cursor: default;
        }
    }
}

.no-csspointerevents .nice-select {
    .list {
        display: none;
    }

    &.open .list {
        display: block;
    }
}

.nice-select {
    .current {
        background: transparent;
    }

    .form-control {
        height: inherit;
    }
}
