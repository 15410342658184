.tree {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-left: 1em;
        position: relative;

        ul {
            margin-left: 0.5em;
        }

        &:before {
            content: "";
            display: block;
            width: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            border-left: 1px solid $border;
        }
    }

    li {
        margin: 0;
        line-height: 2em;
        color: #56546d;
        position: relative;
    }

    ul li {
        padding: 0 1em;
    }

    li i {
        margin-right: 5px;
        font-weight: bold;
        color: $primary;
        cursor: pointer;
    }

    ul li {
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 0;
            border-top: 1px solid $border;
            margin-top: -1px;
            position: absolute;
            top: 1em;
            left: 0;
        }

        &:last-child:before {
            background: $white;
            height: auto;
            top: 1em;
            bottom: 0;
        }
    }
}

.indicator {
    margin-right: 5px;
    color: #4b10a4;
}

.tree li {
    a {
        text-decoration: none;
        color: #29273c;
    }

    button {
        text-decoration: none;
        color: #29273c;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;

        &:active,
        &:focus {
            text-decoration: none;
            color: #29273c;
            border: none;
            background: transparent;
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            outline: 0;
        }
    }
}
