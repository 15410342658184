body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {
    .panel-heading1 a.collapsed:before {
        right: auto;
        left: 10px;
    }

    .alert-dismissible {
        padding-right: 1.25rem;
        padding-left: 3.8125rem;

        .close {
            right: auto;
            left: 0;
        }
    }

    .breadcrumb-4 {
        ol,
        li {
            text-align: left;
        }
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 0;
        padding-right: 0.5rem;

        &::before {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }
    }

    .btn-group > .btn:not(:first-child),
    .sp-container .btn-group > button:not(:first-child) {
        margin-left: auto;
        margin-right: -1px;
    }

    .btn-group > {
        .btn-group:not(:first-child) {
            margin-left: auto;
            margin-right: -1px;
        }

        .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn,
    .sp-container .btn-group > .btn-group:not(:last-child) > button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group > .btn:not(:first-child),
    .sp-container .btn-group > button:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn,
    .sp-container .btn-group > .btn-group:not(:first-child) > button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-with-icon i {
        margin-left: 5px;
        margin-right: auto;
    }

    .btn-icon-list {
        .btn + .btn,
        .sp-container button + .btn {
            margin-right: 5px;
            margin-left: 0;
        }
    }

    .sp-container .btn-icon-list button + .btn,
    .btn-icon-list .sp-container .btn + button,
    .sp-container .btn-icon-list .btn + button,
    .btn-icon-list .sp-container button + button,
    .sp-container .btn-icon-list button + button {
        margin-left: auto;
        margin-right: 5px;
    }

    .card-link + .card-link {
        margin-right: 1.25rem;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .card-group > .card {
            + .card {
                margin-left: auto;
                margin-right: 0;
                border-right: 0;
                border-left: 1px solid #e3e8f7;
            }

            &:not(:last-child) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-right-radius: 3px;
                    border-top-left-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child) {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    #thumbcarousel {
        .carousel-control-prev {
            left: auto;
            right: 0;
            i {
                transform: rotate(180deg);
            }
        }

        .carousel-control-next {
            left: 0;
            right: auto;
            i {
                transform: rotate(180deg);
            }
        }
    }

    .carousel-indicators {
        padding-left: inherit;
        padding-right: 0;
    }

    caption {
        text-align: right;
    }

    .list-unstyled,
    .list-inline {
        padding-left: 0;
        padding-right: 0;
    }

    .list-inline-item:not(:last-child) {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    #bg-side-text {
        right: auto !important;
        left: 21px !important;
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1200px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }

    .dropdown-toggle {
        &::after {
            margin-left: auto;
            margin-right: 0.255em;
        }

        &:empty::after {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .dropdown-menu {
        left: auto;
        right: 0;
        float: right;
        text-align: right;
    }

    .dropdown-menu-start {
        right: 0;
        left: auto;
    }

    .dropdown-menu-end {
        right: auto;
        left: 0;
    }

    @media (min-width: 576px) {
        .dropdown-menu-sm-start {
            right: 0;
            left: auto;
        }

        .dropdown-menu-sm-end {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 768px) {
        .dropdown-menu-md-start {
            right: 0;
            left: auto;
        }

        .dropdown-menu-md-end {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 992px) {
        .dropdown-menu-lg-start {
            right: 0;
            left: auto;
        }

        .dropdown-menu-lg-end {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 1200px) {
        .dropdown-menu-xl-start {
            right: 0;
            left: auto;
        }

        .dropdown-menu-xl-end {
            right: auto;
            left: 0;
        }
    }

    .dropup .dropdown-toggle {
        &::after {
            margin-left: auto;
            margin-right: 0.255em;
        }

        &:empty::after {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .dropend {
        .dropdown-menu {
            right: 100%;
            left: auto;
            margin-left: auto;
            margin-right: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: auto;
                margin-right: 0.255em;
                border-right: 0.3em solid;
                border-left: 0;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropstart {
        .dropdown-menu {
            right: auto;
            left: 100%;
            margin-right: auto;
            margin-left: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: auto;
                margin-right: 0.255em;
            }

            &::before {
                margin-right: auto;
                margin-left: 0.255em;
                border-right: 0;
                border-left: 0.3em solid;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropdown-toggle-start::before {
        margin-right: auto;
        margin-left: 0.255em;
        border-right: 0;
        border-left: 0.3em solid;
    }

    .dropdown-menu {
        &[x-placement^="top"],
        &[x-placement^="right"],
        &[x-placement^="bottom"],
        &[x-placement^="left"] {
            left: auto;
            right: 0;
        }
    }

    .dropdown-toggle-split::after {
        margin-left: auto;
        margin-right: 0;
    }

    .dropup .dropdown-toggle-split::after,
    .dropend .dropdown-toggle-split::after {
        margin-right: 0;
        margin-left: auto;
    }

    .dropstart .dropdown-toggle-split::before {
        margin-right: auto;
        margin-left: 0;
    }

    .form-check {
        padding-left: 0;
        padding-right: 1.25rem;
    }

    .form-check-input {
        margin-left: auto;
        margin-right: -1.25rem;
    }

    .form-check-inline {
        padding-left: inherit;
        padding-right: 0;
        margin-left: 0.75rem;
        margin-right: auto;

        .form-check-input {
            margin-right: 0;
            margin-left: 0.3125rem;
        }
    }

    .was-validated .form-control:valid,
    .form-control.is-valid,
    input.form-control.parsley-success,
    textarea.form-control.parsley-success {
        padding-right: 0.75rem !important;
        padding-left: calc(1.5em + 0.75rem) !important;
        background-position: center left calc(0.375em + 0.1875rem) !important;
    }

    .was-validated textarea.form-control:valid {
        padding-right: 0.75rem !important;
        padding-left: calc(1.5em + 0.75rem) !important;
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem) !important;
    }
    .form-select.is-valid:not([multiple]):not([size]),
    .form-select.is-valid:not([multiple])[size="1"],
    .was-validated .form-select:valid:not([multiple]):not([size]),
    .was-validated .form-select:valid:not([multiple])[size="1"] {
        padding-right: 0.75rem;
        padding-left: 4.125rem;
        background-position: left 0.75rem center, center left 2.25rem;
    }

    textarea.form-control {
        &.is-valid,
        &.parsley-success {
            padding-right: 0.75rem;
            padding-left: calc(1.5em + 0.75rem);
            background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
        }
    }

    .was-validated .form-select:valid,
    .form-select.is-valid,
    input.form-select.parsley-success,
    textarea.form-select.parsley-success {
        padding-right: 0.75rem;
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    input.form-control.parsley-error,
    textarea.form-control.parsley-error {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: center left calc(0.375em + 0.1875rem);
    }

    .was-validated textarea.form-control:invalid {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    }

    textarea.form-control {
        &.is-invalid,
        &.parsley-error {
            padding-right: 0;
            padding-left: calc(1.5em + 0.75rem);
            background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
        }
    }

    .was-validated .form-select:invalid,
    .form-select.is-invalid,
    input.form-select.parsley-error,
    textarea.form-select.parsley-error {
        padding-right: 0.75rem;
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    }

    @media (min-width: 576px) {
        .form-inline {
            .form-check {
                padding-left: inherit;
                padding-right: 0;
            }

            .form-check-input {
                margin-right: 0;
                margin-left: 0.25rem;
            }
        }
    }

    .browse-file.form-control {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .file-browser .btn-default {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .input-group > {
        .form-control + {
            .form-control,
            .form-select,
            .form-file {
                margin-right: -1px;
                margin-left: 0;
            }
        }

        .form-control-plaintext + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .form-select + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .form-file + {
            .form-control,
            .form-select,
            .form-file {
                margin-right: -1px;
                margin-left: 0;
            }
        }

        .form-control:not(:last-child),
        .form-select:not(:last-child) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .form-file {
            &:not(:last-child) .form-file-label {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child) .form-file-label {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .input-group-text {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text button + .btn,
    .input-group-text .sp-container .btn + button,
    .sp-container .input-group-text .btn + button,
    .input-group-text .sp-container button + button,
    .sp-container .input-group-text button + button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        .btn + .input-group-text,
        .sp-container button + .input-group-text {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text button + .input-group-text {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        .input-group-text + {
            .input-group-text,
            .btn {
                margin-right: -1px;
                margin-left: auto;
            }
        }

        .sp-container .input-group-text + button {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text .input-group-text + button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        .btn + .btn,
        .sp-container button + .btn {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text button + .btn,
    .input-group-text .sp-container .btn + button,
    .sp-container .input-group-text .btn + button,
    .input-group-text .sp-container button + button,
    .sp-container .input-group-text button + button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        .btn + .input-group-text,
        .sp-container button + .input-group-text {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text button + .input-group-text {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        .input-group-text + {
            .input-group-text,
            .btn {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .sp-container .input-group-text + button {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-text .input-group-text + button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-text {
        margin-right: auto;
        margin-left: -1px;
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-lg > .form-select,
    .input-group-sm > .form-select {
        padding-right: 0;
        padding-left: 1.75rem;
    }

    .input-group > .input-group-text > .btn,
    .sp-container .input-group > .input-group-text > button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-text {
        > .input-group-text,
        &:not(:last-child) > .btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .input-group > .input-group-text:not(:last-child) > button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-text {
        &:not(:last-child) > .input-group-text,
        &:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .input-group > .input-group-text:last-child > button:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group > .input-group-text {
        &:last-child > .input-group-text:not(:last-child) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        > .btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group > .input-group-text > button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .input-group-text {
        > .input-group-text,
        &:not(:first-child) > .btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group > .input-group-text:not(:first-child) > button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .input-group-text {
        &:not(:first-child) > .input-group-text,
        &:first-child > .btn:not(:first-child) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group > .input-group-text:first-child > button:not(:first-child),
    .input-group > .input-group-text:first-child > .input-group-text:not(:first-child) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .modal,
    .modal-backdrop {
        right: 0;
        left: auto;
    }

    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
    }

    .modal-footer > :not(:first-child) {
        margin-right: 0.25rem;
    }

    #modaldemo8 .modal-footer > :not(:first-child) {
        margin-right: 0;
        margin-left: 14px;
    }

    .modal-footer > :not(:last-child) {
        margin-right: 0;
        margin-left: 0.25rem;
    }

    .nav-tabs .nav-link + .nav-link {
        margin-left: 0;
        margin-right: 3px;
    }

    @media (min-width: 768px) {
        .main-nav .nav-link + .nav-link {
            padding-left: 0;
            padding-right: 15px;
            margin-left: 0;
            margin-right: 15px;
            border-left: 0;
            border-right: 1px solid $gray-500;
        }
    }

    .main-nav-column .nav-link {
        i {
            margin-right: 0;
            margin-left: 12px;
        }

        span {
            margin-left: 0;
            margin-right: auto;
        }

        &.active::before {
            left: auto;
            right: -28px;
        }
    }

    .main-nav-tabs {
        .lSSlideOuter {
            padding-left: 35px;
            padding-right: 32px;
        }

        .lSAction > a {
            &.lSPrev {
                left: 0;
                right: -32px;
            }

            &.lSNext {
                right: 0;
                left: -35px;
            }
        }
    }

    .nav-link {
        &.with-sub {
            .angle {
                right: 0;
                left: 25px;
            }

            .side-badge {
                right: 0;
                left: 44px;
            }
        }

        .side-badge {
            left: 20px;
            right: 0;
        }
    }

    .nav-sub-item .sub-angle {
        right: 0;
        left: 25px;
        margin-left: 0;
        margin-right: auto;
    }

    .page-item + .page-item {
        margin-left: 0;
        margin-right: 1px;
    }

    .page-link i {
        transform: rotate(180deg);
    }

    .page-link {
        margin-left: 0;
        margin-right: 0;
    }

    .pagination-circled .page-item + .page-item {
        margin-left: 0;
        margin-right: 5px;
    }

    .pagination {
        padding-left: inherit;
        padding-right: 0;
    }

    .page-link {
        margin-left: auto;
        margin-right: -1px;
    }

    .page-item {
        &:first-child .page-link {
            margin-left: auto;
            margin-right: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:last-child .page-link {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    .pagination-lg .page-item {
        &:first-child .page-link {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
        }

        &:last-child .page-link {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
        }
    }

    .pagination-sm .page-item {
        &:first-child .page-link {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
        }

        &:last-child .page-link {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0.2rem;
            border-bottom-left-radius: 0.2rem;
        }
    }

    .pagination li a {
        margin-right: auto;
        margin-left: 4px !important;
    }

    .popover-head-primary {
        &.bs-popover-start .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="start"] .popover-arrow {
            right: 0;
            left: -7px;
        }

        &.bs-popover-end .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="end"] .popover-arrow {
            left: 0;
            right: -7px;
        }
    }

    .popover-head-secondary {
        &.bs-popover-start .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="start"] .popover-arrow {
            right: 0;
            left: -7px;
        }

        &.bs-popover-end .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="end"] .popover-arrow {
            left: 0;
            right: -7px;
        }
    }

    .popover-primary {
        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="start"] .popover-arrow::after {
            border-left-color: transparent;
            border-right-color: $primary;
        }

        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="end"] .popover-arrow::after {
            border-right-color: transparent;
            border-left-color: $primary;
        }
    }

    .popover-secondary {
        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="start"] .popover-arrow::after {
            border-left-color: transparent;
            border-right-color: $primary;
        }

        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="end"] .popover-arrow::after {
            border-right-color: transparent;
            border-left-color: $primary;
        }
    }

    .popover {
        left: auto;
        right: 0;
        text-align: right;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -0.5rem;
    }

    .popover-static-demo {
        .bs-popover-top .popover-arrow,
        .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
        .bs-popover-bottom .popover-arrow,
        .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -5px;
        }
    }

    .dt-buttons.btn-group {
        right: 156px;
        left: auto;
    }

    .btn-primary.data-table-btn {
        left: auto;
        right: 185px;
    }

    @media (max-width: 1363px) {
        .dt-buttons.btn-group {
            left: auto;
            right: 0;
        }
    }

    .tooltip-primary {
        &.bs-tooltip-start .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
            border-left-color: $primary;
            border-right-color: $primary;
        }

        &.bs-tooltip-end .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
            border-right-color: $primary;
            border-left-color: $primary;
        }
    }

    .tooltip-secondary {
        &.bs-tooltip-start .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
            border-left-color: $primary;
            border-right-color: $primary;
        }

        &.bs-tooltip-end .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
            border-right-color: $primary;
            border-left-color: $primary;
        }
    }

    .tooltip {
        text-align: right;
    }

    #tooltip-arrow-individual {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -2px;
    }

    .tooltip-inner .tooltip-static-demo {
        .bs-tooltip-top .tooltip-arrow,
        .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
        .bs-tooltip-bottom .tooltip-arrow,
        .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -2px;
        }
    }

    .tabs-style-3 .nav.panel-tabs li a {
        margin: 0 0 5px 5px;
    }

    .tab_wrapper {
        &.right_side {
            > ul {
                float: left;
                padding-left: inherit;
                padding-right: 0;

                li {
                    text-align: left;

                    &:after {
                        right: inherit;
                        left: 0;
                    }

                    &.active {
                        &:after {
                            right: inherit;
                            left: 0;
                        }

                        &:before {
                            left: inherit;
                            right: -2px;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        > ul li {
            float: right;
            margin: 0 0 0 5px;

            &.active:after {
                left: auto;
                right: 0;
            }
        }

        .controller {
            right: auto;
            left: 0;

            span.next {
                float: left;
                margin-left: auto;
                margin-right: 10px;
            }
        }

        .content_wrapper {
            float: right;

            .accordian_header {
                &.active:after {
                    left: auto;
                    right: 0;
                }

                .arrow {
                    float: left;
                    border-left: 0;
                    border-right: 3px solid #cdd2e0;
                }
            }
        }

        &.left_side {
            > ul {
                float: right;
                padding-left: inherit;
                padding-right: 0;

                li {
                    &:after {
                        left: auto;
                        right: 0;
                    }

                    &.active {
                        &:after {
                            left: auto;
                            right: 0;
                        }

                        &:before {
                            left: -1px;
                            right: auto;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        &.right_side {
            > ul {
                float: left;

                li {
                    text-align: left;

                    &:after {
                        right: inherit;
                        left: 0;
                    }

                    &.active {
                        &:after {
                            right: inherit;
                            left: 0;
                        }

                        &:before {
                            left: inherit;
                            right: -2px;
                        }
                    }
                }
            }

            .content_wrapper {
                float: right;
            }
        }

        .active_tab {
            padding-right: 0;
            padding-left: 50px;

            .arrow {
                right: 0;
                left: 6px;

                &:after {
                    border-right: 0;
                    border-left: 3px solid #000;
                    left: auto;
                    right: 50%;
                    margin-left: auto;
                    margin-right: -6px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .tab_wrapper.show-as-dropdown > .tab_list:before {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -7px;
        }
    }

    .ckbox span {
        padding-left: 0;
        padding-right: 15px;

        &:empty {
            float: right;
        }

        &:before,
        &:after {
            left: auto;
            right: 0;
        }

        &:empty {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    .custom-control {
        padding-left: 0;
        padding-right: 1.5rem;
    }

    .custom-control-inline {
        margin-right: auto;
        margin-left: 1rem;
    }

    .custom-control-label {
        &::before,
        &::after {
            left: 0;
            right: -1.5rem;
        }
    }

    .form-switch {
        .custom-control-label {
            &::before {
                left: auto;
                right: -2.25rem;
            }

            &::after {
                left: 0;
                right: calc(-2.25rem + 2px);
            }
        }
    }

    .form-select {
        padding: 0.375rem 0.75rem 0.375rem 1.75rem;

        &[multiple],
        &[size]:not([size="1"]) {
            padding-right: 0;
            padding-left: 0.75rem;
        }
    }

    .form-select-sm {
        padding-left: 28px !important;
        padding-right: 0.5rem !important;
    }

    .form-select-lg {
        padding-left: 0;
        padding-right: 1rem;
    }

    .form-file-label::after {
        right: auto;
        left: 0;
        border-left: 1px solid #e3e8f7;
        border-right: inherit;
    }

    .form-range::-ms-fill-upper {
        margin-right: auto;
        margin-left: 15px;
    }

    .p-text {
        margin-left: 10px;
        margin-right: 12px;
    }

    .example .demo-avatar-group .main-img-user::after {
        right: auto;
        left: 3px;
    }

    .demo-avatar-group.main-avatar-list-stacked .main-img-user::after {
        right: auto;
        left: 1px;
    }

    .main-img-user::after {
        right: auto;
        left: 0px;
    }

    .main-avatar::after {
        right: auto;
        left: 3px;
    }

    .avatar-md::after {
        right: auto;
        left: 2px;
    }

    .avatar-lg::after {
        right: auto;
        left: 4px;
    }

    .avatar-xl::after {
        right: auto;
        left: 5px;
    }

    .avatar-xxl::after {
        right: auto;
        left: 7px;
    }

    .demo-avatar-group {
        .main-img-user + {
            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        .main-avatar + {
            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }

    @media (min-width: 576px) {
        .demo-avatar-group {
            .main-img-user + {
                .main-img-user,
                .main-avatar {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }

            .main-avatar + {
                .main-img-user,
                .main-avatar {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }

        .avatar-list .avatar:not(:last-child) {
            margin-right: 0 !important;
            margin-left: 5px !important;
        }
    }

    .wrapper.image-group .img-xs.rounded-circle {
        margin-right: 0;
        margin-left: -5px;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .list-group {
        padding-left: inherit;
        padding-right: 0;
    }

    .list-group-horizontal .list-group-item {
        margin-right: 0;
        margin-left: -1px;

        &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:last-child {
            margin-right: 0;
            margin-left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
        }
    }

    @media (min-width: 576px) {
        .list-group-horizontal-sm .list-group-item {
            margin-right: 0;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                margin-left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 768px) {
        .list-group-horizontal-md .list-group-item {
            margin-right: 0;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                margin-left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 992px) {
        .list-group-horizontal-lg .list-group-item {
            margin-right: 0;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                margin-left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 1200px) {
        .list-group-horizontal-xl .list-group-item {
            margin-right: 0;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                margin-left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    .main-navbar {
        left: auto;
        right: 0;

        .nav-link {
            i {
                margin-right: auto;
                margin-left: 10px;
            }

            &.with-sub {
                &::after {
                    margin-left: 0;
                    margin-right: auto;
                }

                &::before {
                    left: 0;
                    right: 50%;
                    margin-left: auto;
                    margin-right: -7px;
                    border-left-color: transparent;
                    border-right-color: rgba(28, 39, 60, 0.12);
                }
            }
        }

        .nav-sub-item {
            margin-left: auto;
            margin-right: 30px;

            > .nav-sub {
                left: auto;
                right: 176px;
            }
        }

        .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: auto;
        }

        .nav-sub-mega .nav li:not(.nav-sub-item) {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar {
            left: 0;
            right: auto;
            border-right: 1px solid #e3e8f7;
            border-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item + .nav-item {
            border-left: 0;
            border-right: 1px dotted $border;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link i {
            margin-right: auto;
            margin-left: 8px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link.with-sub::after {
            margin-left: auto;
            margin-right: 4px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub {
            left: auto;
            right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-item {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (max-width: 991.98px) {
        .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
            margin-left: auto;
            margin-right: 37px;

            > .nav-sub-link {
                padding-left: 0;
                padding-right: 10px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: 4px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega {
            .container > div + div,
            .container-fluid > div + div {
                border-left: 0;
                border-right: 1px solid rgba(28, 39, 60, 0.05);
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-sub-mega .nav + .nav {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
            margin-right: 0;
            margin-left: auto;
        }
    }

    .main-navbar-search {
        .btn,
        .sp-container button {
            right: auto;
            left: 0;
        }
    }

    .sp-container .main-navbar-search button {
        right: auto;
        left: 0;
    }

    .main-navbar-two .nav-sub-item > .nav-sub {
        left: auto;
        right: 178px;
    }

    @media (min-width: 992px) {
        .main-navbar-two .nav-item.active::before {
            left: 12px;
            right: 0;
        }
    }

    .main-navbar-three {
        .nav-item + .nav-item {
            border-left-width: 1px;
            border-right-width: 0;
            padding-left: inherit;
            padding-right: 0;
        }

        .nav-sub-item .nav-sub {
            left: auto;
            right: 182px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar-three .nav-item + .nav-item {
            margin-right: 25px;
            margin-left: auto;
        }
    }

    .navbar-brand {
        margin-right: 0;
        margin-left: 1rem;
    }

    .navbar-nav {
        padding-left: inherit;
        padding-right: 0;
    }

    .navbar-form.active .input-group-btn {
        right: auto;
        left: 12px;
    }

    .ps > .ps__rail-y {
        left: 0 !important;
        right: auto;

        > .ps__thumb-y {
            left: auto;
            right: 4px;
        }
    }

    .rdiobox span {
        padding-left: 0;
        padding-right: 13px;

        &:before {
            left: auto;
            right: 0;
        }

        &:after {
            left: auto;
            right: 5px;
        }

        &:empty {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .form-group-rdiobox .rdiobox {
        + .rdiobox {
            margin-right: 30px;
            margin-left: auto;
        }

        span {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;

        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    .tag-avatar {
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags > .tag:not(:last-child) {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .vtimeline {
        &:before {
            right: 50%;
            left: auto;
            margin-right: -1.5px;
            margin-left: auto;
        }

        .timeline-wrapper {
            padding-right: 0;
            padding-left: 90px;

            .timeline-panel {
                margin-left: auto;
                margin-right: 15%;

                &:after {
                    right: auto;
                    left: -14px;
                    border-right: 14px solid $background;
                    border-left: 0 solid $background;
                }
            }

            .timeline-badge {
                left: 0;
                right: 48.5%;
            }

            &.timeline-inverted {
                padding-right: 90px;
                padding-left: 0;

                .timeline-panel {
                    margin-left: 15%;
                    margin-right: auto;

                    &:after {
                        border-left-width: 14px;
                        border-right-width: 0;
                        left: auto;
                        right: -14px;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .vtimeline {
            &:before {
                left: auto !important;
                right: 26px !important;
            }

            .timeline-wrapper {
                padding-left: 0 !important;
                padding-right: inherit !important;

                .timeline-badge {
                    left: auto !important;
                    right: 0 !important;
                }

                .timeline-panel {
                    margin-right: 16% !important;
                    margin-left: auto !important;

                    &:after {
                        border-left: 14px solid $background !important;
                        border-right: 0 solid $background !important;
                        right: -14px !important;
                        left: auto !important;
                    }
                }

                &.timeline-inverted {
                    padding-left: inherit !important;
                    padding-right: 0 !important;
                }
            }
        }
    }

    .latest-timeline-1 ul.timeline-1 {
        &:before {
            left: auto;
            right: 20px;
            border-left: 0;
            border-right: 2px dotted #c0ccda;
        }

        > li {
            padding-left: inherit;
            padding-right: 0.9rem;

            .product-icon {
                left: auto;
                right: 1px;
            }
        }
    }

    .latest-tasks {
        .tasks .span {
            margin-right: 1.3rem;
            margin-left: auto;
        }

        .label,
        .time {
            margin-left: auto;
            margin-right: 1.3rem;
        }

        .check-box .ckbox span:before {
            left: auto;
            right: 0;
        }
    }

    .image-grouped img {
        margin-right: auto !important;
        margin-left: -0.6em !important;
    }

    .activity-list:before {
        margin: 20px 22px 0 20px;
    }

    .activity .item-activity {
        margin-left: auto;
        margin-right: 60px;
    }

    @media (max-width: 576px) {
        .vtimeline .timeline-wrapper .timeline-panel {
            margin-left: auto !important;
            margin-right: 30% !important;
        }
    }

    .main-toggle {
        span {
            right: 3px;
            left: auto;

            &::before {
                right: -25px;
                left: auto;
            }

            &::after {
                right: auto;
                left: -29px;
            }
        }

        &.on span {
            right: 37px;
            left: auto;
        }
    }

    .main-toggle-group-demo .main-toggle + .main-toggle {
        margin-left: 0;
        margin-right: 10px;
    }

    .close {
        float: left;
    }

    .embed-responsive {
        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            right: 0;
            left: auto;
        }
    }

    .main-body::before {
        left: auto;
        right: 70%;
    }

    .horizontal-nav .mega-dropdown-menu:before {
        right: auto;
        left: 16px;
    }

    .mega-dropdown-menu:after {
        right: 16px;
        left: auto;
    }

    .drop-icon-wrap .drop-icon-item {
        float: right;
    }

    .flag > a::before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -7px;
        border-left-color: transparent;
        border-right-color: $gray-300;
    }

    .dropdown-toggle-1::after {
        right: auto;
        left: 9px;
    }

    .dropdown-toggle-2::after {
        right: auto;
        left: 0px;
    }

    .arrow-ribbon {
        left: auto;
        right: 0px;

        &:before {
            right: auto;
            left: 0;
            left: -15px;
            border-left: 0;
            border-right: 15px solid #000;
        }
    }

    .mega-menu {
        &:before,
        &:after {
            right: 14px;
            left: auto;
        }
    }

    .flag-text:before {
        right: auto;
        left: 0;
    }

    .drop-flag .dropdown-menu {
        left: -10px;
        right: auto;
    }

    .dropdown-item .content {
        margin-left: auto;
        margin-right: 15px;
    }

    .avatar-status {
        right: auto;
        left: 5px;
    }

    .user-pro-body .profile-status {
        right: auto;
        left: 98px;

        &:before {
            right: auto;
            left: -6px;
        }
    }

    .pulse,
    .pulse-danger {
        right: auto;
        left: 8px;

        &:before {
            right: auto;
            left: -4px;
        }
    }

    .main-message-list a {
        .desc,
        .name {
            margin-right: 10px;
            margin-left: auto;
        }
    }

    .notifyimg i {
        float: right;
    }

    .sales-card .sales-icon {
        right: auto;
        left: -30px;
    }

    .sales-session {
        margin-left: auto;
        margin-right: 0;
        padding-left: inherit;
        padding-right: 0;
    }

    .product-timeline ul.timeline-1 {
        &:before {
            margin: 20px 22px 0 20px;
            right: -2px;
            left: 0;
            border-left: 0;
            border-right: 2px dotted #c0ccda;
        }

        > li {
            padding-left: inherit;
            padding-right: 1rem;

            .product-icon {
                left: auto;
                right: 3px;
            }
        }
    }

    .order-list .list {
        padding-right: 0;
        padding-left: inherit;
    }

    .pricing-card .list-unstyled {
        padding-left: inherit;
        padding-right: 0;
    }

    .demo-gallery {
        > ul > li a {
            float: right;

            .demo-gallery-poster > img {
                right: 50%;
                left: auto;
                margin-left: auto;
                margin-right: -10px;
            }
        }

        .justified-gallery > a .demo-gallery-poster > img {
            right: 50%;
            left: auto;
            margin-left: auto;
            margin-right: -10px;
        }

        .video .demo-gallery-poster img {
            margin-left: auto;
            margin-right: -24px;
        }
    }

    .product-details .media img {
        margin-right: auto;
        margin-left: 20px;
    }

    .preview-thumbnail.nav-tabs li {
        margin-right: auto;
        margin-left: 2.5%;

        &:last-of-type {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .size {
        margin-left: 10px;
        margin-right: 0;

        &:first-of-type {
            margin-right: 40px;
        }
    }

    .colorinput-color:before {
        right: 0.25rem;
        left: auto;
    }

    .item-card .cardprice {
        left: 15px;
        right: auto;
    }

    .icons-list {
        margin: 0 0 -1px -1px;
    }

    pre {
        border-top-right-radius: 0;
        border-top-left-radius: 7px;
    }

    .clipboard-icon {
        right: auto;
        left: 10px;
    }

    .sub-menu li a:before,
    .mega-menubg.hor-mega-menu li a:before {
        margin-right: auto;
        margin-left: 8px;
        right: 8px;
        left: auto;
    }

    .qunatity-list {
        margin-right: 0;
        margin-left: auto;
        padding-left: 0;
        padding-right: 6px;
    }

    .chips .chip {
        margin: 0 0 0.5rem 0.5rem;
    }

    .chip .avatar {
        float: right;
        margin: 0 -0.75rem 0 0.5rem;
    }

    .team i {
        margin-right: 10px;
        margin-left: auto;
        float: left;
    }

    #back-to-top {
        right: auto;
        left: 20px;
    }

    @media (min-width: 768px) {
        .main-dashboard-header-right > div {
            text-align: left;
        }
    }

    @media (min-width: 576px) {
        .main-dashboard-header-right > div + div {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    @media (min-width: 992px) {
        .main-dashboard-header-right > div + div {
            margin-right: 50px;
            margin-left: auto;
        }
    }

    .main-star span {
        margin-right: 5px;
        margin-left: auto;
    }

    .card-dashboard-eight .list-group-item {
        p {
            margin-right: 10px;
            margin-left: auto;
        }

        span {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .main-content-header-right {
        .media + .media {
            margin-left: auto;
            margin-right: 20px;
            padding-left: inherit;
            padding-right: 20px;
            border-left: 0;
            border-right: 1px solid $border;
        }

        .btn:first-of-type,
        .sp-container button:first-of-type {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    .sp-container .main-content-header-right button:first-of-type {
        margin-right: 30px;
        margin-left: auto;
    }

    .main-content-header-right {
        .btn + .btn,
        .sp-container button + .btn {
            margin-right: 5px;
            margin-left: auto;
        }
    }

    .sp-container .main-content-header-right button + .btn,
    .main-content-header-right .sp-container .btn + button,
    .sp-container .main-content-header-right .btn + button,
    .main-content-header-right .sp-container button + button,
    .sp-container .main-content-header-right button + button {
        margin-right: 5px;
        margin-left: auto;
    }

    .dropdown-menu-arrow:after {
        right: auto;
        left: 16px;
    }

    .dropdown-menu-start {
        right: auto !important;
        left: 0 !important;
    }

    .total-revenue {
        label span {
            margin-right: auto;
            margin-left: 5px;
        }

        > div + div {
            margin-left: auto;
            margin-right: 20px;
            padding-left: inherit;
            padding-right: 20px;
        }
    }

    .progress-style {
        &.progress .progress-bar:after {
            right: auto;
            left: -5px;
        }

        .progress-bar {
            float: right;
        }
    }

    .br-be-7 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 7px !important;
    }

    .br-bs-7 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 7px !important;
    }

    @media (max-width: 920px) {
        &.sidebar-mini .main-dashboard-header-right > div label {
            text-align: right;
        }
    }

    @media (max-width: 576px) {
        .total-revenue > div + div {
            margin-left: auto !important;
            margin-right: 20px !important;
            padding-right: 3px !important;
            padding-left: inherit !important;
        }
    }

    .success-widget h3 {
        &:after {
            left: auto;
            right: 0.5em;
        }

        &:before {
            right: auto;
            left: 0.5em;
        }
    }

    .danger-widget h3,
    .warning-widget h3 {
        &:after {
            right: 0.5em;
            left: auto;
        }

        &:before {
            right: auto;
            left: 0.5em;
        }
    }

    .card .box {
        left: auto;
        right: 0;
    }

    .userlist-table .user-link {
        margin-right: auto;
        margin-left: 5px;
    }

    .dot-label {
        right: 7px;
        left: auto;
    }

    @media (max-width: 576px) {
        .profile.navtab-custom li a {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    .userlist-table .dot-label {
        left: auto;
        right: -6px;
    }

    @media (min-width: 992px) {
        .top-header .main-header-center {
            margin-right: 0 !important;
            margin-left: auto !important;
        }
    }

    @media (max-width: 991px) {
        .navbar-form.active .input-group-btn {
            right: auto !important;
            left: 24px !important;
        }
    }

    .panel-heading1 a:before {
        right: auto;
        left: 10px;
    }

    @media (min-width: 1200px) {
        .main-nav-line-chat .nav-link + .nav-link {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }

    @media (max-width: 1145px) and (min-width: 992px) {
        .main-nav-line-chat .nav-link + .nav-link {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    @media (max-width: 991px) {
        .main-body.active.menu-open .horizontalMenucontainer .main-header {
            left: 0;
            right: auto;
        }
    }

    .boxed {
        .media-body div:first-child {
            float: right;
        }

        .card .b-p-apex {
            padding-left: inherit !important;
            padding-right: 0px !important;
        }
    }

    #mrg-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .timeline-1 {
        padding-left: inherit !important;
        padding-right: revert !important;
    }

    @media (max-width: 991px) and (min-width: 768px) {
        &.app.sidebar-mini.sidenav-toggled .main-chat-footer {
            margin-left: auto !important;
            margin-right: 80px !important;
        }
        &.app.sidebar-mini .main-chat-footer {
            margin-left: auto !important;
            margin-right: 239px !important;
        }
    }

    .l-c-r {
        padding-right: revert !important;
        padding-left: inherit !important;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        padding-right: inherit;
        padding-left: 0.5rem;
    }

    @media (min-width: 992px) {
        .app-content {
            margin-left: 0;
            margin-right: 240px;
        }
    }

    *::-ms-backdrop,
    .horizontalMenucontainer .main-header-left {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    *::-ms-backdrop,
    .chat .action-header {
        padding: 10px 17px 1px 13px;
    }

    *::-ms-backdrop,
    &.sidebar-mini .main-header-left .btn {
        margin-right: 60px;
        margin-left: auto;
        left: auto;
        right: 250px;
    }

    *::-ms-backdrop,
    &.sidebar-mini .main-content.app-content .main-header-left {
        margin-right: 0;
        margin-left: auto;
    }

    *::-ms-backdrop,
    .datetimepicker table th.next {
        border-top-right-radius: 3px;
        border-top-left-radius: 0;
    }

    *::-ms-backdrop,
    .datetimepicker table th.prev {
        border-top-right-radius: 0;
        border-top-left-radius: 3px;
    }

    @media (min-width: 992px) {
        *::-ms-backdrop,
        &.sidebar-mini .main-header-left {
            margin-left: auto;
            margin-right: 80px;
        }

        *::-ms-backdrop,
        &.sidebar-mini .main-header .main-header-left .header-brand {
            margin-right: auto;
            margin-left: 80px;
        }
    }

    *::-ms-backdrop,
    .btn-icon-list .btn {
        margin-left: 10px;
        margin-right: auto;
    }

    *::-ms-backdrop,
    .fc-datepicker.main-datepicker.hasDatepicker {
        border-right: 1px solid #e3e8f7 !important;
        border-left: 0 !important;
    }

    @media (min-width: 992px) {
        *::-ms-backdrop,
        .tasks .ckbox {
            margin-right: auto;
            margin-left: 10px;
        }

        *::-ms-backdrop,
        .app-sidebar__toggle {
            margin-right: auto !important;
            margin-left: 30px !important;
        }
    }

    @media (max-width: 767px) {
        *::-ms-backdrop,
        .main-header-left .responsive-logo {
            margin-right: 40px !important;
            margin-left: auto !important;
        }

        *::-ms-backdrop,
        &.sidebar-mini .main-header .main-header-left .header-brand {
            margin-right: 30px;
            margin-left: auto;
        }
    }

    *::-ms-backdrop,
    #list3 me-4 {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    .adtocart {
        left: auto;
        right: 40%;
    }

    .prod-cat li ul {
        margin-left: auto;
        margin-right: 44px;
    }

    .nav.prod-cat li a i {
        margin-left: 14px;
        margin-right: auto;
    }

    .product-sale {
        left: 5px;
        right: 13px;

        .wishlist {
            float: left;
            right: auto;
            left: 26px;
        }
    }

    .shopping-cart .product-item .product-thumb,
    .wishlist-table .product-item .product-thumb,
    .order-table .product-item .product-thumb {
        padding-right: inherit;
        padding-left: 0;
    }

    .shopping-cart .product-item .product-title small,
    .wishlist-table .product-item .product-title small,
    .order-table .product-item .product-title small {
        margin-left: auto;
        margin-right: 6px;
    }

    .shopping-cart-footer > .column:last-child {
        text-align: left;

        .btn {
            margin-right: 15px;
            margin-left: 0;
        }
    }

    .coupon-form .form-control {
        margin-right: 0;
        margin-left: 12px;
    }

    .widget-user .widget-user-image {
        left: auto;
        right: 50%;
        margin-right: -45px;
        margin-left: auto;
    }

    .user-wideget .border-end {
        border-right: 0 !important;
        border-left: 1px solid #f4f4f4;
    }

    @media (max-width: 991.98px) {
        .main-header-menu-show .main-header-menu {
            border-right: 0;
            border-left: 1px solid rgba(28, 39, 60, 0.08);
        }
    }

    @media (max-width: 633px) {
        .profile-name.ml-2 {
            margin-right: 0;
            margin-left: auto;
        }
    }

    .main-header-left .list-items li i {
        margin-right: auto;
        margin-left: 15px;
    }

    .main-logo i {
        margin-right: auto;
        margin-left: 8px;
    }

    .main-header-menu-icon {
        margin-right: auto;
        margin-left: 10px;

        span {
            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .main-header-menu {
        left: auto;
        right: 0;

        .nav-item {
            &::before {
                margin-right: 25px;
                margin-left: auto;
                border-left: 0;
                border-right: 2px solid rgba(28, 39, 60, 0.12);
            }

            > .nav-link {
                &.with-sub::after {
                    margin-left: 0;
                    margin-right: auto;
                }

                .typcn {
                    margin-right: 0;
                    margin-left: 15px;
                }
            }

            &.active > .nav-link::before {
                left: auto;
                right: -20px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu {
            border-right: 1px solid #e3e8f7;
            border-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item + .nav-item {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link.with-sub::after {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item > .nav-link .typcn {
            margin-right: auto;
            margin-left: 7px;
        }
    }

    .main-menu-sub {
        margin-left: auto;
        margin-right: 32px;
    }

    @media (min-width: 992px) {
        .main-menu-sub {
            margin-right: 0;
            margin-left: auto;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .container > div + div {
            border-left: 0;
            border-right: 1px solid rgba(28, 39, 60, 0.05);
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .nav + .nav {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-menu-sub-mega .nav + .nav {
            margin-right: 25px;
            margin-left: auto;
        }
    }

    .main-header-right {
        .btn-social + .btn-social {
            margin-right: 10px;
            margin-left: auto;
        }

        .btn-buy {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    .main-header-search-link {
        margin-right: auto;
        margin-left: 15px;
    }

    .main-profile-menu > .profile-name::after {
        left: 0;
        right: 50%;
        margin-left: auto;
        margin-right: -7px;
        border-left-color: transparent;
        border-right-color: $primary;
    }

    .main-header-notification .dropdown-menu,
    .main-header-message .dropdown-menu {
        right: auto;
        left: -10px;
    }

    .main-notification-list .media-body {
        margin-left: auto;
        margin-right: 15px;
    }

    .main-profile-menu {
        > .main-img-user::before {
            right: 0;
            left: -90px;
            margin-left: auto;
            margin-right: -7px;
            border-left-color: transparent;
            border-right-color: $gray-300;
        }

        .dropdown-menu {
            right: auto;
            left: -10px;
        }

        .dropdown-item i {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .main-header form[role="search"] {
        right: auto;
        left: 0;

        &.active {
            left: 0;
            border-left: 0;
            border-right: 1px solid #e3e3f7;
            right: -1px;

            input {
                padding-left: inherit;
                padding-right: 20px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu-icon {
            margin-right: auto !important;
            margin-left: 8px !important;
        }
    }

    @media (min-width: 992px) {
        .main-header-left .btn {
            right: auto;
            left: 12px;
            padding-right: inherit;
            padding-left: 13px;
        }
    }

    .header-megamenu-dropdown i {
        margin-right: auto;
        margin-left: 3px;
    }

    .main-header-arrow {
        right: 0;
        left: auto;
    }

    @media (min-width: 992px) {
        .main-header {
            right: 0;
            left: auto;
            padding-right: 240px;
            padding-left: 0;
        }
    }

    .horizontalMenucontainer .main-header.hor-header {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (max-width: 320px) {
        .main-profile-menu {
            padding-right: 7px !important;
            padding-left: 0 !important;
        }
    }

    @media (min-width: 576px) {
        .main-header .dropdown-menu:after {
            right: auto;
            left: 19px;
        }
    }

    .desktoplogo {
        float: right;
    }

    .horizontalMenu > .horizontalMenu-list {
        text-align: right;

        > li {
            float: right;

            &:last-child > a {
                border-right: 1px solid #e3e8f7;
                border-left: 0px;
            }

            > a {
                i {
                    margin-right: auto;
                    margin-left: 5px;
                }

                .arrow:after {
                    float: left;
                    right: auto;
                    left: 11px;
                    margin: 0 14px 0 0;
                    text-align: left;
                }
            }

            &.rightmenu {
                float: left;

                a {
                    padding: 0px 20px 0px 30px;
                    border-right: 1px solid #e3e8f7;
                    border-left: none;
                }

                > .topmenusearch {
                    float: left;

                    input {
                        float: left;
                        right: auto;
                        left: 0;
                    }

                    .btnstyle {
                        right: auto;
                        left: 0;
                    }
                }
            }

            > {
                ul.sub-menu > li > {
                    a {
                        border-left: 0 none;
                        border-right: 1px solid #e3e8f7;
                        text-align: right;

                        > i {
                            margin-right: auto;
                            margin-left: 9px;
                        }
                    }

                    ul.sub-menu {
                        left: auto;
                        right: 100%;

                        > li > {
                            a {
                                border-left: 0 none;
                                border-right: 1px solid #e3e8f7;
                                text-align: right;

                                > i {
                                    margin-right: auto;
                                    margin-left: 9px;
                                }
                            }

                            ul.sub-menu {
                                left: auto;
                                right: 100%;

                                > li > a {
                                    border-left: 0 none;
                                    border-right: 1px solid #e3e8f7;
                                    text-align: right;

                                    > i {
                                        margin-right: auto;
                                        margin-left: 9px;
                                    }
                                }
                            }
                        }
                    }
                }

                .horizontal-megamenu {
                    left: auto;
                    right: 0px;
                    text-align: right;

                    .title {
                        padding: 5px 0px 5px 5px;
                        text-align: right;
                    }

                    .link-list li {
                        text-align: right;

                        a {
                            border-left: 0 none;
                            border-right: 1px solid #e3e8f7;
                            text-align: right;
                        }
                    }

                    li i {
                        margin-right: auto;
                        margin-left: 5px;
                        margin-right: auto;
                        margin-left: 5px;
                    }

                    .menu_form input {
                        &[type="submit"] {
                            float: left;
                            margin-right: auto;
                            margin-left: 15px;
                        }

                        &[type="button"] {
                            float: left;
                        }
                    }
                }

                ul.sub-menu .sub-menu-sub:after {
                    right: auto;
                    left: 14px;
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .horizontalMenu {
            right: auto;
            left: 0;

            > .horizontalMenu-list {
                margin-left: auto;
                margin-right: -240px;
                border-right: 0;
                border-left: 1px solid #e8ebf4;

                > li {
                    border-right: 1px solid #e3e8f7;
                    border-left: none;

                    > {
                        a {
                            padding: 8px 17px 8px 32px;
                            text-align: right;
                            border-right: 1px solid #e3e8f7;
                            border-left: solid 0px;
                        }

                        ul.sub-menu > li {
                            > a {
                                padding: 10px 36px 10px 0px;
                            }

                            span + a {
                                padding-right: inherit;
                                padding-left: 30px;
                            }

                            > ul.sub-menu {
                                left: auto;
                                right: 100%;

                                > li {
                                    > a {
                                        padding: 10px 62px 10px 0px;

                                        &:before {
                                            left: auto;
                                            right: 45px;
                                        }
                                    }

                                    span + a {
                                        padding-right: inherit;
                                        padding-left: 30px;
                                    }

                                    > ul.sub-menu {
                                        left: auto;
                                        right: 100%;

                                        > li {
                                            > a {
                                                padding: 10px 80px 10px 0px;
                                            }

                                            span + a {
                                                padding-right: inherit;
                                                padding-left: 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .horizontal-megamenu .title {
                            padding: 10px 0px 10px 8px;
                        }
                    }
                }
            }
        }

        .horizontal-header {
            left: auto;
            right: 0;
        }

        .callusbtn {
            right: auto;
            left: 5px;
        }

        .animated-arrow {
            left: auto;
            right: 0;
            padding: 14px 0px 16px 35px;
            margin: 18px 15px 0 0px;
        }

        .horizontal-overlapbg {
            right: auto;
            left: 0;
        }

        .active {
            .horizontalMenucontainer {
                margin-right: 240px;
                margin-left: auto;
            }

            .horizontalMenu > .horizontalMenu-list {
                margin-left: auto;
                margin-right: 0;
            }
        }

        .horizontalMenu > .horizontalMenu-list > li > {
            .horizontalMenu-click {
                right: auto;
                left: 0;

                > i {
                    margin-right: auto;
                    margin-left: 10px;
                    float: left;
                }
            }

            ul.sub-menu > li .horizontalMenu-click02 {
                right: auto;
                left: 0;

                > i {
                    float: left;
                }
            }
        }

        .mega-menubg.hor-mega-menu ul {
            margin-right: 0;
            margin-left: auto;
            padding-left: inherit;
            padding-right: 0;
        }

        .desktop-logo {
            margin-left: auto !important;
            margin-right: 2rem !important;
        }
    }

    @media (max-width: 549px) {
        .desktop-logo-1 {
            margin-left: auto;
            margin-right: 0;
        }

        .horizontalMenucontainer .header-brand {
            margin-left: auto;
            margin-right: 3rem;
        }
    }

    @media (min-width: 992px) {
        .side-badge {
            right: auto;
            left: 21px;
        }
    }

    .sub-menu li a:before {
        left: auto;
        right: 12px;
    }

    .mega-menubg li a:before {
        left: auto;
        right: 6px;
    }

    .horizontalMenu > .horizontalMenu-list > li > a i.horizontal-icon {
        margin-right: auto;
        margin-left: 0;
    }

    .hor-icon {
        margin-right: auto;
        margin-left: 3px;
    }

    .horizontal-badge {
        margin-left: auto;
        margin-right: 1px;
    }

    .horizontalMenucontainer .side-menu__icon {
        margin-right: auto;
        margin-left: 7px;
    }

    @media (max-width: 991px) {
        .horizontalMenu
            > .horizontalMenu-list
            > li
            > ul.sub-menu
            > li
            > ul.sub-menu
            > li
            > ul.sub-menu
            > li
            > a:before {
            right: 62px;
            left: auto;
        }
    }

    .main-iconbar {
        left: auto;
        right: 0;
    }

    .main-iconbar-logo::after {
        left: auto;
        right: 20px;
    }

    .main-iconbar-aside {
        left: auto;
        right: 64px;
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside {
            border-right: 1px solid #e3e8f7;
            border-left: 0;
        }
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside.show + .main-content {
            margin-left: auto;
            margin-right: 294px;
        }
    }

    .main-iconbar-header {
        padding-left: inherit;
        padding-right: 0;
    }

    @media (min-width: 1200px) {
        .main-iconbar-toggle-menu::before {
            left: 0;
            right: -1px;
            border-left: 0;
            border-right: 2px solid $gray-900;
        }
    }

    .main-iconbar-body {
        padding-left: inherit;
        padding-right: 0;

        .nav {
            border-left: 0;
            border-right: 2px solid $border;
            padding-left: inherit;
            padding-right: 20px;
        }

        .nav-link {
            i {
                margin-right: auto;
                margin-left: 10px;
            }

            &.with-sub::after {
                margin-left: 0;
                margin-right: auto;
            }
        }

        .nav-sub .nav-sub-item {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    @media (min-width: 992px) {
        .main-iconbar-body .nav-link.with-sub::after {
            margin-left: auto;
            margin-right: 4px;
        }
    }

    @media (max-width: 991.98px) {
        .main-content-left-show .main-content-left {
            left: auto;
            right: 0;
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }
    }

    .main-content-right {
        padding-left: 0;
        padding-right: 25px;
        margin-right: 25px;
        margin-left: auto;
    }

    .main-content.horizontal-content {
        margin-left: 0;
        margin-right: 0;
    }

    @media (min-width: 992px) {
        .main-content {
            margin-left: 0;
            margin-right: 240px;
        }

        .main-body.main-sidebar-hide .main-content {
            margin-right: 80px !important;
            margin-left: auto !important;
        }
    }

    .main-content-left-components {
        border-right: 0;
        border-left: 1px solid $border;
    }

    .main-header-profile .main-img-user::after {
        right: auto;
        left: 6px;
    }

    .main-profile-menu .profile-user img {
        margin-left: auto;
        margin-right: 0;
    }

    @media (min-width: 992px) {
        .main-content-left-profile {
            padding-right: 0;
            padding-left: 20px;
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 1200px) {
        .main-content-left-profile {
            padding-right: 0;
            padding-left: 25px;
        }
    }

    .main-profile-social-list .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    .main-content-body-profile .nav {
        padding: 20px 0px 20px 20px;
    }

    @media (min-width: 992px) {
        .main-content-body-profile .nav {
            padding-right: 20px;
            padding-left: 0;
        }
    }

    @media (min-width: 1200px) {
        .main-content-body-profile .nav {
            padding-left: 0;
            padding-right: 25px;
        }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        .main-content-body-profile .main-nav-line .nav-link + .nav-link {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    @media (min-width: 992px) {
        .main-profile-body {
            padding: 25px 20px 0 0px;
        }
    }

    @media (min-width: 1200px) {
        .main-profile-body {
            padding-left: 0;
            padding-right: 25px;
        }
    }

    .main-profile-view-info {
        left: auto;
        right: 0;

        span {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    .main-profile-work-list .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    .main-profile-contact-list .media-body {
        margin-left: auto;
        margin-right: 25px;
    }

    @media (max-width: 578px) {
        .main-profile-menu .profile-user img {
            margin-right: 0;
            margin-left: auto;
        }
    }

    .profile-user .profile-edit {
        right: auto !important;
        left: 0;
    }

    .main-profile-body .card-header {
        padding-left: inherit;
        padding-right: 0;
    }

    .drop-down-profile span.assigned-task {
        left: 0;
        right: auto;
    }

    @media (max-width: 575px) {
        .main-content-body-profile .main-nav-line .nav-link.active::before {
            left: auto;
            right: 0px;
        }
    }

    @media (max-width: 767.98px) {
        .main-profile-menu .profile-user img {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }

    .profile.navtab-custom li a {
        margin-right: auto;
        margin-left: 7px;
    }

    .main-sidebar-body .nav-sub-item {
        margin-right: 55px;
        margin-left: auto;
    }

    .main-sidebar {
        border-right: 1px solid #d8dfea;
        border-left: 0;
    }

    @media (max-width: 991.98px) {
        .main-sidebar {
            left: auto;
            right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar {
            border-right-width: 0;
            border-left-width: 1px;
        }

        .main-sidebar-hide .main-sidebar-body .nav-link {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .main-sidebar-sticky {
        left: auto;
        right: 0;
    }

    @media (min-width: 992px) {
        .main-sidebar-sticky + .main-content {
            margin-right: 220px;
            margin-left: auto;
        }
    }

    @media (min-width: 1200px) {
        .main-sidebar-sticky + .main-content {
            margin-right: 240px;
            margin-left: auto;
        }
    }

    .main-sidebar-header {
        border-right: 0;
        border-left: 1px solid $border;
    }

    .main-sidebar .nav-pills-circle.nav .nav-item .dropdown-menu {
        left: auto;
        right: -30px;

        .dropdown-item i {
            margin-right: auto;
            margin-left: 3px;
        }
    }

    .main-sidebar-loggedin .media-body {
        margin-left: auto;
        margin-right: 12px;
    }

    .main-sidebar-body {
        .nav-item.active .nav-link .nav-badge {
            right: auto !important;
            left: 12px !important;
        }

        .nav-sub {
            margin-left: auto;
            margin-right: 32px;
        }

        .nav-item + .nav-item::before {
            left: 0;
            right: 30px;
        }
    }

    .nav-link.with-sub {
        .angle,
        .side-badge {
            right: 0;
            left: 23px;
        }
    }

    .main-sidebar-body {
        .nav-sub {
            margin: 0 7px 0 0px;
        }

        .nav-sub-item .nav-sub {
            padding: 0 18px 0 0;
            border-left-width: 0;
            border-right-width: 1px;
            margin-left: auto;
            margin-right: 0;
        }

        .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: auto;
        }

        .nav-sub li .nav-sub-link::after {
            right: -30px;
            left: auto;
        }

        .nav-link .leftmenu-icon {
            margin-right: auto;
            margin-left: 4px;
        }

        .nav-label:after {
            right: 13px;
            left: auto;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-right: 4px;
            margin-left: auto;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-hide .main-sidebar-body .nav-link i.typcn {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .sidebar-right .main-nav-line .nav-link {
        padding: 10px 21px 10px 18px;
    }

    .main-sidebar-body .nav-sub-item {
        margin-right: 0;
        margin-left: auto;
        text-align: right;
        padding: 2.2px 52px 2.2px 25px;
    }

    .main-sidebar-loggedin .media-body {
        margin-right: 13px;
        margin-left: auto;
    }

    .main-sidebar-body {
        .nav-item.active:before {
            left: auto;
            right: 0px;
        }

        .nav .nav-item .nav-sub:before {
            right: 0px;
            left: auto;
        }

        .nav-sub .nav-sub-item .nav-sub-link:before::hover {
            left: 0px;
            right: -27px;
        }
    }

    .main-sidebar .search .btn {
        right: auto;
        left: 0;
        padding-right: 0;
        padding-left: 31px;
    }

    .main-donut-chart .slice {
        right: 0;
        left: auto;
    }

    .legend {
        margin-right: 0;
        margin-left: 10px;
    }

    .dot-label {
        left: 0;
        right: 9px;
    }

    .chart-legend {
        div + div {
            margin-right: 15px;
            margin-left: auto;
        }

        span {
            margin-left: 5px;
            margin-right: auto;
        }
    }

    @media (min-width: 576px) {
        .chart-legend div + div {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    @media (min-width: 576px) {
        .chart-legend span {
            margin-right: auto;
            margin-left: 10px;
        }
    }

    @media (min-width: 576px) {
        .chart-wrapper {
            margin-left: -15px;
            margin-right: -10px;
        }
    }

    table.dataTable {
        thead {
            .sorting_asc::after,
            .sorting_desc::after {
                right: auto;
                left: 10px;
            }

            .sorting {
                &::before,
                &::after {
                    right: auto;
                    left: 10px;
                }
            }
        }

        &.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
            left: auto;
            right: 7px;
        }
    }

    .dataTables_wrapper {
        .dataTables_length {
            text-align: right;

            .select2-container--default {
                margin-right: auto;
                margin-left: 5px;
            }
        }

        .dataTables_filter {
            text-align: right;

            input {
                margin-right: 0;
                margin-left: auto;
            }
        }

        .dataTables_info {
            text-align: right;
        }

        .dataTables_paginate {
            text-align: right;

            .paginate_button {
                + .paginate_button {
                    margin-left: auto;
                    margin-right: 3px;
                }

                &.previous {
                    margin-right: auto;
                    margin-left: 3px;
                }

                &.next {
                    margin-right: 3px;
                    margin-left: auto;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_length {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_filter {
            float: left;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_info {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .dataTables_wrapper .dataTables_paginate {
            float: left;
        }
    }
    @media (max-width: 400px) {
        .btn-primary.data-table-btn {
            right: 0;
        }
    }

    .hoverable-table .btn-primary {
        margin-left: auto;
        margin-right: 98px;
    }

    .ui-datepicker .ui-datepicker-header {
        .ui-datepicker-next:before {
            right: auto;
            left: 5px;
        }

        .ui-datepicker-prev:before {
            left: auto;
            right: 5px;
        }
    }

    .datetimepicker table th {
        &.prev span::before {
            left: auto;
            right: 0;
        }

        &.next span::before {
            right: auto;
            left: 0;
        }
    }

    .main-datetimepicker > {
        .datepicker_header a {
            &:nth-child(2)::before {
                left: auto;
                right: 0;
            }

            &:last-child::before {
                right: auto;
                left: 0;
            }
        }

        .datepicker_inner_container > .datepicker_timelist {
            border-left: 0;
            border-right: 1px solid $gray-400;

            > div.timelist_item {
                margin: 0 1px 0 0;
            }
        }
    }

    .wizard {
        > .steps > ul li {
            .title {
                margin-left: 0;
                margin-right: 5px;
            }

            + li {
                margin-right: 5px;
                margin-left: 0;
            }
        }

        &.vertical > .steps ul li + li {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li .title {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li + li {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    @media (min-width: 992px) {
        .wizard > .steps > ul li + li {
            margin-left: 0;
            margin-right: 30px;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps ul li + li {
            margin-left: 0;
            margin-right: 0px;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .content {
            float: left;
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .actions {
            float: left;
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    .wizard-style-2 > .steps > ul a {
        .title,
        &:hover .title,
        &:active .title {
            margin-right: 0;
            margin-left: 20px;
        }
    }

    #jqvmap1_ca_pin {
        margin-right: -2%;
        margin-left: auto;
    }

    #jqvmap1_ct_pin {
        margin-right: -0.25%;
        margin-left: auto;
    }

    #jqvmap1_fl_pin {
        margin-right: 5%;
        margin-left: auto;
    }

    #jqvmap1_id_pin {
        margin-right: -1%;
        margin-left: auto;
    }

    #jqvmap1_ky_pin {
        margin-right: 2%;
        margin-left: auto;
    }

    #jqvmap1_la_pin {
        margin-right: -2%;
        margin-left: auto;
    }

    #jqvmap1_mi_pin {
        margin-right: 3%;
        margin-left: auto;
    }

    #jqvmap1_mn_pin {
        margin-right: -2%;
        margin-left: auto;
    }

    #jqvmap1_nh_pin {
        margin-right: -0.25%;
        margin-left: auto;
    }

    #jqvmap1_ok_pin,
    #jqvmap1_va_pin {
        margin-right: 2%;
        margin-left: auto;
    }

    #jqvmap1_wv_pin {
        margin-right: -1%;
        margin-left: auto;
    }

    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0 8px;

        &:first-child {
            margin-right: 0;
            margin-left: auto;
        }
    }

    @media (min-width: 576px) {
        .ql-bubble .ql-toolbar .ql-formats:first-child {
            margin-right: 0;
            margin-left: auto;
        }
    }

    .ql-snow {
        &.ql-toolbar .ql-formats {
            margin-right: auto;
            margin-left: 5px;
        }

        .ql-formats button + button {
            margin: 0 2px 0 0;
        }

        .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
            right: auto;
            left: 3px;
        }

        .ql-picker-label {
            padding-left: inherit;
            padding-right: 10px;
        }
    }

    @media (min-width: 768px) {
        .ql-scrolling-demo {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .irs-bar-edge,
    .irs-line-left {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .irs-line-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .irs-slider:before {
        left: auto;
        right: 50%;
        margin-right: -1px;
        margin-left: auto;
    }

    .irs-modern .irs-slider::before {
        margin-right: -2px;
        margin-left: auto;
    }

    .irs-outline {
        .irs-bar-edge {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .irs-slider::before {
            margin-right: -4px;
            margin-left: auto;
        }
    }

    .select2-container--default {
        .select2-selection--single .select2-selection__rendered {
            padding-left: inherit;
            padding-right: 0.75rem;
        }

        .select2-selection--multiple {
            .select2-selection__choice {
                margin-right: auto;
                margin-left: 4px;
                padding: 3px 20px 3px 10px;
            }

            .select2-selection__choice__remove {
                left: auto;
                right: 7px;
            }
        }

        .select2-search--inline .select2-search__field {
            padding-right: 7px;
            padding-left: inherit;
        }

        &.select2-container--disabled .select2-selection__choice {
            padding-right: 10px;
            padding-left: inherit;
        }
    }

    .sp-picker-container {
        border-right: 0;
        border-left: 1px solid #e3e8f7;
    }

    .sp-dd::before {
        right: 0;
        left: auto;
    }

    .card--calendar .card--events .list-group-item {
        padding: 13px 40px 13px 20px;
        padding: 13px 40px 13px 20px;
    }

    .card--events {
        .list-group-item {
            padding: 15px 40px 15px 20px;
        }

        .event-indicator {
            right: 20px;
            left: auto;
        }

        .event-user {
            .main-img-user + .main-img-user {
                margin-right: -6px;
                margin-left: auto;
            }

            a:last-child {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    }

    .ui-datepicker .ui-datepicker-calendar td {
        text-align: left;

        &:last-child {
            border-right: 1px solid #e3e8f7;
            border-left: 0;
        }
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            padding-right: 0;
            padding-left: 15px;
            float: right;

            .ui-datepicker-prev::before {
                left: auto;
                right: 10px;
            }

            .ui-datepicker-next::before {
                right: auto;
                left: 10px;
            }
        }

        .ui-datepicker-group-last {
            padding-right: inherit;
            padding-left: 0;
        }
    }

    .main-nav-calendar-event .nav-link i {
        margin-right: auto;
        margin-left: 10px;
    }

    @media (min-width: 992px) {
        .main-content-body-calendar {
            padding-left: 0;
            padding-right: 25px;
        }
    }

    .main-calendar {
        .fc-view {
            .fc-day-number {
                margin: 2px 0 0 2px;
            }

            &.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
            &.fc-listWeek-view .fc-list-item > td.fc-list-item-time,
            &.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
            &.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
                border-right: 0;
                border-left: 1px solid $border;
            }

            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-left: auto;
                margin-right: 20%;
            }
        }

        .fc-time-grid-event {
            border-left-width: 1px;
            border-right-width: 0;
        }
    }

    @media (max-width: 575px) {
        .main-calendar .fc-header-toolbar button {
            &.fc-month-button::before,
            &.fc-agendaWeek-button::before,
            &.fc-agendaDay-button::before,
            &.fc-listWeek-button::before,
            &.fc-listMonth-button::before {
                left: auto;
                right: 10px;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > thead {
            th:first-child,
            td:first-child {
                border-left-width: 1px;
                border-right-width: 0;
            }

            th:last-child,
            td:last-child {
                border-left-width: 0;
                border-right-width: 1px;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view > table > tbody > tr > td {
            &:first-child {
                border-left-width: 1px;
                border-right-width: 0;
            }

            &:last-child {
                border-left-width: 0;
                border-right-width: 1px;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-right: 15%;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 768px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-right: 12%;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 992px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-right: 10%;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 1200px) {
        .main-calendar .fc-view {
            &.fc-listMonth-view .fc-list-item + .fc-list-item,
            &.fc-listWeek-view .fc-list-item + .fc-list-item {
                margin-right: 8%;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-event {
            border-left-width: 0;
            border-right-width: 2px;
        }
    }

    .main-modal-calendar-schedule {
        .modal-content > .close {
            right: 0;
            left: 25px;
        }

        .form-group-date > i {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .main-modal-calendar-event .nav-modal-event .nav-link + .nav-link {
        margin-left: auto;
        margin-right: 15px;
    }

    @media (max-width: 320px) {
        .main-calendar .fc-header-toolbar {
            .fc-right,
            .fc-left {
                justify-content: right;
            }
        }
    }

    .main-nav-line-chat {
        padding-right: 20px;
        padding-left: 0;

        .nav-link + .nav-link {
            margin-right: 30px;
            margin-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-nav-line-chat {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .main-chat-contacts-wrapper .main-img-user::after,
    .main-chat-list .main-img-user::after,
    .main-chat-header .main-img-user::after,
    .main-chat-body .main-img-user::after {
        right: auto;
        left: 1px;
    }

    .main-chat-contacts-wrapper {
        .main-content-label,
        .card-table-two .card-title {
            padding-left: 0;
            padding-right: 20px;
        }
    }

    @media (min-width: 992px) {
        .main-chat-contacts-wrapper {
            padding: 10px 0 10px 15px;
        }
    }

    .card-table-two .main-chat-contacts-wrapper .card-title,
    .main-chat-contacts-wrapper .card-dashboard-eight .card-title,
    .card-dashboard-eight .main-chat-contacts-wrapper .card-title {
        padding-left: 0;
        padding-right: 20px;
    }

    .main-chat-contacts {
        padding-right: 20px;
        padding-left: 0;
    }

    .main-chat-list {
        .media {
            &::after {
                left: 0;
                right: -1px;
            }

            + .media::before {
                left: 0;
                right: 65px;
            }

            &.selected {
                border-right: 1px solid $primary;
                border-left: 0;
            }
        }

        .main-img-user span {
            right: -2px;
            left: auto;
        }

        .media-body {
            margin-right: 15px;
            margin-left: auto;
        }
    }

    .main-chat-header {
        .nav {
            margin-right: auto;
            margin-left: 0;
        }

        .nav-link + .nav-link {
            margin-right: 15px;
            margin-left: 0;
        }
    }

    .main-chat-msg-name {
        margin-left: 0;
        margin-right: 15px;
    }

    .main-chat-body {
        .media.flex-row-reverse .media-body {
            margin-left: 20px;
            margin-right: 0;
        }

        .media-body {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    @media (min-width: 576px) {
        .main-chat-body .media.flex-row-reverse .media-body {
            margin-left: 20;
            margin-right: 55px;
        }
    }

    @media (min-width: 576px) {
        .main-chat-body .media-body {
            margin-right: 20px;
            margin-left: 55px;
        }
    }

    .main-chat-footer .nav-link {
        + .nav-link {
            margin-right: 10px;
            margin-left: 0;
        }

        &:last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 576px) {
        .main-chat-footer .form-control {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    #action_menu_btn {
        right: auto;
        left: 36px;
    }

    .action_menu {
        right: auto;
        left: 15px;
    }

    .action-header {
        padding: 15px 17px 15px 13px;
    }

    .ah-actions {
        float: left !important;
    }

    .chat {
        .action-header {
            padding: 15px 17px 15px 13px;
        }

        .dropdown-menu.dropdown-menu-end.show {
            right: 0px;
            left: auto;
        }

        .msb-reply textarea {
            margin-left: auto;
            margin-right: -42px;
        }
    }

    .chatbox .user_info {
        margin-right: 15px;
        margin-left: auto;
    }

    .chat {
        .video_cam {
            margin-right: 50px;
            margin-left: auto;

            span {
                margin-right: auto;
                margin-left: 20px;
            }
        }

        .msg_cotainer {
            margin-right: 10px;
            margin-left: auto;
        }

        .msg_cotainer_send {
            margin-left: 10px;
            margin-right: auto;
        }

        .msg_time {
            left: auto;
            right: 0;
        }

        .msg_time_send {
            right: auto;
            left: 0;
        }
    }

    @media (max-width: 449px) {
        .actions {
            float: right !important;
        }
    }

    .main-content-left-contacts {
        .main-content-breadcrumb,
        .main-content-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-contacts {
            .main-content-breadcrumb,
            .main-content-title {
                padding-left: inherit;
                padding-right: 0;
            }
        }
    }

    .main-contact-label {
        padding-right: 20px;
        padding-left: inherit;
    }

    @media (min-width: 992px) {
        .main-contact-label {
            padding-right: 30px;
            padding-left: 0;
        }
    }

    .main-contact-item {
        border-right-width: 1px;
        border-left-width: 0;

        + .main-contact-item::before {
            left: 0;
            right: 65px;
        }

        &.selected {
            border-right-color: $primary;
            border-left-color: transparent;
        }
    }

    .main-contact-body {
        margin-right: 15px;
        margin-left: auto;
    }

    .main-contact-info-header {
        padding-right: 20px;
        padding-left: inherit;

        .main-img-user a {
            right: auto;
            left: 0;
        }

        .media-body .nav-link {
            i {
                margin-right: auto;
                margin-left: 5px;
            }

            + .nav-link {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 992px) {
        .main-contact-info-header {
            padding-right: 30px;
            padding-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-contact-info-header .media-body {
            margin-right: 30px;
            margin-left: auto;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link i {
            margin-right: 0;
            margin-left: 7px;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link + .nav-link {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    .main-contact-action {
        right: auto;
        left: 20px;

        a + a {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    @media (min-width: 992px) {
        .main-contact-action {
            right: auto;
            left: 20px;
        }
    }

    .main-contact-info-body {
        padding-left: inherit;
        padding-right: 20px;

        .media + .media::before {
            left: 0;
            right: -19px;
        }

        .media-body {
            margin-right: 0;
            margin-left: auto;
        }
    }

    .main-contact-item .main-img-user::after {
        right: auto;
        left: 0;
    }

    .page-signin-style {
        &:before {
            left: auto;
            right: 0;
        }

        right: 0;
        left: auto;
    }

    @media (min-width: 992px) {
        .main-column-signup {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    .main-signup-header .row > div {
        .btn i,
        .sp-container button i {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    .sp-container .main-signup-header .row > div button i {
        margin-right: auto;
        margin-left: 5px;
    }

    .construction {
        .input-group-text {
            right: auto;
            left: 0;
        }

        .input-group > {
            .input-group-text > .btn {
                float: left;
            }

            .form-control:not(:last-child),
            .form-select:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }

    .main-content-left-invoice {
        .main-content-breadcrumb,
        .main-content-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {
            .main-content-breadcrumb,
            .main-content-title {
                padding-right: 0;
                padding-left: inherit;
            }
        }
    }

    .main-invoice-list {
        .media {
            border-right-width: 1px;
            border-left-width: 0;

            + .media::before {
                left: 0;
                right: 55px;
            }
        }

        .media-body {
            margin-left: 0;
            margin-right: 15px;
        }

        .selected {
            border-left-color: transparent;
            border-right-color: $primary;
        }
    }

    .table-invoice tbody > tr > {
        th:first-child .invoice-notes,
        td:first-child .invoice-notes {
            margin-right: auto;
            margin-left: 20px;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-mail .btn-compose {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .main-mail-header {
        > div:last-child > span {
            margin-right: auto;
            margin-left: 10px;
        }

        .btn-group {
            .btn + .btn,
            .sp-container button + .btn {
                margin-left: auto;
                margin-right: -2px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-mail-header {
            padding: 0 25px 0 0;
        }
    }

    .sp-container .main-mail-header .btn-group button + .btn,
    .main-mail-header .btn-group .sp-container .btn + button,
    .sp-container .main-mail-header .btn-group .btn + button,
    .main-mail-header .btn-group .sp-container button + button,
    .sp-container .main-mail-header .btn-group button + button {
        margin-left: auto;
        margin-right: -2px;
    }

    .main-mail-options {
        padding: 5px 20px 5px 10px;
    }

    @media (min-width: 992px) {
        .main-mail-options {
            padding-left: inherit;
            padding-right: 25px;
        }
    }

    .main-mail-item {
        .main-img-user,
        .main-avatar {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    .main-mail-checkbox {
        margin-right: 0;
        margin-left: 15px;
    }

    .main-mail-star {
        margin-right: 0;
        margin-left: 15px;
        right: auto;
        left: 0;
    }

    @media (min-width: 992px) {
        .main-mail-body {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    .main-mail-attachment {
        margin-right: 0;
        margin-left: 15px;
    }

    @media (min-width: 992px) {
        .main-mail-attachment {
            right: auto;
            left: 63px;
        }
    }

    .main-mail-date {
        right: auto;
        left: 15px;
        margin-left: 0;
        margin-right: auto;
    }

    .main-mail-compose {
        left: auto;
        right: 0;
    }

    .main-mail-compose-header .nav-link + .nav-link {
        margin-right: 15px;
        margin-left: 0;
    }

    .main-mail-compose-body .form-group {
        > div {
            margin-right: 10px;
            margin-left: auto;
        }

        .nav-link + .nav-link {
            margin-right: 15px;
            margin-left: auto;
        }
    }

    .main-mail-compose-compress,
    .main-mail-compose-minimize {
        left: 30px;
        right: auto;
    }

    .main-mail-two .main-header-menu-icon {
        margin-right: auto;
        margin-left: 0;
    }

    .main-mail-left {
        border-right: 0;
        border-left: 1px solid $gray-400;

        .main-mail-menu {
            padding-right: inherit;
            padding-left: 0;
        }
    }

    .bd-r {
        border-right: 0;
        border-left: 1px solid #e3e8f7;
    }

    .bd-l,
    .bd-r-0 {
        border-left: 0;
        border-right: 1px solid #e3e8f7;
    }

    .bd-l-0 {
        border-right: 0;
        border-left: 1px solid #e3e8f7;
    }

    @media (min-width: 480px) {
        .bd-xs-r {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }

        .bd-xs-l {
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 576px) {
        .bd-sm-r {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }

        .bd-sm-l {
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 768px) {
        .bd-md-r {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }

        .bd-md-l {
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 992px) {
        .bd-lg-r {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }

        .bd-lg-l {
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    @media (min-width: 1200px) {
        .bd-xl-r {
            border-right: 0;
            border-left: 1px solid #e3e8f7;
        }

        .bd-xl-l {
            border-left: 0;
            border-right: 1px solid #e3e8f7;
        }
    }

    .br-ts-0 {
        border-top-left-radius: 3px !important;
        border-top-right-radius: 0 !important;
    }

    .br-bs-0 {
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: 0 !important;
    }

    .br-te-0 {
        border-top-right-radius: 3px !important;
        border-top-left-radius: 0 !important;
    }

    .br-be-0 {
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: 0 !important;
    }

    .border-start-primary {
        border-left-color: transparent !important;
        border-right-color: $primary !important;
    }

    .border-start-success {
        border-left-color: transparent !important;
        border-right-color: $success !important;
    }

    .border-start-warning {
        border-left-color: transparent !important;
        border-right-color: $warning !important;
    }

    .me-0 {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .ms-0 {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .me-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .ms-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .me-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .ms-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .me-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .ms-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .me-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .ms-4 {
        margin-left: auto !important;
        margin-right: 1.5rem !important;
    }

    .me-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .ms-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .me-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .ms-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-sm-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-sm-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-sm-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-sm-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-sm-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-sm-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-sm-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-sm-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-sm-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-sm-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-sm-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .me-sm-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-sm-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-md-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-md-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-md-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-md-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-md-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-md-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-md-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-md-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-md-4 {
            margin-left: 0 !important;
            margin-right: 1.5rem !important;
        }

        .me-md-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-md-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .me-md-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-md-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-lg-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-lg-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-lg-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-lg-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-lg-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-lg-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-lg-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-lg-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-lg-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-lg-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-lg-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .me-lg-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-lg-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .me-xl-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-xl-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-xl-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-xl-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-xl-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-xl-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-xl-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-xl-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-xl-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-xl-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-xl-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-xl-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .me-xl-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-xl-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    .mg-r-0 {
        margin-right: auto;
        margin-left: 0px;
    }

    .mg-l-0 {
        margin-right: 0px;
        margin-left: auto;
    }

    .mg-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .mg-l-1 {
        margin-right: 1px;
        margin-left: auto;
    }

    .mg-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .mg-l-2 {
        margin-right: 2px;
        margin-left: auto;
    }

    .mg-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .mg-l-3 {
        margin-right: 3px;
        margin-left: auto;
    }

    .mg-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .mg-l-4 {
        margin-right: 4px;
        margin-left: auto;
    }

    .mg-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .mg-l-5 {
        margin-right: 5px;
        margin-left: auto;
    }

    .mg-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .mg-l-6 {
        margin-right: 6px;
        margin-left: auto;
    }

    .mg-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .mg-l-7 {
        margin-right: 7px;
        margin-left: auto;
    }

    .mg-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .mg-l-8 {
        margin-right: 8px;
        margin-left: auto;
    }

    .mg-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .mg-l-9 {
        margin-right: 9px;
        margin-left: auto;
    }

    .mg-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .mg-l-10 {
        margin-right: 10px;
        margin-left: 0;
    }

    .mg-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .mg-l-15 {
        margin-right: 15px;
        margin-left: auto;
    }

    .mg-r-20 {
        margin-right: auto;
        margin-left: 20px;
    }

    .mg-l-20 {
        margin-right: 20px;
        margin-left: 0;
    }

    .mg-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .mg-l-25 {
        margin-right: 25px;
        margin-left: auto;
    }

    .mg-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .mg-l-30 {
        margin-right: 30px;
        margin-left: auto;
    }

    .mg-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .mg-l-35 {
        margin-right: 35px;
        margin-left: auto;
    }

    .mg-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .mg-l-40 {
        margin-right: 40px;
        margin-left: auto;
    }

    .mg-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .mg-l-45 {
        margin-right: 45px;
        margin-left: auto;
    }

    .mg-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .mg-l-50 {
        margin-right: 50px;
        margin-left: auto;
    }

    .mg-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .mg-l-55 {
        margin-right: 55px;
        margin-left: auto;
    }

    .mg-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .mg-l-60 {
        margin-right: 60px;
        margin-left: auto;
    }

    .mg-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .mg-l-65 {
        margin-right: 65px;
        margin-left: auto;
    }

    .mg-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .mg-l-70 {
        margin-right: 70px;
        margin-left: auto;
    }

    .mg-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .mg-l-75 {
        margin-right: 75px;
        margin-left: auto;
    }

    .mg-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .mg-l-80 {
        margin-right: 80px;
        margin-left: auto;
    }

    .mg-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .mg-l-85 {
        margin-right: 85px;
        margin-left: auto;
    }

    .mg-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .mg-l-90 {
        margin-right: 90px;
        margin-left: auto;
    }

    .mg-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .mg-l-95 {
        margin-right: 95px;
        margin-left: auto;
    }

    .mg-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .mg-l-100 {
        margin-right: 100px;
        margin-left: auto;
    }

    .mg-r-105 {
        margin-right: auto;
        margin-left: 105px;
    }

    .mg-l-105 {
        margin-right: 105px;
        margin-left: auto;
    }

    .mg-r-110 {
        margin-right: auto;
        margin-left: 110px;
    }

    .mg-l-110 {
        margin-right: 110px;
        margin-left: auto;
    }

    .mg-r-115 {
        margin-right: auto;
        margin-left: 115px;
    }

    .mg-l-115 {
        margin-right: 115px;
        margin-left: auto;
    }

    .mg-r-120 {
        margin-right: auto;
        margin-left: 120px;
    }

    .mg-l-120 {
        margin-right: 120px;
        margin-left: auto;
    }

    .mg-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .mg-l-auto {
        margin-left: 0;
        margin-right: auto;
    }

    @media (min-width: 480px) {
        .mg-xs-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-xs-l-0 {
            margin-right: 0px;
            margin-left: auto;
        }

        .mg-xs-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-xs-l-1 {
            margin-right: 1px;
            margin-left: auto;
        }

        .mg-xs-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-xs-l-2 {
            margin-right: 2px;
            margin-left: auto;
        }

        .mg-xs-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-xs-l-3 {
            margin-right: 3px;
            margin-left: auto;
        }

        .mg-xs-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-xs-l-4 {
            margin-right: 4px;
            margin-left: auto;
        }

        .mg-xs-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-xs-l-5 {
            margin-right: 5px;
            margin-left: auto;
        }

        .mg-xs-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-xs-l-6 {
            margin-right: 6px;
            margin-left: auto;
        }

        .mg-xs-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-xs-l-7 {
            margin-right: 7px;
            margin-left: auto;
        }

        .mg-xs-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-xs-l-8 {
            margin-right: 8px;
            margin-left: auto;
        }

        .mg-xs-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-xs-l-9 {
            margin-right: 9px;
            margin-left: auto;
        }

        .mg-xs-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-xs-l-10 {
            margin-right: 10px;
            margin-left: auto;
        }

        .mg-xs-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-xs-l-15 {
            margin-right: 15px;
            margin-left: auto;
        }

        .mg-xs-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-xs-l-20 {
            margin-right: 20px;
            margin-left: auto;
        }

        .mg-xs-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-xs-l-25 {
            margin-right: 25px;
            margin-left: auto;
        }

        .mg-xs-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-xs-l-30 {
            margin-right: 30px;
            margin-left: auto;
        }

        .mg-xs-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-xs-l-35 {
            margin-right: 35px;
            margin-left: auto;
        }

        .mg-xs-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-xs-l-40 {
            margin-right: 40px;
            margin-left: auto;
        }

        .mg-xs-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-xs-l-45 {
            margin-right: 45px;
            margin-left: auto;
        }

        .mg-xs-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-xs-l-50 {
            margin-right: 50px;
            margin-left: auto;
        }

        .mg-xs-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-xs-l-55 {
            margin-right: 55px;
            margin-left: auto;
        }

        .mg-xs-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-xs-l-60 {
            margin-right: 60px;
            margin-left: auto;
        }

        .mg-xs-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-xs-l-65 {
            margin-right: 65px;
            margin-left: auto;
        }

        .mg-xs-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-xs-l-70 {
            margin-right: 70px;
            margin-left: auto;
        }

        .mg-xs-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-xs-l-75 {
            margin-right: 75px;
            margin-left: auto;
        }

        .mg-xs-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-xs-l-80 {
            margin-right: 80px;
            margin-left: auto;
        }

        .mg-xs-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-xs-l-85 {
            margin-right: 85px;
            margin-left: auto;
        }

        .mg-xs-r-90 {
            margin-left: 90px;
            margin-right: auto;
        }

        .mg-xs-l-90 {
            margin-right: 90px;
            margin-left: auto;
        }

        .mg-xs-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-xs-l-95 {
            margin-right: 95px;
            margin-left: auto;
        }

        .mg-xs-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-xs-l-100 {
            margin-right: 100px;
            margin-left: auto;
        }

        .mg-xs-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-xs-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 576px) {
        .mg-sm-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-sm-l-0 {
            margin-right: 0px;
            margin-left: auto;
        }

        .mg-sm-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-sm-l-1 {
            margin-right: 1px;
            margin-left: auto;
        }

        .mg-sm-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-sm-l-2 {
            margin-right: 2px;
            margin-left: auto;
        }

        .mg-sm-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-sm-l-3 {
            margin-right: 3px;
            margin-left: auto;
        }

        .mg-sm-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-sm-l-4 {
            margin-right: 4px;
            margin-left: auto;
        }

        .mg-sm-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-sm-l-5 {
            margin-right: 5px;
            margin-left: auto;
        }

        .mg-sm-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-sm-l-6 {
            margin-right: 6px;
            margin-left: auto;
        }

        .mg-sm-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-sm-l-7 {
            margin-right: 7px;
            margin-left: auto;
        }

        .mg-sm-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-sm-l-8 {
            margin-right: 8px;
            margin-left: auto;
        }

        .mg-sm-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-sm-l-9 {
            margin-right: 9px;
            margin-left: auto;
        }

        .mg-sm-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-sm-l-10 {
            margin-right: 10px;
            margin-left: auto;
        }

        .mg-sm-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-sm-l-15 {
            margin-right: 15px;
            margin-left: auto;
        }

        .mg-sm-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-sm-l-20 {
            margin-right: 20px;
            margin-left: auto;
        }

        .mg-sm-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-sm-l-25 {
            margin-right: 25px;
            margin-left: auto;
        }

        .mg-sm-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-sm-l-30 {
            margin-right: 30px;
            margin-left: auto;
        }

        .mg-sm-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-sm-l-35 {
            margin-right: 35px;
            margin-left: auto;
        }

        .mg-sm-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-sm-l-40 {
            margin-right: 40px;
            margin-left: auto;
        }

        .mg-sm-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-sm-l-45 {
            margin-right: 45px;
            margin-left: auto;
        }

        .mg-sm-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-sm-l-50 {
            margin-right: 50px;
            margin-left: auto;
        }

        .mg-sm-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-sm-l-55 {
            margin-right: 55px;
            margin-left: auto;
        }

        .mg-sm-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-sm-l-60 {
            margin-right: 60px;
            margin-left: auto;
        }

        .mg-sm-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-sm-l-65 {
            margin-right: 65px;
            margin-left: auto;
        }

        .mg-sm-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-sm-l-70 {
            margin-right: 70px;
            margin-left: auto;
        }

        .mg-sm-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-sm-l-75 {
            margin-right: 75px;
            margin-left: auto;
        }

        .mg-sm-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-sm-l-80 {
            margin-right: 80px;
            margin-left: auto;
        }

        .mg-sm-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-sm-l-85 {
            margin-right: 85px;
            margin-left: auto;
        }

        .mg-sm-r-90 {
            margin-left: 90px;
            margin-right: auto;
        }

        .mg-sm-l-90 {
            margin-right: 90px;
            margin-left: auto;
        }

        .mg-sm-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-sm-l-95 {
            margin-right: 95px;
            margin-left: auto;
        }

        .mg-sm-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-sm-l-100 {
            margin-right: 100px;
            margin-left: auto;
        }

        .mg-sm-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-sm-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 768px) {
        .mg-md-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-md-l-0 {
            margin-right: 0px;
            margin-left: auto;
        }

        .mg-md-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-md-l-1 {
            margin-right: 1px;
            margin-left: auto;
        }

        .mg-md-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-md-l-2 {
            margin-right: 2px;
            margin-left: auto;
        }

        .mg-md-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-md-l-3 {
            margin-right: 3px;
            margin-left: auto;
        }

        .mg-md-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-md-l-4 {
            margin-right: 4px;
            margin-left: auto;
        }

        .mg-md-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-md-l-5 {
            margin-right: 5px;
            margin-left: auto;
        }

        .mg-md-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-md-l-6 {
            margin-right: 6px;
            margin-left: auto;
        }

        .mg-md-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-md-l-7 {
            margin-right: 7px;
            margin-left: auto;
        }

        .mg-md-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-md-l-8 {
            margin-right: 8px;
            margin-left: auto;
        }

        .mg-md-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-md-l-9 {
            margin-right: 9px;
            margin-left: auto;
        }

        .mg-md-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-md-l-10 {
            margin-right: 10px;
            margin-left: auto;
        }

        .mg-md-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-md-l-15 {
            margin-right: 15px;
            margin-left: auto;
        }

        .mg-md-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-md-l-20 {
            margin-right: 20px;
            margin-left: auto;
        }

        .mg-md-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-md-l-25 {
            margin-right: 25px;
            margin-left: auto;
        }

        .mg-md-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-md-l-30 {
            margin-right: 30px;
            margin-left: auto;
        }

        .mg-md-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-md-l-35 {
            margin-right: 35px;
            margin-left: auto;
        }

        .mg-md-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-md-l-40 {
            margin-right: 40px;
            margin-left: auto;
        }

        .mg-md-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-md-l-45 {
            margin-right: 45px;
            margin-left: auto;
        }

        .mg-md-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-md-l-50 {
            margin-right: 50px;
            margin-left: auto;
        }

        .mg-md-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-md-l-55 {
            margin-right: 55px;
            margin-left: auto;
        }

        .mg-md-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-md-l-60 {
            margin-right: 60px;
            margin-left: auto;
        }

        .mg-md-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-md-l-65 {
            margin-right: 65px;
            margin-left: auto;
        }

        .mg-md-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-md-l-70 {
            margin-right: 70px;
            margin-left: auto;
        }

        .mg-md-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-md-l-75 {
            margin-right: 75px;
            margin-left: auto;
        }

        .mg-md-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-md-l-80 {
            margin-right: 80px;
            margin-left: auto;
        }

        .mg-md-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-md-l-85 {
            margin-right: 85px;
            margin-left: auto;
        }

        .mg-md-r-90 {
            margin-left: 90px;
            margin-right: auto;
        }

        .mg-md-l-90 {
            margin-right: 90px;
            margin-left: auto;
        }

        .mg-md-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-md-l-95 {
            margin-right: 95px;
            margin-left: auto;
        }

        .mg-md-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-md-l-100 {
            margin-right: 100px;
            margin-left: auto;
        }

        .mg-md-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-md-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 992px) {
        .mg-lg-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-lg-l-0 {
            margin-right: 0px;
            margin-left: auto;
        }

        .mg-lg-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-lg-l-1 {
            margin-right: 1px;
            margin-left: auto;
        }

        .mg-lg-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-lg-l-2 {
            margin-right: 2px;
            margin-left: auto;
        }

        .mg-lg-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-lg-l-3 {
            margin-right: 3px;
            margin-left: auto;
        }

        .mg-lg-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-lg-l-4 {
            margin-right: 4px;
            margin-left: auto;
        }

        .mg-lg-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-lg-l-5 {
            margin-right: 5px;
            margin-left: auto;
        }

        .mg-lg-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-lg-l-6 {
            margin-right: 6px;
            margin-left: auto;
        }

        .mg-lg-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-lg-l-7 {
            margin-right: 7px;
            margin-left: auto;
        }

        .mg-lg-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-lg-l-8 {
            margin-right: 8px;
            margin-left: auto;
        }

        .mg-lg-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-lg-l-9 {
            margin-right: 9px;
            margin-left: auto;
        }

        .mg-lg-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-lg-l-10 {
            margin-right: 10px;
            margin-left: auto;
        }

        .mg-lg-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-lg-l-15 {
            margin-right: 15px;
            margin-left: auto;
        }

        .mg-lg-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-lg-l-20 {
            margin-right: 20px;
            margin-left: auto;
        }

        .mg-lg-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-lg-l-25 {
            margin-right: 25px;
            margin-left: auto;
        }

        .mg-lg-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-lg-l-30 {
            margin-right: 30px;
            margin-left: auto;
        }

        .mg-lg-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-lg-l-35 {
            margin-right: 35px;
            margin-left: auto;
        }

        .mg-lg-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-lg-l-40 {
            margin-right: 40px;
            margin-left: auto;
        }

        .mg-lg-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-lg-l-45 {
            margin-right: 45px;
            margin-left: auto;
        }

        .mg-lg-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-lg-l-50 {
            margin-right: 50px;
            margin-left: auto;
        }

        .mg-lg-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-lg-l-55 {
            margin-right: 55px;
            margin-left: auto;
        }

        .mg-lg-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-lg-l-60 {
            margin-right: 60px;
            margin-left: auto;
        }

        .mg-lg-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-lg-l-65 {
            margin-right: 65px;
            margin-left: auto;
        }

        .mg-lg-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-lg-l-70 {
            margin-right: 70px;
            margin-left: auto;
        }

        .mg-lg-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-lg-l-75 {
            margin-right: 75px;
            margin-left: auto;
        }

        .mg-lg-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-lg-l-80 {
            margin-right: 80px;
            margin-left: auto;
        }

        .mg-lg-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-lg-l-85 {
            margin-right: 85px;
            margin-left: auto;
        }

        .mg-lg-r-90 {
            margin-left: 90px;
            margin-right: auto;
        }

        .mg-lg-l-90 {
            margin-right: 90px;
            margin-left: auto;
        }

        .mg-lg-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-lg-l-95 {
            margin-right: 95px;
            margin-left: auto;
        }

        .mg-lg-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-lg-l-100 {
            margin-right: 100px;
            margin-left: auto;
        }

        .mg-lg-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-lg-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 1200px) {
        .mg-xl-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-xl-l-0 {
            margin-right: 0px;
            margin-left: auto;
        }

        .mg-xl-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-xl-l-1 {
            margin-right: 1px;
            margin-left: auto;
        }

        .mg-xl-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-xl-l-2 {
            margin-right: 2px;
            margin-left: auto;
        }

        .mg-xl-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-xl-l-3 {
            margin-right: 3px;
            margin-left: auto;
        }

        .mg-xl-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-xl-l-4 {
            margin-right: 4px;
            margin-left: auto;
        }

        .mg-xl-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-xl-l-5 {
            margin-right: 5px;
            margin-left: auto;
        }

        .mg-xl-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-xl-l-6 {
            margin-right: 6px;
            margin-left: auto;
        }

        .mg-xl-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-xl-l-7 {
            margin-right: 7px;
            margin-left: auto;
        }

        .mg-xl-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-xl-l-8 {
            margin-right: 8px;
            margin-left: auto;
        }

        .mg-xl-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-xl-l-9 {
            margin-right: 9px;
            margin-left: auto;
        }

        .mg-xl-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-xl-l-10 {
            margin-right: 10px;
            margin-left: auto;
        }

        .mg-xl-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-xl-l-15 {
            margin-right: 15px;
            margin-left: auto;
        }

        .mg-xl-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-xl-l-20 {
            margin-right: 20px;
            margin-left: auto;
        }

        .mg-xl-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-xl-l-25 {
            margin-right: 25px;
            margin-left: auto;
        }

        .mg-xl-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-xl-l-30 {
            margin-right: 30px;
            margin-left: auto;
        }

        .mg-xl-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-xl-l-35 {
            margin-right: 35px;
            margin-left: auto;
        }

        .mg-xl-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-xl-l-40 {
            margin-right: 40px;
            margin-left: auto;
        }

        .mg-xl-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-xl-l-45 {
            margin-right: 45px;
            margin-left: auto;
        }

        .mg-xl-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-xl-l-50 {
            margin-right: 50px;
            margin-left: auto;
        }

        .mg-xl-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-xl-l-55 {
            margin-right: 55px;
            margin-left: auto;
        }

        .mg-xl-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-xl-l-60 {
            margin-right: 60px;
            margin-left: auto;
        }

        .mg-xl-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-xl-l-65 {
            margin-right: 65px;
            margin-left: auto;
        }

        .mg-xl-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-xl-l-70 {
            margin-right: 70px;
            margin-left: auto;
        }

        .mg-xl-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-xl-l-75 {
            margin-right: 75px;
            margin-left: auto;
        }

        .mg-xl-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-xl-l-80 {
            margin-right: 80px;
            margin-left: auto;
        }

        .mg-xl-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-xl-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-xl-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-xl-l-90 {
            margin-right: 90px;
            margin-left: auto;
        }

        .mg-xl-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-xl-l-95 {
            margin-right: 95px;
            margin-left: auto;
        }

        .mg-xl-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-xl-l-100 {
            margin-right: 100px;
            margin-left: auto;
        }

        .mg-xl-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-xl-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .pe-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .ps-0 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .pe-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .ps-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .pe-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .pe-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .ps-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .pe-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .ps-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .pe-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .ps-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    @media (min-width: 576px) {
        .pe-sm-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-sm-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-sm-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-sm-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-sm-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-sm-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-sm-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-sm-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-sm-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-sm-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }
    }

    @media (min-width: 768px) {
        .pe-md-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-md-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-md-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-md-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-md-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-md-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-md-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-md-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-md-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-md-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }
    }

    @media (min-width: 992px) {
        .pe-lg-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-lg-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-lg-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-lg-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-lg-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-lg-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-lg-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-lg-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-lg-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-lg-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }
    }

    @media (min-width: 1200px) {
        .pe-xl-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-xl-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-xl-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-xl-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-xl-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-xl-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-xl-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-xl-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-xl-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-xl-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }
    }

    .pd-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .pd-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .pd-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .pd-l-1 {
        padding-right: 1px;
        padding-left: inherit;
    }

    .pd-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .pd-l-2 {
        padding-right: 2px;
        padding-left: inherit;
    }

    .pd-r-3 {
        padding-left: 3px;
        padding-right: inherit;
    }

    .pd-l-3 {
        padding-right: 3px;
        padding-left: inherit;
    }

    .pd-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .pd-l-4 {
        padding-right: 4px;
        padding-left: inherit;
    }

    .pd-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .pd-l-5 {
        padding-right: 5px;
        padding-left: inherit;
    }

    .pd-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .pd-l-6 {
        padding-right: 6px;
        padding-left: inherit;
    }

    .pd-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .pd-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .pd-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .pd-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .pd-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .pd-l-9 {
        padding-right: 9px;
        padding-left: inherit;
    }

    .pd-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .pd-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .pd-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .pd-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .pd-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .pd-l-20 {
        padding-right: 20px;
        padding-left: inherit;
    }

    .pd-r-25 {
        padding-left: 25px;
        padding-right: inherit;
    }

    .pd-l-25 {
        padding-right: 25px;
        padding-left: inherit;
    }

    .pd-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .pd-l-30 {
        padding-right: 30px;
        padding-left: inherit;
    }

    .pd-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .pd-l-35 {
        padding-right: 35px;
        padding-left: inherit;
    }

    .pd-r-40 {
        padding-left: 40px;
        padding-right: inherit;
    }

    .pd-l-40 {
        padding-right: 40px;
        padding-left: inherit;
    }

    .pd-r-45 {
        padding-left: 45px;
        padding-right: inherit;
    }

    .pd-l-45 {
        padding-right: 45px;
        padding-left: inherit;
    }

    .pd-r-50 {
        padding-left: 50px;
        padding-right: inherit;
    }

    .pd-l-50 {
        padding-right: 50px;
        padding-left: inherit;
    }

    .pd-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .pd-l-55 {
        padding-right: 55px;
        padding-left: inherit;
    }

    .pd-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .pd-l-60 {
        padding-right: 60px;
        padding-left: inherit;
    }

    .pd-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .pd-l-65 {
        padding-right: 65px;
        padding-left: inherit;
    }

    .pd-r-70 {
        padding-left: 70px;
        padding-right: inherit;
    }

    .pd-l-70 {
        padding-right: 70px;
        padding-left: inherit;
    }

    .pd-r-75 {
        padding-left: 75px;
        padding-right: inherit;
    }

    .pd-l-75 {
        padding-right: 75px;
        padding-left: inherit;
    }

    .pd-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .pd-l-80 {
        padding-right: 80px;
        padding-left: inherit;
    }

    .pd-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .pd-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .pd-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .pd-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .pd-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .pd-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .pd-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .pd-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .pd-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .pd-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .pd-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .pd-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .pd-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .pd-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .pd-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .pd-l-140 {
        padding-right: 140px;
        padding-left: inherit;
    }

    .pd-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .pd-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .pd-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .pd-l-160 {
        padding-right: 160px;
        padding-left: inherit;
    }

    .pd-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .pd-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .pd-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .pd-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .pd-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .pd-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .pd-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .pd-l-200 {
        padding-right: 200px;
        padding-left: inherit;
    }

    @media (min-width: 480px) {
        .pd-xs-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-xs-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-xs-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-xs-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-xs-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-xs-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-xs-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-xs-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-xs-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-xs-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-xs-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-xs-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-xs-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-xs-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-xs-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-xs-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-xs-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-xs-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-xs-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-xs-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-xs-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-xs-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-xs-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-xs-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-xs-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-xs-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-xs-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-xs-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-xs-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-xs-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-xs-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-xs-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-xs-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-xs-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-xs-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-xs-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-xs-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-xs-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-xs-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-xs-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-xs-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-xs-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-xs-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-xs-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-xs-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-xs-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-xs-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-xs-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-xs-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-xs-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-xs-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-xs-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-xs-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-xs-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-xs-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-xs-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-xs-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-xs-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-xs-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-xs-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-xs-r-120 {
            padding-left: 120px;
            padding-right: inherit;
        }

        .pd-xs-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-xs-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-xs-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-xs-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-xs-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-xs-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-xs-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-xs-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-xs-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-xs-r-170 {
            padding-left: 170px;
            padding-right: inherit;
        }

        .pd-xs-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-xs-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-xs-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-xs-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-xs-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-xs-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-xs-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .pd-sm-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-sm-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-sm-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-sm-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-sm-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-sm-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-sm-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-sm-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-sm-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-sm-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-sm-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-sm-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-sm-r-6 {
            padding-left: 6px;
            padding-right: inherit;
        }

        .pd-sm-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-sm-r-7 {
            padding-left: 7px;
            padding-right: inherit;
        }

        .pd-sm-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-sm-r-8 {
            padding-left: 8px;
            padding-right: inherit;
        }

        .pd-sm-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-sm-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-sm-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-sm-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-sm-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-sm-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-sm-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-sm-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-sm-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-sm-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-sm-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-sm-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-sm-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-sm-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-sm-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-sm-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-sm-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-sm-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-sm-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-sm-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-sm-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-sm-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-sm-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-sm-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-sm-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-sm-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-sm-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-sm-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-sm-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-sm-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-sm-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-sm-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-sm-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-sm-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-sm-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-sm-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-sm-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-sm-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-sm-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-sm-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-sm-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-sm-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-sm-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-sm-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-sm-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-sm-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-sm-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-sm-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-sm-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-sm-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-sm-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-sm-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-sm-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-sm-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-sm-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-sm-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-sm-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-sm-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-sm-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-sm-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-sm-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 768px) {
        .pd-md-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-md-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-md-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-md-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-md-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-md-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-md-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-md-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-md-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-md-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-md-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-md-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-md-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-md-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-md-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-md-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-md-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-md-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-md-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-md-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-md-r-10 {
            padding-left: 10px;
            padding-right: inherit;
        }

        .pd-md-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-md-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-md-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-md-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-md-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-md-r-25 {
            padding-left: 25px;
            padding-right: inherit;
        }

        .pd-md-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-md-r-30 {
            padding-left: 30px;
            padding-right: inherit;
        }

        .pd-md-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-md-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-md-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-md-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-md-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-md-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-md-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-md-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-md-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-md-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-md-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-md-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-md-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-md-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-md-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-md-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-md-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-md-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-md-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-md-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-md-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-md-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-md-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-md-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-md-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-md-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-md-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-md-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-md-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-md-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-md-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-md-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-md-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-md-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-md-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-md-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-md-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-md-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-md-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-md-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-md-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-md-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-md-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-md-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-md-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-md-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-md-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-md-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-md-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    @media (min-width: 992px) {
        .pd-lg-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-lg-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-lg-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-lg-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-lg-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-lg-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-lg-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-lg-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-lg-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-lg-l-4 {
            padding-right: 4px;
            padding-left: inherit;
        }

        .pd-lg-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-lg-l-5 {
            padding-right: 5px;
            padding-left: inherit;
        }

        .pd-lg-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-lg-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-lg-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-lg-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-lg-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-lg-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-lg-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-lg-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-lg-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-lg-l-10 {
            padding-right: 10px;
            padding-left: inherit;
        }

        .pd-lg-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-lg-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-lg-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-lg-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-lg-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-lg-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-lg-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-lg-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-lg-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-lg-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-lg-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-lg-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-lg-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-lg-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-lg-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-lg-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-lg-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-lg-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-lg-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-lg-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-lg-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-lg-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-lg-r-70 {
            padding-left: 70px;
            padding-right: inherit;
        }

        .pd-lg-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-lg-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-lg-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-lg-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-lg-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-lg-r-85 {
            padding-left: 85px;
            padding-right: inherit;
        }

        .pd-lg-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-lg-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-lg-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-lg-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-lg-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-lg-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-lg-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-lg-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-lg-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-lg-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-lg-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-lg-r-130 {
            padding-left: 130px;
            padding-right: inherit;
        }

        .pd-lg-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-lg-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-lg-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-lg-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-lg-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-lg-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-lg-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-lg-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-lg-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-lg-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-lg-l-180 {
            padding-right: 180px;
            padding-left: inherit;
        }

        .pd-lg-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-lg-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-lg-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-lg-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    @media (min-width: 1200px) {
        .pd-xl-r-0 {
            padding-left: 0px;
            padding-right: inherit;
        }

        .pd-xl-l-0 {
            padding-right: 0px;
            padding-left: inherit;
        }

        .pd-xl-r-1 {
            padding-left: 1px;
            padding-right: inherit;
        }

        .pd-xl-l-1 {
            padding-right: 1px;
            padding-left: inherit;
        }

        .pd-xl-r-2 {
            padding-left: 2px;
            padding-right: inherit;
        }

        .pd-xl-l-2 {
            padding-right: 2px;
            padding-left: inherit;
        }

        .pd-xl-r-3 {
            padding-left: 3px;
            padding-right: inherit;
        }

        .pd-xl-l-3 {
            padding-right: 3px;
            padding-left: inherit;
        }

        .pd-xl-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-xl-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-xl-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-xl-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-xl-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-xl-l-6 {
            padding-right: 6px;
            padding-left: inherit;
        }

        .pd-xl-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-xl-l-7 {
            padding-right: 7px;
            padding-left: inherit;
        }

        .pd-xl-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-xl-l-8 {
            padding-right: 8px;
            padding-left: inherit;
        }

        .pd-xl-r-9 {
            padding-left: 9px;
            padding-right: inherit;
        }

        .pd-xl-l-9 {
            padding-right: 9px;
            padding-left: inherit;
        }

        .pd-xl-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-xl-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-xl-r-15 {
            padding-left: 15px;
            padding-right: inherit;
        }

        .pd-xl-l-15 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .pd-xl-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-xl-l-20 {
            padding-right: 20px;
            padding-left: inherit;
        }

        .pd-xl-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-xl-l-25 {
            padding-right: 25px;
            padding-left: inherit;
        }

        .pd-xl-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-xl-l-30 {
            padding-right: 30px;
            padding-left: inherit;
        }

        .pd-xl-r-35 {
            padding-left: 35px;
            padding-right: inherit;
        }

        .pd-xl-l-35 {
            padding-right: 35px;
            padding-left: inherit;
        }

        .pd-xl-r-40 {
            padding-left: 40px;
            padding-right: inherit;
        }

        .pd-xl-l-40 {
            padding-right: 40px;
            padding-left: inherit;
        }

        .pd-xl-r-45 {
            padding-left: 45px;
            padding-right: inherit;
        }

        .pd-xl-l-45 {
            padding-right: 45px;
            padding-left: inherit;
        }

        .pd-xl-r-50 {
            padding-left: 50px;
            padding-right: inherit;
        }

        .pd-xl-l-50 {
            padding-right: 50px;
            padding-left: inherit;
        }

        .pd-xl-r-55 {
            padding-left: 55px;
            padding-right: inherit;
        }

        .pd-xl-l-55 {
            padding-right: 55px;
            padding-left: inherit;
        }

        .pd-xl-r-60 {
            padding-left: 60px;
            padding-right: inherit;
        }

        .pd-xl-l-60 {
            padding-right: 60px;
            padding-left: inherit;
        }

        .pd-xl-r-65 {
            padding-left: 65px;
            padding-right: inherit;
        }

        .pd-xl-l-65 {
            padding-right: 65px;
            padding-left: inherit;
        }

        .pd-xl-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-xl-l-70 {
            padding-right: 70px;
            padding-left: inherit;
        }

        .pd-xl-r-75 {
            padding-left: 75px;
            padding-right: inherit;
        }

        .pd-xl-l-75 {
            padding-right: 75px;
            padding-left: inherit;
        }

        .pd-xl-r-80 {
            padding-left: 80px;
            padding-right: inherit;
        }

        .pd-xl-l-80 {
            padding-right: 80px;
            padding-left: inherit;
        }

        .pd-xl-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-xl-l-85 {
            padding-right: 85px;
            padding-left: inherit;
        }

        .pd-xl-r-90 {
            padding-left: 90px;
            padding-right: inherit;
        }

        .pd-xl-l-90 {
            padding-right: 90px;
            padding-left: inherit;
        }

        .pd-xl-r-95 {
            padding-left: 95px;
            padding-right: inherit;
        }

        .pd-xl-l-95 {
            padding-right: 95px;
            padding-left: inherit;
        }

        .pd-xl-r-100 {
            padding-left: 100px;
            padding-right: inherit;
        }

        .pd-xl-l-100 {
            padding-right: 100px;
            padding-left: inherit;
        }

        .pd-xl-r-110 {
            padding-left: 110px;
            padding-right: inherit;
        }

        .pd-xl-l-110 {
            padding-right: 110px;
            padding-left: inherit;
        }

        .pd-xl-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-xl-l-120 {
            padding-right: 120px;
            padding-left: inherit;
        }

        .pd-xl-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-xl-l-130 {
            padding-right: 130px;
            padding-left: inherit;
        }

        .pd-xl-r-140 {
            padding-left: 140px;
            padding-right: inherit;
        }

        .pd-xl-l-140 {
            padding-right: 140px;
            padding-left: inherit;
        }

        .pd-xl-r-150 {
            padding-left: 150px;
            padding-right: inherit;
        }

        .pd-xl-l-150 {
            padding-right: 150px;
            padding-left: inherit;
        }

        .pd-xl-r-160 {
            padding-left: 160px;
            padding-right: inherit;
        }

        .pd-xl-l-160 {
            padding-right: 160px;
            padding-left: inherit;
        }

        .pd-xl-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-xl-l-170 {
            padding-right: 170px;
            padding-left: inherit;
        }

        .pd-xl-r-180 {
            padding-left: 180px;
            padding-right: inherit;
        }

        .pd-xl-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-xl-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-xl-l-190 {
            padding-right: 190px;
            padding-left: inherit;
        }

        .pd-xl-r-200 {
            padding-left: 200px;
            padding-right: inherit;
        }

        .pd-xl-l-200 {
            padding-right: 200px;
            padding-left: inherit;
        }
    }

    .pd-r-12 {
        padding-left: 12px;
        padding-right: inherit;
    }

    .pd-l-12 {
        padding-right: 12px;
        padding-left: inherit;
    }

    .pd-r-13 {
        padding-right: inherit;
        padding-left: 13px;
    }

    .pd-l-13 {
        padding-left: inherit;
        padding-right: 13px;
    }

    .r-0 {
        right: auto;
        left: 0px;
    }

    .l-0 {
        left: auto;
        right: 0px;
    }

    .r--0 {
        right: auto;
        left: 0px;
    }

    .l--0 {
        left: auto;
        right: 0px;
    }

    .r-5 {
        right: auto;
        left: 5px;
    }

    .l-5 {
        right: 5px;
        left: auto;
    }

    .r--5 {
        right: auto;
        left: -5px;
    }

    .l--5 {
        left: auto;
        right: -5px;
    }

    .r-10 {
        right: auto;
        left: 10px;
    }

    .l-10 {
        left: auto;
        right: 10px;
    }

    .r--10 {
        right: auto;
        left: -10px;
    }

    .l--10 {
        right: -10px;
        left: auto;
    }

    .r-15 {
        left: 15px;
        right: auto;
    }

    .l-15 {
        right: 15px;
        left: auto;
    }

    .r--15 {
        right: auto;
        left: -15px;
    }

    .l--15 {
        left: auto;
        right: -15px;
    }

    .r-20 {
        right: auto;
        left: 20px;
    }

    .l-20 {
        left: auto;
        right: 20px;
    }

    .r--20 {
        right: auto;
        left: -20px;
    }

    .l--20 {
        left: auto;
        right: -20px;
    }

    .r-25 {
        right: auto;
        left: 25px;
    }

    .l-25 {
        right: 25px;
        left: auto;
    }

    .r--25 {
        right: auto;
        left: -25px;
    }

    .l--25 {
        left: auto;
        right: -25px;
    }

    .r-30 {
        right: auto;
        left: 30px;
    }

    .l-30 {
        left: auto;
        right: 30px;
    }

    .r--30 {
        right: auto;
        left: -30px;
    }

    .l--30 {
        left: auto;
        right: -30px;
    }

    .r-35 {
        right: auto;
        left: 35px;
    }

    .l-35 {
        left: auto;
        right: 35px;
    }

    .r--35 {
        right: auto;
        left: -35px;
    }

    .l--35 {
        right: -35px;
        left: auto;
    }

    .r-40 {
        right: auto;
        left: 40px;
    }

    .l-40 {
        left: auto;
        right: 40px;
    }

    .r--40 {
        right: auto;
        left: -40px;
    }

    .l--40 {
        left: auto;
        right: -40px;
    }

    .r-45 {
        left: 45px;
        right: auto;
    }

    .l-45 {
        right: 45px;
        left: auto;
    }

    .r--45 {
        right: auto;
        left: -45px;
    }

    .l--45 {
        right: -45px;
        left: auto;
    }

    .r-50 {
        right: auto;
        left: 50px;
    }

    .l-50 {
        left: auto;
        right: 50px;
    }

    .r--50 {
        right: auto;
        left: -50px;
    }

    .l--50 {
        left: auto;
        right: -50px;
    }

    .r-55 {
        right: auto;
        left: 55px;
    }

    .l-55 {
        left: auto;
        right: 55px;
    }

    .r--55 {
        right: auto;
        left: -55px;
    }

    .l--55 {
        right: -55px;
        left: auto;
    }

    .r-60 {
        right: auto;
        left: 60px;
    }

    .l-60 {
        right: 60px;
        left: auto;
    }

    .r--60 {
        left: -60px;
        right: auto;
    }

    .l--60 {
        left: auto;
        right: -60px;
    }

    .r-65 {
        right: auto;
        left: 65px;
    }

    .l-65 {
        right: 65px;
        left: auto;
    }

    .r--65 {
        right: auto;
        left: -65px;
    }

    .l--65 {
        left: auto;
        right: -65px;
    }

    .r-70 {
        right: auto;
        left: 70px;
    }

    .l-70 {
        right: 70px;
        left: auto;
    }

    .r--70 {
        right: auto;
        left: -70px;
    }

    .l--70 {
        left: auto;
        right: -70px;
    }

    .r-75 {
        right: auto;
        left: 75px;
    }

    .l-75 {
        left: auto;
        right: 75px;
    }

    .r--75 {
        right: auto;
        left: -75px;
    }

    .l--75 {
        left: auto;
        right: -75px;
    }

    .r-80 {
        right: auto;
        left: 80px;
    }

    .l-80 {
        right: 80px;
        left: auto;
    }

    .r--80 {
        right: auto;
        left: -80px;
    }

    .l--80 {
        left: auto;
        right: -80px;
    }

    .r-85 {
        right: auto;
        left: 85px;
    }

    .l-85 {
        left: auto;
        right: 85px;
    }

    .r--85 {
        right: auto;
        left: -85px;
    }

    .l--85 {
        left: auto;
        right: -85px;
    }

    .r-90 {
        right: auto;
        left: 90px;
    }

    .l-90 {
        right: 90px;
        left: auto;
    }

    .r--90 {
        right: auto;
        left: -90px;
    }

    .l--90 {
        left: auto;
        right: -90px;
    }

    .r-95 {
        right: auto;
        left: 95px;
    }

    .l-95 {
        left: auto;
        right: 95px;
    }

    .r--95 {
        right: auto;
        left: -95px;
    }

    .l--95 {
        right: -95px;
        left: auto;
    }

    .r-100 {
        right: auto;
        left: 100px;
    }

    .l-100 {
        left: auto;
        right: 100px;
    }

    .r--100 {
        right: auto;
        left: -100px;
    }

    .l--100 {
        left: auto;
        right: -100px;
    }

    .r--5 {
        right: auto;
        left: -5px;
    }

    @media (min-width: 480px) {
        .r-xs-auto {
            right: 0;
            left: auto;
        }

        .l-xs-auto {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 576px) {
        .r-sm-auto {
            right: 0;
            left: auto;
        }

        .l-sm-auto {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 768px) {
        .r-md-auto {
            right: 0;
            left: auto;
        }

        .l-md-auto {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 992px) {
        .r-lg-auto {
            right: 0;
            left: auto;
        }

        .l-lg-auto {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 1200px) {
        .r-xl-auto {
            right: 0;
            left: auto;
        }

        .l-xl-auto {
            left: 0;
            right: auto;
        }
    }

    .tx-end {
        text-align: left;
    }

    .tx-start {
        text-align: right;
    }

    @media (min-width: 480px) {
        .tx-xs-end {
            text-align: left;
        }

        .tx-xs-start {
            text-align: right;
        }
    }

    @media (min-width: 576px) {
        .tx-sm-right {
            text-align: left;
        }

        .tx-sm-left {
            text-align: right;
        }
    }

    @media (min-width: 768px) {
        .tx-md-end {
            text-align: left;
        }

        .tx-md-start {
            text-align: right;
        }
    }

    @media (min-width: 992px) {
        .tx-lg-end {
            text-align: left;
        }

        .tx-lg-start {
            text-align: right;
        }
    }

    @media (min-width: 1200px) {
        .tx-xl-end {
            text-align: left;
        }

        .tx-xl-start {
            text-align: right;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-start {
            text-align: right !important;
        }

        .text-sm-end {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-start {
            text-align: right !important;
        }

        .text-md-end {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }

        .text-lg-end {
            text-align: left !important;
        }
    }

    @media (min-width: 1200px) {
        .text-xl-start {
            text-align: right !important;
        }

        .text-xl-end {
            text-align: left !important;
        }
    }
}

// ----- HOVER SUBMENU -------//
.hover-submenu,
.hover-submenu1 {
    @media (min-width: 992px) {
        &.rtl.sidenav-toggled-open .slide-menu {
            border-radius: 7px 0px 0px 7px !important;
        }
    }
}
// -----! HOVER SUBMENU -------//

.rtl.dark-theme .demo_changer .form_holder {
    border-right-color: $dark-border;
}

.rtl.transparent-theme .demo_changer .form_holder {
    border-right-color: $transparent-border;
}
body.rtl {
    &.leftbgimage1,
    &.leftbgimage2,
    &.leftbgimage3,
    &.leftbgimage4,
    &.leftbgimage5 {
        .app-sidebar {
            border-right: 0;
            border-left: 1px solid $border;
        }
        &.dark-theme {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $dark-border;
            }
        }
        &.transparent-theme {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $transparent-border;
            }
        }
        &.light-menu {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $dark-border;
            }
        }
        &.color-menu {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $transparent-border;
            }
        }
        &.dark-menu {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $dark-border;
            }
        }
        &.gradient-menu {
            .app-sidebar {
                border-right: 0;
                border-left: 1px solid $transparent-border;
            }
        }
    }

    @media (min-width: 1025px) {
        &.layout-boxed {
            .app-sidebar {
                right: auto !important;
            }
            .main-content-left-chat .main-nav-line-chat .nav-link + .nav-link {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
    @media (min-width: 992px) {
        &.horizontal {
            .horizontal-main .sub-slide2 .sub-slide-menu1 {
                left: 0;
                right: 180px;
            }

            .horizontal-main {
                .slide {
                    .slide-menu,
                    .sub-slide-menu {
                        .slide-item:before {
                            left: auto;
                            right: 20px;
                            margin-right: auto;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .slide.is-expanded .sub-slide-item2:before {
                left: auto !important;
                right: 20px !important;
            }

            .sub-slide-menu .sub-slide-item2 {
                padding: 8px 35px 8px 20px !important;
            }

            .sub-slide .sub-angle {
                right: auto;
                left: -20px;
                margin-right: auto !important;
                margin-left: 0 !important;
            }

            .sub-slide .sub-slide-menu {
                left: auto;
                right: 180px;
            }

            .main-sidemenu .slide-right {
                right: auto;
                left: 0;
            }

            .main-sidemenu .slide-left {
                left: auto;
                right: 0;
            }
        }
    }

    &.horizontal {
        @media (max-width: 991.98px) {
            .app-sidebar.horizontal-main {
                left: auto;
                right: -300px;
            }
        }
    }
    @media (min-width: 992px) {
        &.horizontal-hover.horizontal .app-sidebar .slide-menu .sub-slide-menu1 a:before {
            right: 20px !important;
            left: auto !important;
        }
        &.horizontal-hover.horizontal .sub-slide-menu .sub-side-menu__item:before {
            right: 20px !important;
            left: auto !important;
        }
    }
}
.rtl {
    .app-sidebar .mCSB_draggerContainer {
        right: auto !important;
        left: -11px !important;
    }

    @media (min-width: 992px) {
        &.app.sidenav-toggled {
            .app-content {
                margin-left: 0;
                margin-right: 80px;
            }

            .app-sidebar {
                left: auto;
                right: 0;
            }
        }
    }

    @media (max-width: 991px) {
        &.app {
            .app-sidebar {
                left: auto;
                right: -240px;
            }
            &.sidenav-toggled {
                .app-content {
                    margin-left: 0;
                    margin-right: 0;
                }

                .app-sidebar {
                    left: auto;
                    right: 0;
                }
            }

            &.sidebar-gone.sidenav-toggled .app-sidebar {
                right: 0;
                left: auto;
            }
        }
        &.app.sidenav-toggled .side-menu .side-menu__icon {
            margin-right: 0 !important;
            margin-left: 13px;
        }
    }

    .app-header {
        padding-right: inherit;
        padding-left: 15px;
    }

    @media (min-width: 992px) {
        .app-header {
            padding-right: inherit;
            padding-left: 30px;
        }
    }

    .app-sidebar__toggle {
        margin-right: auto;
        margin-left: 10px;
    }

    .app-search {
        margin-right: auto;
        margin-left: 15px;
    }

    .app-search__input {
        padding-right: inherit;
        padding-left: 30px;
    }

    .app-search__button {
        right: auto;
        left: 0;
    }

    .app-notification__icon {
        padding-right: 0;
        padding-left: 10px;
    }

    .app-sidebar {
        left: auto;
        right: 0;
        border-left: 1px solid $border;
        border-right: 0;
    }
    .app-sidebar__user-avatar {
        margin-right: auto;
        margin-left: 15px;
    }

    .side-menu {
        .slide .side-menu__item {
            padding: 10px 22px 10px 20px;
        }
    }
    @media (min-width: 992px) {
        &.app.sidebar-mini.sidenav-toggled .profile-status {
            right: auto;
            left: 22px;
        }
    }

    .sub-slide .sub-angle {
        float: left;
        margin-left: 0;
        margin-right: auto;
    }
    .slide.is-expanded {
        .sub-side-menu__item:before {
            left: auto;
            right: 0px;
        }
    }

    .side-menu .side-menu__icon {
        margin-right: auto;
        margin-left: 14px;
    }

    .app-sidebar .slide .side-menu__item.active::before {
        right: 0;
        left: auto;
    }

    .app-sidebar .side-item.side-item-category {
        padding: 0 25px 0 20px;
    }

    .slide-menu {
        padding-right: 29px;
        padding-left: 0;
    }

    .slide-item {
        .icon {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    @media (min-width: 992px) {
        &.app.sidenav-toggled {
            .side-menu__item {
                border-right: 0;
                border-left: 1px solid $border;
            }

            .side-menu__label {
                right: 0;
                left: auto;
            }
        }

        &.app.sidenav-toggled .nav-badge {
            right: auto;
            left: 28px;
        }
    }

    .dropdown-menu {
        &.dropdown-menu-right {
            left: 0;
            right: auto;
        }
    }

    .app-breadcrumb {
        text-align: left;
    }
    &.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
        .nav-badge {
            left: auto;
            right: 57px;
        }
    }

    .side-header {
        .header-brand-img.desktop-logo {
            margin-right: auto;
            margin-left: 0;
        }
    }

    &.sidenav-toggled {
        .app-sidebar .side-header {
            .header-brand-img.toggle-logo {
                margin-right: auto;
                margin-left: 0;
            }
        }
    }

    .side-menu h3 {
        &:after {
            left: auto;
            right: 21px;
        }
    }

    .sub-slide-item {
        padding: 8px 25px 8px 0 !important;
    }

    .sub-slide-menu {
        padding-left: inherit;
        padding-right: 0;
    }

    .sub-side-menu__item {
        padding-left: 20px !important;
        padding-right: 40px !important;
    }

    .sub-slide-item {
        padding-left: 0 !important;
        padding-right: 42px !important;
    }

    .app-sidebar .slide-menu .sub-slide-menu a:before {
        left: auto;
        right: 25px;
    }

    .app-sidebar .slide-menu a:before {
        left: auto;
        right: 0px;
        transform: rotate(180deg);
    }

    .sub-slide-menu1 .sub-slide-item2:before {
        left: auto !important;
        right: 35px !important;
    }
    @media (min-width: 992px) {
        &.app.sidebar-mini {
            &.sidenav-toggled-open {
                .app-sidebar {
                    left: auto;
                    right: 0px;
                }

                .side-menu {
                    .slide .side-menu__item {
                        text-align: right;
                    }

                    .side-menu__icon {
                        margin-right: auto;
                        margin-left: 12px;
                    }
                }

                .ps > .ps__rail-y > .ps__thumb-y {
                    left: auto;
                    right: 1px;
                }
            }

            &.sidenav-toggled.sidenav-toggled-open {
                .profile-status {
                    right: auto;
                    left: 97px;
                }

                .slide-menu {
                    left: auto;
                    right: 0;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled-open .app-sidebar .slide .side-menu__item.active::before {
            left: auto;
            right: 0;
        }
    }

    @media only screen and (max-width: 991px) {
        &.app .desktop-logo {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        &.main-body.app.sidebar-mini {
            &.sidenav-toggled {
                &.sidenav-toggled-open .main-header form[role="search"].active input {
                    padding-left: inherit;
                    padding-right: 250px;
                }

                .main-header form[role="search"].active input {
                    padding-left: inherit;
                    padding-right: 90px;
                }
            }

            &.sidebar-gone .main-header form[role="search"].active input {
                padding-left: inherit;
                padding-right: 20px;
            }

            .main-header form[role="search"].active input {
                padding-left: inherit;
                padding-right: 250px;
            }
        }
    }

    .sub-slide-menu-sub {
        padding-right: 0;
        padding-left: inherit;
    }
    .sub-slide-menu-sub .sub-slide-item {
        padding-left: inherit;
        padding-right: 55px !important;
    }

    .app-sidebar .slide-menu .sub-slide-menu .sub-slide-menu-sub a:before {
        left: auto;
        right: 35px;
    }

    .sub-slide-menu1 .sub-slide-item2 {
        padding: 6px 48px 6px 0px !important;
    }

    .sub-slide-menu1 {
        padding-right: 0;
        padding-left: inherit;
    }
    &.closed-menu {
        @media (min-width: 992px) {
            &.app.sidenav-toggled {
                .app-content {
                    margin-left: 0 !important;
                    margin-right: 0px !important;
                }

                .main-header {
                    padding-left: inherit !important;
                    padding-right: 0px !important;
                }
            }
        }
    }
    &.icontext-menu {
        @media (min-width: 992px) {
            &.app.sidenav-toggled {
                .main-header {
                    padding-left: inherit !important;
                    padding-right: 110px !important;
                }

                .app-content {
                    margin-left: 0;
                    margin-right: 110px;
                }
            }
        }
    }
    &.hover-submenu {
        @media (min-width: 992px) {
            &.sidenav-toggled-open .slide-menu {
                border-radius: 7px 0 0 7px !important;
            }

            .slide-item {
                padding: 8px 35px 8px 18px;
            }

            .slide-menu a:before {
                left: auto;
                right: 15px;
            }

            .slide-menu {
                border-radius: 7px 0 0 7px;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
                left: auto;
                right: 108px;
            }

            &.app.sidenav-toggled-open {
                .slide-menu {
                    padding-left: inherit !important;
                    padding-right: 0 !important;
                    left: auto;
                    right: 110px;
                }

                .side-menu__label {
                    right: 0;
                    left: auto;
                }

                .side-menu .slide .side-menu__item.active {
                    border-right: 1px solid $border !important;
                    border-left: 0 !important;
                }
            }

            &.app.sidenav-toggled {
                .main-header {
                    padding-left: inherit;
                    padding-right: 110px;
                }

                .app-content {
                    margin-left: 0;
                    margin-right: 110px;
                }

                .app-sidebar {
                    left: auto;
                    right: 0;
                }
            }
        }

        .sub-slide-menu .sub-side-menu__item:before {
            left: auto !important;
            right: 25px !important;
        }

        @media (min-width: 992px) {
            &.app.sidebar-mini {
                &.sidenav-toggled.sidenav-toggled-open .profile-status {
                    right: auto;
                    left: 22px;
                }
            }
        }
    }

    &.hover-submenu1 {
        @media (min-width: 992px) {
            &.sidenav-toggled-open .slide-menu {
                border-radius: 7px 0 0 7px !important;
            }

            .slide-item {
                padding: 8px 35px 8px 18px;
            }

            .slide-menu a:before {
                left: auto;
                right: 15px;
            }

            .slide-menu {
                border-radius: 7px 0 0 7px;
            }

            &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
                left: auto;
                right: 108px;
            }

            &.app.sidenav-toggled-open {
                .slide-menu {
                    padding-left: inherit !important;
                    padding-right: 0 !important;
                    left: auto;
                    right: 110px;
                }

                .side-menu__label {
                    left: auto;
                    right: 0;
                }
                .side-menu .slide .side-menu__item.active {
                    border-right: 1px solid $border !important;
                    border-left: 0 !important;
                }
            }

            &.app.sidenav-toggled {
                .main-header {
                    padding-left: inherit;
                    padding-right: 110px;
                }

                .app-content {
                    margin-right: 110px;
                    margin-left: 0;
                }

                .app-sidebar {
                    left: auto;
                    right: 0;
                }
            }
        }

        .sub-slide-menu .sub-side-menu__item:before {
            left: auto !important;
            right: 25px !important;
        }

        @media (min-width: 992px) {
            &.app.sidebar-mini {
                &.sidenav-toggled.sidenav-toggled-open .profile-status {
                    right: auto;
                    left: 22px;
                }
            }
        }
    }
    @media (min-width: 992px) {
        &.app.sidenav-toggled {
            .main-header {
                padding-right: 80px;
                padding-left: 0;
            }
        }
    }
    .sidebar-right {
        left: 0;
        right: auto;
    }
    .sidebar.sidebar-right {
        transform: translate(-100%, 0);
    }
    #slide-right svg,
    #slide-left svg {
        transform: rotate(180deg);
    }
    .main {
        float: right;
    }
    .dTree a {
        padding-right: 8px;
    }
    .join {
        float: right;
        transform: rotate(180deg);
    }
    .dTree .page-tree {
        float: right;
    }
    .folder-group.last .minus {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .folder-open {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .last .plus {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .folder {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .plus {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .minus {
        padding-right: 20px;
        padding-left: inherit;
        float: right;
    }
    .folder-group {
        background: none;
    }
    .dropdown-item {
        i {
            float: right;
        }
    }
    .lg-toolbar .lg-icon {
        float: left;
    }
    .image-pic {
        padding: 20px 20px 20px 0;
    }
    #icon-alerts,
    #icon-dismissalerts {
        .alert {
            .alert-inner--icon {
                float: right;
                margin-left: 10px;
            }
        }
    }
    .main-img-user1 {
        &.avatar-lg,
        &.avatar-md,
        &.avatar-sm {
            .number-badge {
                left: -7px;
                right: auto;
            }
        }
    }
    .main-img-user3 {
        &.avatar-lg,
        &.avatar-md,
        &.avatar-sm {
            .icon-badgeavatar {
                left: -4px;
                right: auto;
            }
        }
    }
    .btn-list.btn-animation {
        .btn {
            margin-left: 0 !important;
        }
    }
    .button-icon i {
        float: right;
        margin-top: 3px;
    }
    .badge-top-pill {
        margin-right: 0;
        margin-left: 3rem;
        .start-100 {
            right: 72% !important;
            left: auto !important;
        }
    }
    .list-group-horizontal > .list-group-item + .list-group-item {
        border-right-width: 0;
        border-left-width: 1px;
    }
    @media (min-width: 576px) {
        .list-group-horizontal-sm > .list-group-item + .list-group-item {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }
    @media (min-width: 768px) {
        .list-group-horizontal-md > .list-group-item + .list-group-item {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }
    @media (min-width: 992px) {
        .list-group-horizontal-lg > .list-group-item + .list-group-item {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }
    @media (min-width: 1200px) {
        .list-group-horizontal-xl > .list-group-item + .list-group-item {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }
    .list-group-item {
        img {
            margin-right: 0 !important;
        }
    }
    .toast-header {
        .close {
            margin-left: 0 !important;
        }
        i {
            margin-right: 0 !important;
        }
    }
    .bootstrap-tagsinput {
        .badge {
            margin: 0 0 5px 5px;

            [data-role="remove"] {
                margin-right: 20px;
                margin-left: auto;
            }
        }
    }
    #accordion11 {
        .accordion-toggle {
            i {
                float: right;
                transform: rotate(180deg);
            }
        }
    }
    .accor {
        a {
            i {
                float: right;
            }
        }
    }

    .carousel {
        overflow: hidden;
    }

    .carousel-control-prev {
        left: 0;
        right: inherit;
    }

    .carousel-control-next {
        right: 0;
        left: inherit;
    }

    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }
    .MS-content {
        direction: ltr;
    }
    .timeline-footer {
        i {
            margin-right: 0 !important;
        }
    }
    .grid-margin {
        .dot-label {
            margin-right: 0 !important;
        }
    }
    .item7-card-desc {
        .phone {
            span {
                float: right;
            }
        }
    }
    .smartphoto {
        left: auto;
        right: 0;
    }

    .smartphoto-header {
        left: auto;
        right: 0;
    }

    .smartphoto-content {
        left: auto;
        right: 0;
    }

    .smartphoto-dismiss {
        right: auto;
        left: 10px;
    }

    .smartphoto-arrows {
        left: auto;
        right: 0;
    }

    .smartphoto-arrow-right {
        right: auto;
        left: 0;
    }

    .smartphoto-arrow-left {
        left: auto;
        right: 0;
    }

    .smartphoto-nav {
        left: auto;
        right: 0;
    }

    .smartphoto-list {
        left: auto;
        right: 0;
    }

    .smartphoto-caption {
        left: auto;
        right: 0;
    }

    .smartphoto-loader {
        left: auto;
        right: 0;
        margin-left: 0;
        margin-right: -25px;
    }

    .smartphoto-img-clone {
        left: auto;
        right: 0;
    }

    .smartphoto-dismiss {
        right: auto !important;
        left: 22px !important;
    }

    .smartphoto-arrow-left {
        left: auto !important;
        right: 15px !important;
    }

    .smartphoto-arrow-right {
        right: auto !important;
        left: 15px !important;
    }

    .smartphoto-nav li {
        margin-left: auto;
        margin-right: 5px;
    }
    .smartphoto-arrows a {
        transform: rotate(180deg);
    }
    .ql-clipboard {
        left: auto;
        right: -100000px;
    }

    .ql-editor {
        text-align: right;

        ol,
        ul {
            padding-left: inherit;
            padding-right: 1.5em;
        }

        li {
            &:not(.ql-direction-rtl)::before {
                margin-left: 0.3em;
                margin-right: -1.5em;
                text-align: left;
            }

            &.ql-direction-rtl::before {
                margin-left: -1.5em;
                margin-right: 0.3em;
            }
        }

        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 1.5em;
        }

        ol li.ql-direction-rtl,
        ul li.ql-direction-rtl {
            padding-right: inherit;
            padding-left: 1.5em;
        }

        .ql-indent-1:not(.ql-direction-rtl) {
            padding-right: 3em;
            padding-left: inherit;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 4.5em;
        }

        .ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 3em;
        }

        li.ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 4.5em;
        }

        .ql-indent-2:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 6em;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 7.5em;
        }

        .ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 6em;
        }

        li.ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 7.5em;
        }

        .ql-indent-3:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 9em;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 10.5em;
        }

        .ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 9em;
        }

        li.ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 10.5em;
        }

        .ql-indent-4:not(.ql-direction-rtl) {
            padding-right: 12em;
            padding-left: inherit;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 13.5em;
        }

        .ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 12em;
        }

        li.ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 13.5em;
        }

        .ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 15em;
            padding-left: inherit;
        }

        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-right: 16.5em;
            padding-left: inherit;
        }

        .ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 15em;
        }

        li.ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 16.5em;
        }

        .ql-indent-6:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 18em;
        }

        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 19.5em;
        }

        .ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 18em;
        }

        li.ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 19.5em;
        }

        .ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 21em;
            padding-left: inherit;
        }

        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-right: 22.5em;
            padding-left: inherit;
        }

        .ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 21em;
        }

        li.ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 22.5em;
        }

        .ql-indent-8:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 24em;
        }

        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 25.5em;
        }

        .ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 24em;
        }

        li.ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 25.5em;
        }

        .ql-indent-9:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 27em;
        }

        li.ql-indent-9:not(.ql-direction-rtl) {
            padding-left: inherit;
            padding-right: 28.5em;
        }

        .ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 27em;
        }

        li.ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: inherit;
            padding-left: 28.5em;
        }

        .ql-direction-rtl {
            direction: ltr;
        }

        .ql-align-right {
            text-align: left;
        }
    }

    .ql-bubble {
        &.ql-toolbar button,
        .ql-toolbar button {
            float: right;
        }

        &.ql-toolbar button svg,
        .ql-toolbar button svg {
            float: right;
        }

        .ql-editor {
            blockquote {
                padding-left: inherit;
                padding-right: 16px;
                border-left: 0;
                border-right: 4px solid $border;
            }
        }

        .ql-picker {
            float: right;
        }

        .ql-picker-label {
            padding-left: 2px;
            padding-right: 8px;
        }

        .ql-color-picker .ql-picker-label svg {
            right: auto;
            left: 4px;
        }

        .ql-icon-picker {
            .ql-picker-label svg {
                right: auto;
                left: 4px;
            }
        }

        .ql-color-picker {
            .ql-picker-item {
                float: right;
            }
        }

        .ql-picker {
            &:not(.ql-color-picker):not(.ql-icon-picker) svg {
                right: auto;
                left: 0;
            }
        }

        .ql-toolbar .ql-formats {
            margin: 8px 0 8px 12px;

            &:first-child {
                margin-right: 12px;
                margin-left: auto;
            }
        }

        .ql-tooltip-arrow {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -6px;
        }

        .ql-tooltip-editor {
            a {
                right: auto;
                left: 20px;
            }
        }
    }

    .ql-container.ql-bubble:not(.ql-disabled) a {
        &::before,
        &::after {
            right: 0;
            left: auto;
            margin-left: auto;
            margin-right: 50%;
        }
    }
    .ad-post-details {
        .custom-control {
            margin-right: 0 !important;
        }
    }
    .datepicker {
        direction: rtl;
        right: auto;
        left: 0;
    }
    .datetimepicker {
        direction: rtl;
        right: auto;
        left: auto;
    }
    .ui-datepicker .ui-datepicker-next {
        left: 2px;
        right: auto;
        transform: rotate(180deg);
    }
    .ui-datepicker .ui-datepicker-prev {
        right: 2px;
        left: auto;
        transform: rotate(180deg);
    }
    .datetimepicker table th.next,
    .datetimepicker table th.prev {
        transform: rotate(180deg);
    }
    .SumoSelect {
        > .optWrapper {
            > .options li {
                ul li.opt {
                    padding-left: inherit;
                    padding-right: 22px;
                }
            }
            &.multiple > .options li ul li.opt {
                padding-left: inherit;
                padding-right: 50px;
            }
        }

        .select-all {
            > span {
                margin-left: auto;
                margin-right: -35px;
            }
        }

        > .optWrapper {
            &.multiple > .options li.opt {
                padding-left: inherit;
                padding-right: 35px;

                span {
                    margin-left: auto;
                    margin-right: -35px;
                }
            }
        }

        > .optWrapper {
            &.multiple {
                > .MultiControls > p {
                    &.btnOk {
                        border-right: 0;
                        border-left: 1px solid $border;
                    }
                }
            }

            &.multiple {
                &.isFloating {
                    > .options li.opt {
                        padding-left: inherit;
                        padding-right: 35px;
                    }
                }
            }
        }

        .select-all {
            padding: 8px 35px 3px 0;
        }

        > .optWrapper > .options li.optGroup {
            padding-left: inherit;
            padding-right: 5px;
        }

        > .CaptionCont > span {
            padding-right: inherit;
            padding-left: 30px;
        }
        > .CaptionCont > label {
            left: 0;
            right: auto;
        }
        > .CaptionCont > label > i:before {
            margin-right: 0.255em;
            margin-left: auto;
        }
    }
    .intl-tel-input {
        .flag-dropdown {
            .selected-flag {
                margin: 1px 1px 1px 2px;
                padding: 11px 6px 11px 16px;
            }
        }
    }

    .intl-tel-input {
        .flag-dropdown {
            .selected-flag .down-arrow {
                left: auto;
                right: 20px;
            }
        }

        input {
            padding-left: inherit;
            padding-right: 47px;
        }
    }

    .telephone-input .btn {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .intl-tel-input .flag-dropdown .country-list .flag {
        margin-right: 0;
        margin-left: 6px;
    }
    .tree li i {
        margin-right: auto;
        margin-left: 5px;
        float: right;
        margin-top: 7px;
    }
    .datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item {
        padding-left: 25px;
        padding-right: 7px;
    }
    .main-datetimepicker > .datepicker_header a:nth-child(2),
    .main-datetimepicker > .datepicker_header a:last-child {
        transform: rotate(180deg);
    }
    .dropify-wrapper {
        .dropify-clear {
            right: auto;
            left: 10px;
        }
        .dropify-preview {
            .dropify-render {
                i {
                    left: auto;
                    right: 50%;
                }

                .dropify-extension {
                    left: auto;
                    right: 50%;
                }
            }

            .dropify-infos {
                .dropify-infos-inner {
                    p {
                        &.dropify-infos-message {
                            &::before {
                                left: auto;
                                right: 50%;
                            }
                        }
                    }
                }
            }
        }

        &.touch-fallback {
            .dropify-preview {
                .dropify-infos {
                    .dropify-infos-inner {
                        padding: 5px 0 5px 90px;

                        p {
                            text-align: right;
                        }
                    }
                }
            }
        }

        .dropify-loader {
            right: auto;
            left: 15px;
        }

        .dropify-errors-container {
            text-align: right;

            ul {
                li {
                    margin-left: auto;
                    margin-right: 20px;
                }
            }
        }
        ~ .dropify-errors-container ul {
            li {
                margin-left: auto;
                margin-right: 20px;
            }
        }
    }
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
        margin-right: auto;
        margin-left: 0.5em;
    }
    .form-select.is-invalid:not([multiple]):not([size]),
    .form-select.is-invalid:not([multiple])[size="1"],
    .was-validated .form-select:invalid:not([multiple]):not([size]),
    .was-validated .form-select:invalid:not([multiple])[size="1"] {
        padding-right: 0.75rem;
        padding-left: 4.125rem;
        background-position: left 0.75rem center, center left 2.25rem;
    }
    .note-editor .note-toolbar .note-color .dropdown-toggle,
    .note-popover .popover-content .note-color .dropdown-toggle {
        padding-left: inherit;
        padding-right: 5px;
    }
    .richText .richText-toolbar ul {
        padding-left: inherit;
        padding-right: 0;
    }
    .richText .richText-toolbar ul li {
        float: right;
    }
    .richText .richText-toolbar ul li a {
        border-left: $border solid 1px;
        border-right: 0;
    }
    .ql-snow .ql-tooltip {
        left: auto !important;
        right: 0;
    }
    .custom-control-label-md {
        padding-left: inherit;
        padding-right: 8px;
    }
    .custom-control-label-lg {
        padding-left: inherit;
        padding-right: 15px;
    }
    div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
        padding-left: inherit;
        padding-right: 0;
    }
    div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
        padding-right: inherit;
        padding-left: 0;
    }
    .dt-button-collection {
        .dropdown-menu {
            left: 0;
            right: auto;
        }
    }
    .card-img-holder {
        .card-footer {
            p {
                i {
                    float: right;
                    margin-top: 4px;
                }
            }
        }
    }
    .border-start {
        border-right: 1px solid $border !important;
        border-left: 0 !important;
    }
    .border-end {
        border-left: 1px solid $border !important;
        border-right: 0 !important;
    }
    .counter-status {
        p {
            i {
                float: right;
                margin-top: 4px;
            }
        }
    }
    .settings-main-icon {
        margin-right: 0 !important;
    }
    .main-mail-header {
        .btn {
            i {
                transform: rotate(180deg);
            }
        }
    }
    .nice-select {
        text-align: right;
        &:after {
            right: auto;
            left: 12px;
        }

        &.right {
            float: left;

            .list {
                left: 0;
                right: auto;
            }
        }

        .list {
            left: auto;
            right: 0;
        }

        .option {
            padding-left: 29px;
            padding-right: 18px;
            text-align: right;
        }
    }
    #checkoutsteps .item .purchase {
        margin-left: 0;
        margin-right: 21px;
    }
    #checkoutsteps .total {
        float: left;
    }
    .handle-counter .counter-minus {
        border-radius: 0px 3px 3px 0px !important;
    }
    .handle-counter .counter-plus {
        border-radius: 3px 0px 0px 3px !important;
    }
    .card-pay .tabs-menu.nav li {
        border-left: 1px solid $border;
        border-right: 0;
    }
    dd {
        margin-left: auto;
        margin-right: 0;
    }
    .alert {
        .item-card {
            .card-footer {
                a {
                    i {
                        float: right;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
    &.error-page1 .demo-icon {
        left: 60px;
        right: auto;
    }
    .margin-card {
        .me-3 {
            margin-right: 0 !important;
        }
        .mg-r-10 {
            margin-right: 0;
        }
        .mg-r-40 {
            margin-right: 0;
        }
        .mg-r-20 {
            margin-right: 0;
        }
    }
    @media (max-width: 991px) {
        .navbar-nav-right {
            padding-right: 15px;
        }
    }
    @media (max-width: 991px) {
        .error-page1 .demo-icon {
            right: auto !important;
            left: 30px !important;
        }
    }
    @media (max-width: 576px) {
        .navbar-nav-right {
            padding-right: 0;
        }
        .main-dashboard-header-right > div + div {
            margin-left: auto;
            margin-right: 25px;
        }
    }
    .avatar.me-3 {
        margin-right: 0 !important;
    }
    .dropdown-menu-end {
        --bs-position: start !important;
    }
    .dropdown-menu-start {
        --bs-position: end !important;
    }
    .card-link {
        display: inline-block;
    }
    #external-events {
        text-align: right;
    }
    .recent-contacts.me-3 {
        margin-right: 0 !important;
    }
    .tree {
        ul {
            margin-left: 0;
            margin-right: 1em;

            ul {
                margin-left: 0;
                margin-right: 0.5em;
            }

            &:before {
                left: auto;
                right: 0;
                border-left: 0;
                border-right: 1px solid $border;
            }
        }

        li i {
            margin-right: 0;
            margin-left: 5px;
        }

        ul li {
            &:before {
                left: auto;
                right: 0;
            }
        }
    }

    .indicator {
        margin-right: auto;
        margin-left: 5px;
    }
    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: -1px;
        margin-left: 0;
    }
    .sub-review-section .media-body a .badge span {
        float: right;
    }
    .owl-nav button {
        transform: rotate(180deg);
    }
    .avatar-list-stacked .avatar1 {
        margin-left: -0.8em !important;
        margin-right: 0 !important;
    }
    .radiobtns,
    .checkboxbtns {
        .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
        .btn-group > .btn:last-child:not(.dropdown-toggle) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }
        .btn-group > .btn.rounded-0 {
            border-radius: 0 !important;
        }
    }
    #basic-alert,
    #tabs-style2,
    #tabs-style3,
    #tabs-style4 {
        .main-nav-line {
            padding-right: 0;
        }
        .nav {
            padding-right: 0;
        }
    }
    .item7-card-desc,
    #friends {
        .me-2,
        .me-3,
        .me-0 {
            margin-right: 0 !important;
        }
    }
    .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
    .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
    .border-end-0 {
        border-right: 1px solid $border !important;
        border-left: 0 !important;
    }
    .border-start-0 {
        border-left: 1px solid $border !important;
        border-right: 0 !important;
    }
    div.dataTables_wrapper div.dataTables_length label {
        text-align: right;
    }
    div.dataTables_wrapper div.dataTables_filter label {
        text-align: right;
    }
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child {
        border-right-width: 1px !important;
        border-left-width: 1px !important;
    }
    table.table-bordered.dataTable th,
    table.table-bordered.dataTable td {
        border-right-width: 1px;
        border-left-width: 0 !important;
    }
    table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
        left: auto;
        right: 5px;
    }
    table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control {
        padding-left: inherit;
        padding-right: 30px;
    }
    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: auto;
        margin-right: 0.5em;
    }
    @media (max-width: 1143px) {
        .table tr td:last-child {
            border-left: 2px solid $border;
        }
        .userlist-table {
            .table tr td:last-child {
                border-left: 0;
            }
        }
    }
    .nav-tabs-line-brand,
    .preview-thumbnail {
        padding-right: 0;
    }
    @media (max-width: 400px) {
        #checkoutsteps .item .purchase {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }
    .cardprice-2 {
        text-align: left;
    }
    .main-notification-list {
        i {
            &.la-angle-right {
                transform: rotate(180deg);
                text-align: right !important;
            }
        }
    }
    .dropend .dropdown-menu {
        --bs-position: start !important;
    }
    .dropstart .dropdown-menu {
        --bs-position: end !important;
    }
    @media (max-width: 991px) {
        &.error-page1 .demo-icon {
            right: auto !important;
            left: 30px !important;
        }
    }
    .sidebar {
        border-left: 0;
        border-right: 1px solid $border;
    }
}
