#mixedSlider {
    position: relative;

    .MS-content {
        white-space: nowrap;
        overflow: hidden;
        margin: 0 5%;

        .item {
            display: inline-block;
            width: 33.3333%;
            position: relative;
            vertical-align: top;
            overflow: hidden;
            height: 100%;
            white-space: normal;
            padding: 0 10px;

            .imgTitle {
                position: relative;

                .blogTitle {
                    margin: 0;
                    text-align: left;
                    letter-spacing: 2px;
                    color: #252525;
                    font-style: italic;
                    position: absolute;
                    background-color: $white-5;
                    width: 100%;
                    bottom: 0;
                    font-weight: bold;
                    padding: 0 0 2px 10px;
                }

                img {
                    height: auto;
                    width: 100%;
                }
            }

            p {
                font-size: 16px;
                margin: 2px 10px 0 5px;
                text-indent: 15px;
            }

            a {
                float: right;
                margin: 0 20px 0 0;
                font-size: 16px;
                font-style: italic;
                color: rgba(173, 0, 0, 0.82);
                font-weight: bold;
                letter-spacing: 1px;
                transition: linear 0.1s;

                &:hover {
                    text-shadow: 0 0 1px grey;
                }
            }
        }
    }

    .MS-controls {
        button {
            position: absolute;
            border: none;
            background-color: transparent;
            outline: 0;
            font-size: 50px;
            top: 95px;
            color: $black-4;
            transition: 0.15s linear;

            &:hover {
                color: $black-8;
            }
        }

        .MS-left {
            left: 0px;
        }

        .MS-right {
            right: 0px;
        }
    }
}

@media (max-width: 991px) {
    #mixedSlider .MS-content .item {
        width: 50%;
    }
}

@media (max-width: 767px) {
    #mixedSlider .MS-content .item {
        width: 100%;
    }
}

@media (max-width: 992px) {
    #mixedSlider .MS-controls button {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    #mixedSlider .MS-controls button {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    #mixedSlider .MS-controls .MS-left {
        left: -10px;
    }
}

@media (max-width: 767px) {
    #mixedSlider .MS-controls .MS-right {
        right: -10px;
    }
}

#basicSlider {
    position: relative;

    .MS-content {
        white-space: nowrap;
        overflow: hidden;
        margin: 0 2%;
        height: auto;

        .item {
            margin: 0;
            padding: 10px;
            display: inline-block;
            width: 20%;
            position: relative;
            vertical-align: top;
            overflow: hidden;
            height: 100%;
            white-space: normal;
            line-height: 50px;
            vertical-align: middle;

            a {
                line-height: 50px;
                vertical-align: middle;
            }
        }
    }

    .MS-controls {
        button {
            position: absolute;
        }

        .MS-left {
            top: 35px;
            left: 10px;
        }

        .MS-right {
            top: 35px;
            right: 10px;
        }
    }
}

@media (max-width: 991px) {
    #basicSlider .MS-content .item {
        width: 25%;
    }
}

@media (max-width: 767px) {
    #basicSlider .MS-content .item {
        width: 35%;
    }
}

@media (max-width: 500px) {
    #basicSlider .MS-content .item {
        width: 50%;
    }
}
