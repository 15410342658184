﻿/*
    Common 
*/

.wizard,
.tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a {
    outline: 0;
}

.wizard ul,
.tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li {
    display: block;
    padding: 0;
}

/* Accessibility */

.wizard > .steps .current-info,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.tabcontrol > .content > .title {
    position: absolute;
    left: -999em;
}


.tabcontrol > {
    .steps {
        position: relative;
        display: block;
        width: 100%;

        > ul {
            position: relative;
            margin: 6px 0 0 0;
            top: 1px;
            z-index: 1;

            > li {
                float: left;
                margin: 5px 2px 0 0;
                padding: 1px;
                -webkit-border-top-left-radius: 5px;
                -webkit-border-top-right-radius: 5px;
                -moz-border-radius-topleft: 5px;
                -moz-border-radius-topright: 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                &:hover {
                    background: #edecec;
                    border: 1px solid #bbb;
                    padding: 0;
                }

                &.current {
                    background: $white;
                    border: 1px solid #bbb;
                    border-bottom: 0 none;
                    padding: 0 0 1px 0;
                    margin-top: 0;

                    > a {
                        padding: 15px 30px 10px 30px;
                    }
                }

                > a {
                    color: #5f5f5f;
                    display: inline-block;
                    border: 0 none;
                    margin: 0;
                    padding: 10px 30px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .content {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 35em;
        overflow: hidden;
        border-top: 1px solid #bbb;
        padding-top: 20px;

        > .body {
            float: left;
            position: absolute;
            width: 95%;
            height: 95%;
            padding: 2.5%;

            ul {
                list-style: disc !important;

                > li {
                    display: list-item;
                }
            }
        }
    }
}
