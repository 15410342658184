:root {
  --primary-bg-color: #0162e8;
  --primary-bg-hover: #025cd8;
  --primary-bg-border: #0162e8;
  --primary-1: rgba(1, 98, 232, 0.1);
  --primary-2: rgba(1, 98, 232, 0.2);
  --primary-2: rgba(1, 98, 232, 0.3);
  --primary-5: rgba(1, 98, 232, 0.5);
  --primary-8: rgba(1, 98, 232, 0.8);
  --primary-transparentcolor: rgba(1, 98, 232, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --transparent-body: #0b5bc9;
  --transparent-theme: rgba(0, 0, 0, 0.1);
  --blue: #0162e8;
  --indigo: #0162e8;
  --purple: $purple;
  --pink: $pink;
  --red: $danger;
  --orange: $orange;
  --yellow: $warning;
  --green: $success;
  --teal: $teal;
  --cyan: $info;
  --white: $white;
  --gray: $gray-600;
  --gray-dark: $gray-800;
  --primary: #0162e8;
  --secondary: $gray-600;
  --success: $success;
  --info: $info;
  --warning: $warning;
  --danger: $danger;
  --light: $gray-100;
  --dark: $gray-800;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

#count-down {
  width: auto;
}

#global-loader {
  position: fixed;
  z-index: 50000;
  background: $white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.dropify-wrapper {
  p:first-child {
    font-size: 14px !important;
    color: #334151 !important;
  }
}

.dropify-filename-inner {
  color: $white;
}

.loader-img {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 43%;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 $white;
  opacity: 0.5;

  &:hover {
    color: $black;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.2);
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.2);
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (' attr(title) ')";
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }
}

/* ########## BOOTSTRAP OVERRIDES ########## */

/* ############### PAGE LAYOUT STYLES ############### */

/* ###### 6.3 Global  ###### */

body {
  font-family: "Roboto", sans-serif;
}

.main-body,
.main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.main-body::before {
  content: "main";
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 0.5;
  opacity: 0.02;
  z-index: -1;
  display: none;
}

.main-table-reference {
  background-color: $white;

  > {
    thead > tr > {
      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
      }
    }

    tbody > tr > {
      th,
      td {
        padding: 8px 10px;
        border: 1px solid $border;
        font-size: 13px;
      }
    }

    thead > tr > {
      th,
      td {
        background-color: $gray-100;
        color: $gray-600;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0;
      }
    }
  }
}

/* ############### TEMPLATE STYLES ############### */

/* ###### 7.1 Demo  ###### */

@media (min-width: 992px) {
  .userlist-table .table {
    white-space: nowrap !important;
  }
  .nav-link#bs-example-navbar-collapse-1 {
    display: none;
  }
}

.main-icon-group {
  text-align: center;
  padding: 20px;
  color: $gray-800;
}

.font-awesome.main-icon-group i {
  font-size: 18px;
}

.main-icon-group i {
  line-height: 1;
  font-size: 24px;
  font-size: 18px;
}

.brround {
  border-radius: 50%;
}

/* ############### UTILITIES/HELPER CLASSES ############### */

.horizontal-nav .mega-dropdown-menu {
  box-shadow: 0 25px 100px rgba(115, 77, 191, 0.2);
  border: 0;
  margin: -2px;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
    right: auto;
    left: 16px;
  }
}

.mega-dropdown-menu:after {
  right: auto;
  left: 16px;
}

.horizontal-nav .dropdown-menu {
  min-width: 12.5rem;
}

.drop-icon-wrap .drop-icon-item {
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: #020202;
  text-transform: capitalize;
  width: 33.3%;
}

.h-6 {
  height: 2rem !important;
}

.dropgroupicons .dropdown-menu {
  width: 260px !important;
  padding: 5px !important;
  top: 52px !important;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
}

.flag {
  .dropdown-menu {
    top: 52px !important;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  }

  > a::before {
    content: "";
    position: absolute;
    top: 47px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: $gray-300;
    border-left-color: $gray-300;
    transform: rotate(45deg);
    background-color: $white;
    z-index: 901;
    display: none;
  }
}

.dropdown-toggle-1::after {
  top: 10px;
  right: 9px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f3d0";
  font-family: "Ionicons";
  position: absolute;
  display: inline-block;
  color: #7a8eb1;
}

.dropdown-toggle-2::after {
  top: 13px;
  right: 0px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "\f3d0";
  font-family: "Ionicons";
  position: absolute;
  display: inline-block;
  color: #7a8eb1;
}

.dropgroupicons .nav-link.icon i {
  font-size: 21px;
}

.drop-icon-wrap {
  a:hover {
    background: #eff2f6;
  }

  .drop-icon-item .drop-font {
    font-size: 12px;
  }
}

.country-Flag img {
  width: 1.5rem;
  height: 1rem;
  line-height: 0.1rem;
  font-size: 0.75rem;
}

.dropdown.flag .dropdown-item span {
  font-size: 14px;
}

.btn.btn-default.nav-link {
  height: 38px;
  width: 38px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  font-size: 17px;
  padding: 1px;
}

.d-md-block.nav.nav-item.nav-link {
  padding: 0.5rem 0.7rem;
}

.mega-dropdown-menu {
  width: 65rem;
  padding: 0.7rem 0.8rem 0.5rem 0.8rem;

  .dropdown-item {
    padding: 0.5rem 1rem;

    &:hover {
      color: $primary;
      background: transparent;
    }
  }
}

.btn-absolute {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 12px;
  padding: 20px;
}

.arrow-ribbon {
  padding: 6px 8px;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 999;
  font-size: 14px;
  line-height: 17px;
  background: $black;
  color: $white;

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    right: -15px;
    border-top: 14px solid transparent;
    border-left: 15px solid $black;
    border-bottom: 15px solid transparent;
    width: 0;
  }
}

.header-megamenu-dropdown .nav-item .btn-link {
  color: $default-color;
}

.mega-dropdown-menu .media-body .progress-bar {
  height: 4px;
}

.header-megamenu-dropdown .dropdown-menu {
  top: 8px !important;
}

.mega-menu {
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 14px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #c9cddb transparent;
    border-color: transparent transparent rgba(64, 82, 128, 0.9) transparent;
    z-index: -99;
  }
}

.drop-menu-header {
  padding: 1.5rem;
  color: $white;
}

.dropdown-menu .dropdown-header {
  text-transform: uppercase;
  font-size: 0.73333333rem;
  color: #3f6ad8;
  font-weight: bold;
}

.flag-text {
  color: $default-color;
  font-weight: 400;

  &:before {
    content: "\f3d0";
    font-family: "Ionicons";
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 0;
    color: $default-color;
  }
}

.drop-flag {
  margin-right: 2.3rem !important;
}

.flag-notification {
  a {
    display: block;
    font-size: 22px;
    color: $gray-900;
    position: relative;
    line-height: 1.5;
    outline: none;
    padding: 0.5rem 0.5rem;
  }
}

.flag-img {
  width: 2rem;
  height: 1.3rem;
}

.drop-flag .dropdown-menu {
  width: 150px;
  position: absolute;
  top: 52px;
  left: auto;
  right: -10px;
  bottom: auto;
  padding: 10px 10px 10px;
  border-color: $gray-300;
  border-width: 2px;

  .dropdown-item {
    font-size: 14px;
  }
}

.drop-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $white;
  position: relative;
}

.dropdown-item .content {
  margin-left: 15px;
  width: 200px;
  white-space: normal;
}
.avatar-status {
  content: "";
  position: absolute;
  bottom: 0;
  right: 5px;
  width: 6px;
  height: 6px;
  background-color: $gray-500;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  bottom: 4px;
}
.user-pro-body .profile-status {
  position: absolute;
  display: block;
  bottom: 0;
  right: 98px;
  width: 8px;
  height: 8px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  top: 73px;
  background: $success;

  &:before {
    content: "";
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.5);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
  }
}

.pulse {
  display: block;
  position: absolute;
  top: 7px;
  right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $success;

  &:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  8% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}
.pulse-danger {
  display: block;
  position: absolute;
  top: 7px;
  right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $danger;

  &:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
    animation: pulse 1s linear infinite;
  }
}

.main-message-list a {
  .desc {
    font-size: 13px;
    color: #535384;
    margin-left: 10px;
  }

  .name {
    font-size: 0.9rem;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    color: $default-color;
    margin-left: 10px;
  }

  .time {
    font-size: 11px;
    color: #8d8db3;
    font-weight: 400;
  }
}

.menu-header-content {
  padding: 1.2rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.chat-scroll,
.Notification-scroll {
  max-height: 291px;
  position: relative;
}

.cover-image {
  background-size: cover !important;
}

.notifyimg {
  i {
    color: $black;
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px;
  }

  border-radius: 50%;
}

.notification-label {
  font-size: 13.5px;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  color: $default-color;

  &:hover {
    color: $primary;
  }
}

.main-message-list .name:hover {
  color: $primary;
}

.notification-subtext {
  font-size: 12px;
  color: $gray-700;
}

/* ###### 4.8 Box-shadows ###### */

.box-shadow-primary {
  box-shadow: 0 5px 10px $primary-2;
}

.box-shadow-success {
  box-shadow: 0 5px 10px rgba(59, 176, 1, 0.25);
}

.box-shadow-warning {
  box-shadow: 0 5px 10px rgba(255, 193, 7, 0.25);
}

.box-shadow-danger {
  box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
}

.box-shadow-pink {
  box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
}

.main-notification-list {
  .las {
    font-size: 13px;
  }

  a:hover {
    background: #f6f6fb;
    color: #45567b;
  }
}

.main-message-list a:hover {
  background: #f6f6fb;
  color: #45567b;
}

.increase i,
.decrease i {
  height: 35px;
  width: 35px;
  margin: 0 auto;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
}

.sales-bar {
  min-height: 180px !important;
  position: relative;
  bottom: -21px;
}

.progress-sm {
  height: 4px;
}

.card-chart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 3px;
}

.browser-stats {
  i {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
  }

  .item {
    padding: 11px 1rem;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .sub-text {
    display: block;
    color: $gray-500;
    font-size: 10.6px;
  }
}

.sales-card {
  .badge {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 4px 15px;
    color: #4680ff;
    font-weight: 600;
  }

  .sales-icon {
    color: $white;
    font-size: 18px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover .sales-icon {
    font-size: 25px;
  }
}

.media img {
  width: 35px;
  height: 35px;
}

.sales-session {
  li {
    list-style-type: none;
    margin-bottom: 1.2rem;
  }

  margin-left: 0;
  padding-left: 0;
}

.stats .icon {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 45px;
  border-radius: 3px;

  i {
    font-size: 20px;
  }
}

.product-timeline {
  ul.timeline-1 {
    list-style-type: none;
    position: relative;
  }

  .name {
    color: $default-color;
    font-size: 15px;
  }

  ul.timeline-1 {
    &:before {
      content: " ";
      margin: 20px 20px 0 22px;
      display: inline-block;
      position: absolute;
      left: -2px;
      height: 94%;
      z-index: 0;
      content: "";
      position: absolute;
      bottom: 0;
      border-left: 2px dotted #c0ccda;
    }

    > li {
      margin: 20.7px 0;
      padding-left: 1rem;

      .product-icon {
        width: 37px;
        height: 37px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        background-color: #f3f6f7;
        font-size: 20px;
        line-height: 37px;
        font-size: 17px;
      }
    }
  }
}

.fw-semibold {
  font-weight: 500 !important;
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.order-list {
  .list {
    padding-left: 0;

    .list-item:first-child {
      padding-top: 0;
    }

    &.list-noborders .list-item {
      border: none;
    }

    .list-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 0 19px 0;
    }
  }

  .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dee2e6;
  }
}

.order-list img {
  border-radius: 6px;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.row-cards > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-deck > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .col .card,
  [class*="col-"] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.area.chart-legend {
  top: 125px;
}

.pricing-card {
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }

  .list-unstyled li {
    border-bottom: 1px solid #eaedf1;
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0;
  }
}

/*------ Pricing Styles ---------*/

.panel.price {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  > .panel-heading {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  h3 {
    margin-bottom: 0;
    padding: 20px 0;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid $white-2;
  background: $white;
}

.panel.price {
  > .panel-heading {
    color: $white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .list-group-item {
    &:last-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  margin-bottom: 1.5rem;
}

.price {
  .panel-footer {
    border-bottom: 0px;
    background-color: $white;
    border-left: 0;
    border-right: 0;
  }

  &.panel-color > .panel-body {
    background-color: $white;
  }
}

.panel-body {
  padding: 15px;

  .lead {
    strong {
      font-size: 40px;
      margin-bottom: 0;
    }

    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: $white;
  border-top: 1px solid #eaedf1;
  border-left: 1px solid #eaedf1;
  border-right: 1px solid #eaedf1;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel.price .btn {
  border: 0px;
}

.pricing .list-unstyled li {
  border-bottom: 1px solid #eaedf1;
  display: flow-root;
  margin-bottom: 0;
  padding: 9px 0;
}

.demo-gallery {
  > ul > li a {
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;

    > img {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      width: 100% !important;
    }

    &:hover {
      > img,
      .demo-gallery-poster > img {
        opacity: 0.5;
      }
    }

    .demo-gallery-poster {
      background-color: $black-1;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: background-color 0.15s ease 0s;
      -o-transition: background-color 0.15s ease 0s;
      transition: background-color 0.15s ease 0s;

      > img {
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
    }

    &:hover .demo-gallery-poster {
      background-color: $black-5;
    }
  }

  .justified-gallery > a {
    > img {
      -webkit-transition: -webkit-transform 0.15s ease 0s;
      -moz-transition: -moz-transform 0.15s ease 0s;
      -o-transition: -o-transform 0.15s ease 0s;
      transition: transform 0.15s ease 0s;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      height: 100%;
      width: 100%;
    }

    &:hover {
      > img {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
      }

      .demo-gallery-poster > img {
        opacity: 1;
      }
    }

    .demo-gallery-poster {
      background-color: $black-1;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: background-color 0.15s ease 0s;
      -o-transition: background-color 0.15s ease 0s;
      transition: background-color 0.15s ease 0s;

      > img {
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
    }

    &:hover .demo-gallery-poster {
      background-color: $black-5;
    }
  }

  .video .demo-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
  }

  &.dark > ul > li a {
    border: 3px solid #04070a;
  }
}

.card-body + .card-body {
  border-top: 1px solid #eaedf1;
}

.product-details .media img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 5px;
  max-width: inherit;
  border: 1px solid #d4daea;
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;

  li {
    width: 18%;
    margin-right: 2.5%;

    img {
      max-width: 100%;
      display: block;
    }

    a {
      padding: 0;
      margin: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.tab-content {
  overflow: hidden;

  img {
    width: 100%;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  text-transform: UPPERCASE;
  font-weight: bold;
}

.checked {
  color: #ff9f1a;
}
.price span {
  color: $default-color;
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
  margin-bottom: 15px;
}

.product-title {
  margin-top: 0;
}

.size {
  margin-right: 10px;

  &:first-of-type {
    margin-left: 40px;
  }
}

.details .rdiobox span {
  &:after {
    top: 5px;
  }

  &:before {
    top: 0px;
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  border: 1px solid #eaf0f7;
  color: $white;
  box-shadow: 0 1px 2px 0 $black-05;

  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
      no-repeat center (center / 50%) 50%;
  }
}

.colorinput-input:checked ~ .colorinput-color:before {
  opacity: 1;
}

.feature2 i {
  line-height: 3rem;
  font-size: 20px;
  margin: 0 0 15px 0;
}

.item-card {
  .cardtitle {
    span {
      display: block;
      font-size: 0.75rem;
    }

    a {
      color: #1f252d;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .cardprice {
    position: absolute;
    top: 17px;
    right: 15px;

    span {
      &.type--strikethrough {
        opacity: 0.7;
        text-decoration: line-through;
      }

      display: block;
      color: #1f252d;
    }
  }

  .relative {
    position: relative;
  }
}

/*------icons-list---------*/

.main-icon-list {
  padding: 0;
}

.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.icons-list-item {
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border: 1px solid #d6dcec;
  margin: 4px 4px;
  border-radius: 6px;
}

.icons-list .flag-icon {
  border-radius: 0;
}

/*------icons-list---------*/

.example {
  padding: 1rem;
  border: 1px solid #e7ebf5;
  font-size: 0.9375rem;
  border-radius: 7px;

  + .highlight {
    border-top: none;
    margin-top: 0;
  }
}

.text-wrap > :last-child {
  margin-bottom: 0;
}

.highlight {
  margin: 0 0 2rem;
  border: 1px solid #23241f;
  border-radius: 0px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 0.9375rem;
  background: #edeff7;
  border-top-right-radius: 8px;
  position: relative !important;

  pre {
    margin-bottom: 0;
    background-color: #23241f;
    max-height: 20rem;
  }
}

pre {
  color: #3d3f4e;
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #edeff7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  border-top-right-radius: 7px;
}

/*------icons-list---------*/

.clip-widget {
  position: relative;
}

.clipboard-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  background: $white;
  border: 1px solid #e7ebf5;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 33px;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition: 0.3s background;
}

::-webkit-scrollbar-thumb {
  background: #e1e6f1;
}

*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

@media (max-width: 568px) {
  .example {
    padding: 10px !important;
    font-size: 0.876rem;
  }
}

.angle {
  color: #5b6e88 !important;
}

.pro-img-box img {
  border: 1px solid $border !important;
}

.nav-sub-item .sub-slide-item {
  color: $white;
}
@media (max-width: 991px) {
  .responsive-logo {
    img {
      height: 2rem;
      margin: 0 auto;
    }
  }
}

@media (max-width: 633px) {
  .right-content {
    margin-top: 20px !important;
  }
}

@media (max-width: 1199px) {
  .total-revenue {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0px solid $border !important;
  }
}

.sub-menu li a:before {
  margin-right: 8px;
  content: "";
  width: inherit;
  height: inherit;
  border-width: 0;
  position: absolute;
  left: 8px;
  top: 15px;
  z-index: 99;
  border-color: #bec5d0;
  content: "\e92f";
  font-family: "feather" !important;
  position: absolute;
  top: 9px;
  left: 8px;
  font-size: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
  color: #696af1;
}

.mega-menubg.hor-mega-menu li a:before {
  margin-right: 8px;
  content: "";
  width: inherit;
  height: inherit;
  border-width: 0;
  position: absolute;
  left: 8px;
  top: 15px;
  z-index: 99;
  border-color: #bec5d0;
  content: "\e92f";
  font-family: "feather" !important;
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 9px;
  background: transparent;
}

.slide.active.is-expanded {
  background: rgba(238, 238, 247, 0.5);
}

@media (max-width: 1134px) {
  .p-text {
    display: none;
  }
}

@media (max-width: 659px) {
  .fullscreen-button {
    display: none;
  }
}

.horizontalMenucontainer.main-header form[role="search"] {
  width: inherit;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.prev-price {
  text-decoration: line-through;
}

.qunatity-list {
  margin-left: 0;
  padding-left: 6px;

  li {
    list-style-type: none;
  }
}

.chips .chip {
  margin: 0 0.5rem 0.5rem 0;
}

.chip {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6e7687;
  padding: 0 0.75rem;
  border-radius: 1rem;
  background-color: #f5f6fb;
  transition: 0.3s background;

  .avatar {
    float: left;
    margin: 0 0.5rem 0 -0.75rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
}

.team i {
  margin-left: 10px;
  float: right;
}

.feature .project {
  display: flex;
  width: 45px;
  height: 45px;
  line-height: 2em;
  vertical-align: middle;
  padding-top: 0px;
  color: $white;
  font-size: 20px;
  border-radius: 50px;
  position: relative;
  align-items: center;
  text-align: center;
  left: 0;
  right: 0;
  justify-content: center;
  font-weight: 500;
}

.plan-icon {
  font-size: 25px;
  width: 80px;
  height: 80px;
  line-height: 80px !important;
  overflow: hidden;
  border: 1px solid #ebeaf1;
  border-radius: 50%;
  background: #f5f6fb;
  transition: all 0.3s;
}

.country-table .table th,
.country-table .table td {
  padding: 10px 15px;
  line-height: 1.462;
}

/*-----Back to Top-----*/

#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: none;
  text-align: center;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -o-border-radius: 4px;
  z-index: 1000;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;

  i {
    padding-top: 0;
    font-size: 20px;
    line-height: 2.4;
  }

  background: $primary;
  color: $white;
  border-radius: 5px;
}

#compositeline,
#compositeline2 {
  position: relative;
  bottom: -1px;
}

@media (max-width: 480px) {
  .sales-bar {
    padding-top: 50px;
  }
}

@media (max-width: 578px) {
  .main-logo {
    display: none !important;
  }

  .logo-icon {
    display: block !important;
    height: 2.2rem;
  }

  .total-revenue .media {
    display: none;
  }
}

.box-shadow {
  box-shadow: none;
}

@media (max-width: 480px) {
  .sales-bar {
    padding-top: 50px;
  }
}

@media (max-width: 575px) {
  .sales-bar {
    padding-top: 50px;
  }
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover:before {
  border-color: $primary;
  color: $primary;
}

.desktop-dark,
.desktop-logo-dark,
.main-logo.dark-theme,
.dark-logo-2,
.dark-logo-1 {
  display: none;
}

.desktop-logo.logo-dark .main-logo.dark-theme {
  display: none !important;
}

@media (max-width: 578px) {
  .desktop-logo.logo-light .main-logo {
    display: block;
  }

  .logo-icon.mobile-logo {
    &.icon-light {
      display: block !important;
    }

    &.icon-dark {
      display: none !important;
    }
  }
}

@media (max-width: 991px) {
  .sticky-pin {
    position: fixed !important;
    box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.3);
    z-index: 1024;
  }
  .icons-list-item {
    margin: 4px 4px;
  }
}
/* ############### DASHBOARD STYLES ############### */

.main-dashboard-header-right {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  > div {
    label {
      display: block;
      margin-bottom: 0;
      color: #78829a;
    }

    h5 {
      font-size: 20px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      margin-bottom: 0;
      line-height: 1;
    }

    &:first-child {
      flex-basis: 100%;
    }

    + div {
      margin-top: 10px;
    }
  }
}
.left-content p {
  color: #78829a;
  font-weight: 400;
}

@media (min-width: 576px) {
  .main-dashboard-header-right {
    flex-wrap: nowrap;
  }
}

@media (min-width: 992px) {
  .main-dashboard-header-right {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .main-dashboard-header-right > div {
    text-align: right;
  }
}

@media (max-width: 575.98px) {
  .main-dashboard-header-right > div + div {
    flex-basis: 50%;
  }
}

@media (min-width: 576px) {
  .main-dashboard-header-right > div + div {
    margin-left: 25px;
    margin-top: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 992px) {
  .main-dashboard-header-right > div + div {
    margin-left: 50px;
  }
}

.main-star {
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
    line-height: 0;
    color: #97a3b9;
    display: inline-block;

    &.typcn {
      line-height: 0.9;
    }

    &.active {
      color: $warning !important;
    }
  }

  span {
    font-size: 13px;
    display: inline-block;
    margin-left: 5px;
  }
}
.card-dashboard-map-one {
  border-color: $border;
  padding: 20px;

  .main-content-label,
  .card-table-two .card-title {
    font-size: 0.875rem;
  }
}

.card-dashboard-map-one .vmap-wrapper {
  height: 200px;
}

@media (min-width: 576px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 270px;
  }
}

.card-dashboard-eight {
  border-color: $border;
  padding: 20px;

  .card-title {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .list-group-item {
    padding: 10px 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: $border;
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }

    span {
      display: block;
      margin-left: auto;
      font-weight: 600;
      font-family: "Archivo", Arial, sans-serif;
      color: #1c273c;
    }
  }

  .flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%;
  }
}
.main-content-header-right {
  display: none;

  .media {
    label {
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #97a3b9;
    }

    h6 {
      color: #1c273c;
      margin-bottom: 0;
    }

    + .media {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid $border;
    }
  }

  .btn:first-of-type,
  .sp-container button:first-of-type {
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  .main-content-header-right {
    display: flex;
    align-items: center;
  }
}

.sp-container .main-content-header-right button:first-of-type {
  margin-left: 30px;
}

.main-content-header-right {
  .btn + .btn,
  .sp-container button + .btn {
    margin-left: 5px;
  }
}

.sp-container .main-content-header-right button + .btn,
.main-content-header-right .sp-container .btn + button,
.sp-container .main-content-header-right .btn + button,
.main-content-header-right .sp-container button + button,
.sp-container .main-content-header-right button + button {
  margin-left: 5px;
}

.fw-bold {
  font-weight: bold !important;
}
.fw-nomal {
  font-weight: 400 !important;
}

.main-img-user.online::after {
  background-color: $success;
}

.app.sidebar-mini .desktop-logo.logo-dark {
  display: none;
}

.dropdown-menu-start {
  box-shadow: 0px 0px 15px 1px rgb(188, 181, 214);

  .dropdown-item {
    padding: 4px 19px;
  }

  img {
    width: 31px;
    height: 20px;
  }
}

.main-header-profile {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  top: -8.5px;
  right: 16px;
  border-bottom: 9px solid $white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.dropdown-menu-start {
  right: 0 !important;
  left: auto !important;
  bottom: auto !important;
}

.right-content {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.4rem 0.8rem;
  }
}

.total-revenue {
  display: flex;
  padding: 0 10px 20px;
  flex: none;
  position: absolute;
  z-index: 0;

  h4 {
    font-size: 21px;
    font-weight: 700;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -0.5px;
    margin-bottom: 3px;
  }

  label {
    font-size: 0.875rem;

    span {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-right: 5px;
      border-radius: 100%;
    }
  }

  > div + div {
    margin-left: 20px;
    padding-left: 20px;
  }
}

.customers .list-group-item .media img {
  width: 40px !important;
  height: 40px !important;
}

.list-group-item-action {
  width: 100%;
  color: #1d2635;
  text-align: inherit;
}

.progress-style {
  .table {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
    }
  }

  &.progress {
    overflow: visible !important;

    .progress-bar:after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: 0 5px 5px $black-2;
      background: $white;
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  .bg-primary-gradient.progress-bar:after,
  .bg-primary.progress-bar:after {
    border: 4px solid $primary;
  }

  .bg-danger.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-teal.progress-bar:after {
    border: 4px solid $teal;
  }

  .bg-pink.progress-bar:after {
    border: 4px solid $pink;
  }

  .bg-success.progress-bar:after {
    border: 4px solid $success;
  }

  .bg-danger-gradient.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-warning.progress-bar:after {
    border: 4px solid $warning;
  }

  .bg-info.progress-bar:after {
    border: 4px solid $info;
  }

  &.progress .progress-bar {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
  }

  .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px !important;
    line-height: 20px;
    overflow: visible !important;
    color: $white;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
}

.customers {
  .list-group-item-action {
    &:hover,
    &:focus {
      color: #0160e4;
      background-color: $gray-100;
    }
  }

  .list-group-item {
    padding: 0.82rem 1.25rem;
  }
}

.sales-info h3,
.card-table h4 {
  font-size: 21px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
}

.sales-infomation {
  position: absolute;
  bottom: 0;
}

#chart {
  margin-bottom: 4.5rem;
}

.br-be-7 {
  border-bottom-right-radius: 7px !important;
}

.br-bs-7 {
  border-bottom-left-radius: 7px !important;
}

.header-icon-svgs {
  width: 19px;
  height: 19px;
  color: #5b6e88;
}

.fullscreen-button .full-screen-link {
  line-height: 38px !important;
}

.country-flag1 strong {
  color: #7282a9;
  display: none;
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .main-header {
    .container-fluid,
    .container {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .sidebar-mini .main-dashboard-header-right {
    flex-wrap: wrap !important;
  }

  .country-flag1 {
    display: none !important;
  }

  .main-header-message,
  .main-header-notification,
  .search-icon,
  .main-layout {
    margin: auto 0 !important;
  }

  .total-revenue {
    > div + div {
      margin-left: 20px !important;
      padding-left: 3px !important;
    }

    padding: 0 !important;
  }
}

.nav-link.html-code.active {
  border-color: transparent;
  background-color: #23241f;
  color: white;
  margin-top: 1rem;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: 0;
}

.html-source.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-link.html-code {
  background: $gray-100;
  display: inline-flex;
  -webkit-box-align: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  align-items: center;
}

.counter-icon {
  margin-bottom: 0;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  padding: 1.3rem 1.4rem;
  border-radius: 50%;
  text-align: center;
  background: rgba(21, 22, 23, 0.2);

  i {
    color: $white;
    font-size: 22px;
  }
}

.success-widget h3 {
  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.danger-widget h3 {
  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.warning-widget h3 {
  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.card .box {
  .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
  }

  h2 {
    font-size: 20px;
    margin: 20px auto;
  }

  ul li a {
    display: block;
    margin: 0 10px;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
  }

  h2 span {
    background: $primary;
    font-size: 14px;
    color: $white;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px;
  }

  position: relative;
  top: 50%;
  left: 0;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid #e9f1f1;
  border-radius: 4px;
  box-shadow: 0 0 25px $white-3;
  width: 100%;
}

.userlist-table {
  .table {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
      display: table-cell;
    }
  }

  .table-bordered thead {
    th,
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.44rem 0.7rem;
    font-size: 0.9rem;
    display: initial;
    margin: 4px;
  }

  .user-link {
    font-weight: 500;
    color: $black;
    margin-right: 5px;
  }

  .user-subhead {
    color: #8992a9;
    display: none;
  }
}

.dot-label {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: ani 1s linear infinite;
  left: 7px;
  bottom: -12px;
  position: relative;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.search .page-link {
  background-color: $white;
}

.main-card-signin,
.modal-content .card {
  box-shadow: none;
}

@media (max-width: 576px) {
  .profile.navtab-custom {
    li {
      display: flex;
      width: 100%;
      border-radius: 0;

      a {
        border-radius: 0;
        width: 100%;
        margin-right: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }

    padding: 11px 0 0 0 !important;
    width: 100%;
  }
}

.table-vcenter {
  td,
  th {
    vertical-align: middle;
    border-top: 1px solid #f1f5fa;
  }
}

.table-center {
  td,
  th {
    text-align: center;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #22252f;
}

.userlist-table {
  .dot-label {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: ani 1s linear infinite;
    left: -6px;
    bottom: -7px;
    position: relative;
  }

  img {
    max-width: inherit;
  }
}

.product-pagination {
  .page-link {
    background-color: $white !important;
    border: 1px solid #dfe4f3;
  }

  .page-item.active .page-link {
    background: $primary !important;
    border-color: $primary;
  }
}
.main-logo1 {
  font-weight: 600;
}
@media (min-width: 992px) {
  .top-header .header-brand {
    display: none;
  }
  .top-header .main-header-center {
    margin-left: 0 !important;
  }
  .top-header .header-brand.header-brand2 {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 17px;
  }
}

.btn-outline-danger:focus {
  background: $danger;
}

@media (max-width: 991px) {
  .navbar-form.active .input-group-btn {
    right: 24px !important;
  }
}
.panel-heading1 a:before {
  content: "\e994";
  position: absolute;
  font-family: "feather" !important;
  right: 10px;
  top: 12px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}
#compositeline,
#compositeline2,
#compositeline3,
#compositeline4 {
  canvas {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .main-header.nav.hor-header {
    padding: 0px;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .main-content-left-contacts .main-nav-line .nav-link {
    padding: 6px 10px;
  }
}
@media (min-width: 1200px) {
  .main-nav-line-chat .nav-link + .nav-link {
    margin-left: 0 !important;
  }
}

@media (max-width: 1145px) and (min-width: 992px) {
  .main-nav-line-chat .nav-link + .nav-link {
    margin-left: 0 !important;
    padding: 0 7px;
  }
}

@media (max-width: 991px) {
  .main-nav-line-chat .nav-link {
    padding: 6px 10px;
  }
  .main-datetimepicker > .datepicker_inner_container > .datepicker_calendar {
    width: 250px !important;
  }
  .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist {
    width: 100px !important;
  }
  .horizontalMenucontainer .main-header.hor-header {
    position: fixed !important;
  }
  .main-body.active.menu-open .horizontalMenucontainer .main-header {
    left: auto;
  }
}
@media (max-width: 991px) and (min-width: 634px) {
  .horizontalMenucontainer .right-content {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 634px) {
  .main-dashboard-header-right {
    margin-top: 0px;
  }
}
@media screen and (max-width: 991px) {
  .breadcrumb-header {
    margin-top: 24px;
    margin-bottom: 24px !important;
  }
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: 0;
}

.breadcrumb-header .right-content .dropdown-menu {
  z-index: 9;
}
.todo-widget-header .dropdown-menu {
  z-index: 9;
}

#tooltip-left-arrow-individual {
  left: 117px !important;
  bottom: 8px !important;
}
#tooltip-right-arrow-individual {
  left: 0px !important;
  top: 7px !important;
}

.sign-favicon-b {
  display: none;
}

.light-mode {
  .sign-favicon-b {
    display: none;
  }
  .sign-favicon-a {
    display: inline;
  }
}

.boxed {
  #bar .apexcharts-canvas {
    svg {
      width: 500px !important;
    }
  }
}

.boxed {
  .media-body div:first-child {
    display: block !important;
    float: left;
  }
}
.boxed {
  .card .b-p-apex {
    padding-left: 0px !important;
  }
}
.boxed {
  .vmap-wrapper {
    svg {
      width: 500px !important;
    }
  }
}

#chart div:first-child {
  margin-right: auto;
  margin-left: auto;
}
.border-top-0 {
  border-top: none !important;
}
#br-t-0 {
  border-top: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $background;
}
.list-group-item.br-t-1 {
  border-top: 1px solid #e7ebf3;
}
#mrg-8 {
  margin-right: 8px;
}
.btn-b {
  color: $white !important;
  background-color: $info !important;
  border-color: $info !important;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: $background !important;
  color: var(--bs-table-striped-color);
}

.timeline-1 {
  padding-left: revert !important;
}
.tabs-menu1 .main-nav-line .nav-item .nav-link {
  transition: none !important;
}
@media (min-width: 320px) and (max-width: 375px) {
  .main-card-signin.construction .sub-input .form-control {
    font-size: 12px;
    padding: 0.375rem 0.5rem;
  }
  .main-card-signin.construction .sub-input .input-group-text button {
    padding: 0px 10px;
  }
}
@media (max-width: 375px) {
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 2px;
    }
  }
}

@media (min-width: 991px) and (max-width: 1215px) {
  .related-products-ltr-h .text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      margin-top: 2px;
    }
  }
}
@media (min-width: 991px) and (max-width: 1999px) {
  .related-products-ltr-l .product-des {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;

    .cardprice {
      position: unset;
    }
  }
}

@media (min-width: 991px) {
  .related-products-ltr-l .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
      margin: 2px;
    }
  }
}
@media (min-width: 320px) {
  .actions.clearfix {
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      // justify-content: center !important;

      li {
        margin: 2px;
      }
    }
  }
}
@media (max-width: 577px) {
  .custom-cls-res {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    div {
      margin: 2px;
    }
  }
}

@media (min-width: 990px) and (max-width: 1024px) {
  .card-img-overlay {
    align-items: center;

    p {
      margin-bottom: 0px !important;
    }
  }
}

@media (max-width: 375px) {
  .widget-user-header {
    padding: 10px 20px !important;
  }
}

.navbar-nav-right .dropdown-footer {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
hr {
  border: none;
}

.table > :not(caption) > * > * {
  border-bottom: none;
}

.custom-index .table > :not(caption) > * > * {
  border-bottom: 1px solid #9b9ea5;
}

select:disabled {
  opacity: 0;
}

@media (min-width: 320px) {
  #mobile-number {
    max-width: 154px !important;
  }
}

@media (min-width: 375px) {
  #mobile-number {
    max-width: 209px !important;
  }
}

@media (min-width: 425px) {
  #mobile-number {
    width: 250px;
  }
}

@media (min-width: 320px) and (max-width: 357px) {
  .pd-25.custom-cls-img {
    padding: 5px !important;
    text-align: center;

    h6 {
      margin-bottom: 5px !important;
    }
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled .main-chat-footer {
    margin-left: 80px !important;
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .app.sidebar-mini .main-chat-footer {
    margin-left: 239px !important;
  }
}
#bar {
  svg {
    width: 100% !important;
  }
  .apexcharts-canvas {
    width: 100% !important;
  }
}
.custom-hvr a:hover {
  color: $white;
}
.custom-hvr-2 a:hover {
  color: $white;
}

.l-c-r {
  padding-left: revert !important;
}
.carousel-inner,
.carousel-slide,
.carousel-item,
.carousel-item img {
  border-radius: 5px;
}
#basicSlider .item,
#basicSlider img {
  border-radius: 5px;
}
@media (max-width: 425px) {
  .clearfix .carousel.slide .carousel-inner .carousel-item.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.5rem;
}

@media (min-width: 320px) and (max-width: 576px) {
  .modal-title.mg-b-5 {
    margin-top: 12px;
  }
}

.dropdown-menu-start .dropdown-menu-arrow.show {
  box-shadow: 0px 0px 15px 1px #45414e33;
  border-radius: 6px;
}
.custom-cls-no-br {
  border-color: $gray-200;
}
.custom-index .table > :not(caption) > * > * {
  border-bottom: none !important;
}

.table-bordered > :not(caption) > * {
  border-bottom: 1px;
}

@media (max-width: 320px) {
  .pd-25 {
    padding: 8px !important;
  }
}

@media (min-width: 992px) {
  .app-content {
    margin-left: 240px;
  }
}
table.dataTable {
  margin: 0 auto;
  width: 100% !important;
}

@media (min-width: 991px) and (max-width: 950px) {
  .custom-cls-img {
    &.pd-25 {
      padding: 7px;
    }
    .mg-sm-b-20 {
      margin-bottom: 2px;
    }
    .mg-b-0 {
      font-size: 12px;
    }
  }
}
.icons-list-item i {
  font-size: 1rem !important;
  line-height: 3;
}
.background-image-blog {
  background-image: url(../img/photos/blog-main.jpg) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 355px;
  position: relative;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-radius: 0px;
}
.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  border-radius: 5px;
}
.square {
  border-radius: 0 !important;
}
.radius {
  border-radius: 10px !important;
}
.main-img-user1.avatar-md .number-badge {
  position: absolute;
  top: -7px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  right: -7px;
  border: 1px solid #ededf5;
}
.main-img-user1.avatar-sm .number-badge {
  position: absolute;
  top: -7px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  right: -7px;
  border: 1px solid #ededf5;
}
.main-img-user1,
.main-img-user3 {
  position: relative;
}

.main-img-user1.avatar-lg .number-badge {
  position: absolute;
  top: -7px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  right: -7px;
  border: 1px solid #ededf5;
}

.main-img-user3.avatar-sm .icon-badgeavatar {
  position: absolute;
  bottom: -4px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  right: -4px;
  border: 2px solid #ededf5;
}

.main-img-user3.avatar-sm .icon-badgeavatar i {
  font-size: 10px;
  line-height: 1.4 !important;
}

.main-img-user3.avatar-md .icon-badgeavatar {
  position: absolute;
  bottom: -4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  right: -4px;
  border: 2px solid #ededf5;
}

.main-img-user3.avatar-md .icon-badgeavatar i {
  font-size: 11px;
  line-height: 1.4 !important;
}

.main-img-user3.avatar-lg .icon-badgeavatar {
  position: absolute;
  bottom: -4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
  right: -4px;
  border: 2px solid #ededf5;
}

.main-img-user3.avatar-lg .icon-badgeavatar i {
  font-size: 12px;
  line-height: 1.4 !important;
}

.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0 0 8px 0;
  font-size: 0;
}

.avatar-list-stacked .avatar1 {
  box-shadow: 0 0 0 2px $white;
  margin-right: -0.8em !important;
}

.avatar1 {
  width: 3rem;
  height: 3rem;
  line-height: 2.85rem;
  position: relative;
  text-align: center;
  display: inline-block;
  color: $white;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avatar1 {
  background: $primary no-repeat center/cover;
}

.avatar1 i {
  line-height: 3.1;
}
.example .row > * {
  width: auto !important;
}
hr {
  background-color: $black-2;
}
.chat-profile {
  padding: 8px;
  line-height: 1;
  color: #4a4a69;
}
.shared-files {
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ededf5;
}
.theme-container button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid #ededf5;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.theme-container1 button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid #ededf5;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.theme-container2 button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid #ededf5;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.pickr {
  text-align: center;
  margin-top: 10px;
}
.content {
  .table-responsive > .table-bordered {
    border: 1px solid $border;
  }
}
.bxl-instagram.tx-prime,
.bxl-linkedin.tx-prime,
.bxl-twitter.tx-prime,
.bxl-facebook.tx-prime {
  color: $primary;
  display: block;
  font-size: 18px;
  position: relative;
  line-height: 35px !important;
  outline: none;
  height: 37px;
  width: 37px;
  background: $white;
  margin: auto;
  text-align: center;
  border: 1px solid #f1ecf7;
  box-shadow: none;
  border-radius: 35px;
}
.user-lock {
  btn {
    border: 1px solid $border;
    border-radius: 50%;
  }
}
#gallery {
  .brick img {
    border-radius: 0;
  }
}

.handle-counter {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.counter-minus.btn {
  padding: 9px 15px !important;
}

.handle-counter .btn {
  padding: 8px 10px !important;
}

.handle-counter input {
  float: left;
  text-align: center;
  height: 41px;
  border-radius: 0;
  width: 70px;
  border: 1px solid #ededf5;
  outline: none;
  border-left: 0;
  border-right: 0;
}

.handle-counter .counter-minus,
.handle-counter .handle-counter .counter-plus {
  float: left;
  text-align: center;
  border-radius: 1px;
}

.handle-counter .counter-minus {
  border-radius: 3px 0px 0px 3px !important;
}

.handle-counter .counter-plus {
  border-radius: 0px 3px 3px 0px !important;
}

.counter-minus {
  border-radius: 0px 0 0 0px !important;
}

.counter-plus {
  border-radius: 0 0px 0px 0 !important;
}
.aboutlist {
  list-style-type: none;
}

.about-icons {
  height: 60px;
  width: 60px;
}

.about-team {
  width: 80px;
  height: 80px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.about-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prime-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prime-card img {
  height: 160px;
  transform: scale(1.9) translate(3px, -12px);
}

@media (max-width: 1600px) {
  .prime-card img {
    height: 250px;
    transform: scale(1);
  }
}
@media (min-width: 992px) {
  .main-settings-menu .nav-link {
    font-size: 14px;
  }

  .main-settings-menu .nav-link i {
    font-size: 18px;
  }
}

.settings-main-icon {
  display: flex;
  text-align: center;
}

.settings-main-icon i {
  width: 3rem;
  font-size: 18px;
  line-height: 3rem;
  height: 3rem;
  background-color: $primary-2;
  color: $primary;
  border-radius: 50%;
}
/*--scrollspy ---*/
.scrollspy-example {
  position: relative;
  height: 200px;
  overflow: auto;
  padding: 0.75rem;
  border-right: 1px solid $border;
  border-left: 1px solid $border;
  border-bottom: 1px solid $border;
}

.scrollspy-example-2 {
  position: relative;
  height: 409px;
  overflow: auto;
  padding: 0.75rem;
  border: 1px solid $border;
  border-radius: 0 5px 5px 0;
}

.scrollspy-example-2 .nav-link {
  padding: 10px;
}

#navbar-example2 .nav-link {
  padding: 0.5rem 0.75rem;
}

#navbar-example3 .nav-link {
  padding: 0.75rem;
  color: $default-color;
}

#navbar-example3 .nav-link.active {
  color: $white;
}

.file-manager-icon {
  width: 30px;
  height: 30px;
}
.file-manger-icon1 img {
  width: 70px;
  height: 70px;
}
.file-manger-icon img {
  width: 70px;
  height: 70px;
}

.file-manger-icon {
  position: relative;
}
.file-1 {
  input {
    height: 40px;
  }
}

.tag.tag-attachments {
  padding: 4px 15px 2px 15px;
  font-size: 13px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-lg {
  padding: 5px 17px 3px 17px;
  font-size: 17px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-sm {
  padding: 3px 10px 1px 10px;
  font-size: 13px;
  background-color: $white;
  border: 1px solid #e8e8f7;
}

.tags .tag-files span {
  position: absolute;
  top: 21px;
  margin-right: 104px;
  left: 51px;
  font-size: 12px;
  color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: $white;
}

.attached-file-grid6 .icons li a {
  color: $primary;
  border: 1px solid $white;
}
.attached-file-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, $black-5, transparent 100%);
}

.image-pic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  color: $white;
  font-size: 17px;
  background-image: linear-gradient(to bottom, $black-5, transparent 100%);
  padding: 20px 0px 20px 20px;
  border-radius: 5px;
}

.file-image-1 {
  width: 100px;
  height: 100px;
  display: inline-table;
  margin: 20px;
  position: relative;
  border: 1px solid #ededf5;
  border-radius: 5px;

  .product-image img {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.file-image-md .product-image img {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-lg .product-image img {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-1 {
  .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    left: 7px;
    top: 6px;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
  }

  &:hover .icons {
    opacity: 1;
    bottom: 33px;
  }

  .icons li a {
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    height: 25px;
    width: 25px;
    margin: 4px 1px;
    padding: 0;
    border-radius: 50px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    color: $white;
  }

  .file-name-1 {
    position: absolute;
    right: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.file-image-md {
  width: 150px;
  height: 150px;

  .icons li a {
    font-size: 13px;
    line-height: 30px;
    height: 28px;
    width: 28px;
  }
}

.file-image-lg {
  width: 200px;
  height: 200px;

  .icons li a {
    font-size: 15px;
    line-height: 35px;
    height: 35px;
    width: 35px;
  }
}
.light-layout {
  display: none;
}
.sidebar-right {
  .wrapper {
    max-width: inherit;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 991px) {
  .app-sidebar {
    z-index: 1029 !important;
    top: 63px !important;
  }
}
.bg-primary-transparent {
  .about-icons {
    :first-child {
      fill: $primary !important;
    }
    :last-child {
      fill: $primary-5 !important;
    }
  }
}
.formgroup-wrapper .form-control {
  padding: 0 10px;
}
.dataTables_wrapper .dataTables_length .select2-container--default {
  width: 60px !important;
}
.latest-tasks {
  .nav-tabs-bold {
    &.nav-tabs .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.item-card .cardprice-2 span.type--strikethrough {
  opacity: 0.5;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .main-header {
    padding: 0 9px !important;
  }
}
#owl-demo2 {
  .item {
    .custom-card {
      border: 0 !important;
    }
  }
}
#contentthumb {
  .img-thumbnail {
    padding: 15px;
    .caption {
      padding: 15px 0 0 0;
      .btn {
        margin: 0 0 5px 0;
      }
    }
  }
}
#background,
#image4 {
  figure {
    overflow: hidden;
  }
}
#thumb {
  img {
    margin-bottom: 10px;
  }
}
#contentthumb,
#background,
#thumb,
#aligning,
#responsive,
#image1,
#image2,
#image3,
#image4 {
  img,
  figcaption {
    border-radius: 7px;
  }
}
#tab {
  .nav.nav-tabs {
    padding: 0.5rem !important;
  }
}

@media (max-width: 340px) {
  .pd-20.bg-gray-800 {
    padding: 20px 8px;
  }
  .pagination-circled .page-link {
    width: 37px;
  }
}
@media (max-width: 320px) {
  .blog-edit {
    .card-footer {
      text-align: center;
      .float-end {
        float: none !important;
      }
      .btn {
        margin-bottom: 3px;
      }
    }
  }
  .pagination.search {
    .page-link {
      width: 28px;
      height: 28px;
    }
  }
}
.datetimepicker {
  z-index: 1 !important;
}
@media (max-width: 400px) {
  .btn-primary.data-table-btn {
    position: relative;
    left: 0;
  }
  .demo-gallery {
    .col-md-6 {
      .float-end {
        float: none !important;
      }
    }
  }
  #checkoutsteps .item .left {
    display: block !important;
  }
  #checkoutsteps .item .purchase {
    margin-left: 0 !important;
  }
  .card-pay .tabs-menu li {
    width: 100% !important;
    border-bottom: 1px solid $border;
  }
  .wizard > .actions a {
    text-align: center;
  }
  .wizard > .actions > ul {
    display: block !important;
  }
}
.main-mail-list {
  overflow: hidden;
}
.nav-tabs-line-brand {
  .nav-link {
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
.user-wideget-widget {
  overflow: hidden;
}
.todo-widget-header {
  .dropdown-menu {
    border-radius: 7px;
  }
}
.checkout-steps.wrapper {
  max-width: initial;
  margin: 20px 0;
  padding: 0;
}
.cardprice-2 {
  text-align: right;
}
.wishlist-card {
  margin-bottom: 0;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .error-page1 .demo-icon {
    right: 30px !important;
    top: 30px !important;
  }
}
.datepicker {
  z-index: 1 !important;
}
.bg-danger-transparent,
.bg-success-transparent,
.bg-info-transparent {
  .card-header {
    background: transparent !important;
  }
}

@media (max-width: 370px) {
  #background figcaption,
  #image4 figcaption {
    overflow: scroll;
  }
}
.main-dropdown-form-demo {
  .dropdown-menu-start {
    right: 0 !important;
    left: 0 !important;
    box-shadow: none;
  }
}
#friends {
  .user-lock {
    .dropdown-menu {
      border-radius: 7px;
    }
  }
}
@media screen and (max-width:991px) {
  .search-icon {
    input {
      border-radius: 0px !important;
    }
  }
}

