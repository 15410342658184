/* ######  Accordion ###### */

.accordion {
	> .card {
		overflow: hidden;

		&:not(:first-of-type) {
			.card-header:first-child {
				border-radius: 0;
			}

			&:not(:last-of-type) {
				border-bottom: 0;
				border-radius: 0;
			}
		}

		&:first-of-type {
			border-bottom: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		&:last-of-type {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.card-header {
			margin-bottom: -1px;
		}
	}

	.card {
		border-width: 0;

		+ .card {
			margin-top: 1px;
		}
	}

	.card-header {
		padding: 0;
		background-color: transparent;
		font-weight: 500;

		a {
			display: block;
			padding: 14px 20px;
			position: relative;
			color: $gray-900;
			font-weight: 500;
			font-size: 14px;
			background-color: $gray-100;

			&:hover,
			&:focus {
				color: $gray-900;
			}

			&.collapsed {
				color: $gray-700;
				background-color: $gray-100;
				border-bottom-color: transparent;

				&:hover,
				&:focus {
					color: $gray-900;
					background-color: $gray-200;
				}
			}
		}
	}

	.card-body {
		padding: 0 20px 20px;
		background-color: $gray-100;
		transition: none;
	}
}

.card .card {
	box-shadow: none !important;
}
.accordion > .card {
	border: 0 !important;
}

.accordion-dark {
	.card {
		border-color: $gray-900;
	}

	.card-header a {
		color: $gray-900;

		&:hover,
		&:focus {
			color: $gray-900;
		}

		&.collapsed {
			background-color: $gray-900;
			color: $white-8;
			font-weight: 500;
			letter-spacing: -0.1px;

			&:hover,
			&:focus {
				background-color: #141c2b;
				color: $white;
			}
		}
	}
}

.panel-heading1.bg-primary .panel-title1 a {
	display: block;
	color: #6a7882;
}

.panel-title1 {
	margin-bottom: 0;
}

.panel-heading1.bg-primary .panel-title1 a {
	display: block;
	color: $white;
}

.panel-title1 a {
	display: block;
	color: $white;
	padding: 15px;
	position: relative;
	font-weight: 400;
	font-size: 15px;
}

.panel-heading1 a.collapsed:before {
	content: "\e9af";
	position: absolute;
	font-family: "feather" !important;
	right: 10px;
	top: 12px;
	font-size: 20px;
	transition: all 0.5s;
	transform: scale(1);
}

.accor {
	&.bg-primary h4 a {
		color: $white;
	}

	h4 a {
		padding: 0.75rem 1.25rem;
		display: block;
		font-size: 15px;
		border-bottom: 1px solid rgba(154, 180, 202, 0.16);
	}
}
/* ######  Accordion ###### */